import {Box, Checkbox, styled, TextField} from '@mui/material';
import Typography from '@mui/material/Typography';
import React, {useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {useParams} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {STUDENT_PROFILE_CONFIRMATION_TYPES} from '../../const/StudentProfileConfirmationTypes';
import ConfirmationWrapper from '../../Components/ConfirmationWrapper';
import {useGetWelfareQuery, useManageWelfareMutation} from '../../../../../api/services/studentService';
import {useGetWelfareOptionsQuery} from '../../../../../api/services/lookupsService';

export const Item = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: 3
});

const WelfareOptions = ({readonly, onConfirmSuccess}) => {
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [deselectedOptions, setDeselectedOptions] = useState([]);
    const {studentId} = useParams();
    const [isNotApplicable, setIsNotApplicable] = useState(undefined);
    const {selectedProfile, currentSchool} = useSelector(state => state.profilesInfo);
    const [manageWelfare] = useManageWelfareMutation();
    const {
        data: welfare,
        refetch: refetchWelfareData
    } = useGetWelfareQuery(studentId);
    const {
        data: optionsData = {},
        refetch: refetchWelfareOptions
    } = useGetWelfareOptionsQuery();

    useEffect(() => {
        if (welfare) {
            setSelectedOptions(welfare.options?.map(x => ({id: x.optionId, comment: x.comment})));
            setIsNotApplicable(welfare.isNotApplicable);
        }
    }, [welfare]);

    const handleSelect = (event) => {
        const {checked, value} = event.target;
        const optionId = parseInt(value);

        if (checked) {
            setSelectedOptions([...selectedOptions, {id: optionId}]);
            setDeselectedOptions(deselectedOptions.filter(id => id !== optionId));
        } else {
            setDeselectedOptions([...deselectedOptions, optionId]);
            setSelectedOptions(selectedOptions.filter(selectedOption => selectedOption.id !== optionId));
        }
    };

    const handleAddComment = (value, id) => {
        let updatedSelectedOptions = JSON.parse(JSON.stringify(selectedOptions));
        let targetOption = updatedSelectedOptions.find(option => option.id === id);
        if (targetOption === null) {
            setSelectedOptions([...selectedOptions, {id, comment: value}]);
        } else {
            targetOption.comment = value;
            setSelectedOptions(updatedSelectedOptions);
            setDeselectedOptions([...deselectedOptions, id]);
        }
    };

    const handleSave = async () => {
        const body = {
            selectedOptions,
            deselectedOptions,
            isNotApplicable,
            studentId
        };

        await manageWelfare({studentId, body});
        setSelectedOptions([]);
        setDeselectedOptions([]);
    };

    const isEdited = () => {
        return selectedOptions.filter(x => !welfare.options.some(v => v.optionId === x.id && v.comment === x.comment)).length !== 0 ||
            deselectedOptions.filter(x => welfare.options.some(v => v.optionId === x)).length !== 0 || isNotApplicable !== welfare.isNotApplicable;
    };

    const welfareOptions = useMemo(() =>
            optionsData.options
                ? optionsData.options
                    ?.map(x => ({...x, description: x.description?.replaceAll('@studentForename', selectedProfile.name), title: x.title?.replaceAll('@studentForename', selectedProfile.name)}))
                : [],
        [optionsData, selectedProfile]);

    return (
        welfare && welfareOptions && <ConfirmationWrapper
                readonly={readonly}
                confirmData={{
                    confirmMessage: `I confirm that the information in the welfare section of ${selectedProfile.name} ${selectedProfile.surname} student profile is correct and understand it will be relied upon by school staff and any other person or organisation who may have ${selectedProfile.name} ${selectedProfile.surname} in their care on behalf of ${currentSchool.name}`,
                    confirmedAt: welfare.confirmedAt,
                    confirmedBy: welfare.confirmedBy,
                    enableSaveButton: isEdited() && (selectedOptions.length !== 0 || isNotApplicable),
                    enableConfirmButton: selectedOptions.length !== 0 || isNotApplicable,
                    studentId,
                    type: STUDENT_PROFILE_CONFIRMATION_TYPES.Welfare,
                    handleSave,
                    onConfirmSuccess: async () => {
                        await onConfirmSuccess();
                        await refetchWelfareData();
                        await refetchWelfareOptions();
                    },
                    confirmButtonText: 'Confirm Welfare'
                }}
            >
                <Typography variant="h6" gutterBottom>WELFARE</Typography>
                <Item>
                    <Typography>Please tick this box if none of the Welfare questions listed below are
                        relevant to {selectedProfile.name}</Typography>
                    <Checkbox
                        sx={{width: '25px', height: '25px'}}
                        value={isNotApplicable}
                        checked={!!isNotApplicable}
                        disabled={readonly || (selectedOptions.length > 0 && !isNotApplicable)}
                        onChange={(e) => setIsNotApplicable(e.target.checked)}
                    />
                </Item>
                {
                    !isNotApplicable && <Typography fontWeight={'bold'} variant="body1" mt={2}>
                        {`Please tick any of the questions below that are relevant to ${selectedProfile.name}.
            We do not require any additional information relating to the questions below to be provided on ${selectedProfile.name}'s
            Student Profile.
            Our welfare team may contact you if they need any further information.`}
                </Typography>
            }
            {!isNotApplicable &&
                welfareOptions.map((field, index) => (
                    <Box key={index}>
                        <Item>
                            <Box>
                                {
                                    field.description && field.title
                                        ? <Typography fontWeight={'bold'} mt={1}>{field.description}</Typography>
                                        : ''
                                }
                                {
                                    field.title
                                        ? <Typography>{field.title}</Typography>
                                        : <Typography>{field.description}</Typography>
                                }
                            </Box>
                            <Checkbox
                                sx={{width: '25px', height: '25px'}}
                                value={field.id}
                                checked={selectedOptions?.some(option => option.id === field.id)}
                                onChange={handleSelect}
                                disabled={readonly}
                            />
                        </Item>
                        {(field.hasComment && selectedOptions.findIndex(option => option.id === field.id) !== -1) &&
                            <TextField
                                sx={{my: 2}}
                                fullWidth
                                label={'Comment'}
                                multiline
                                value={selectedOptions.find(option => option.id === field.id)?.comment}
                                autoComplete="off"
                                disabled={readonly}
                                onChange={event => {
                                    handleAddComment(event.target.value, field.id);
                                }}
                            />
                        }
                    </Box>
                ))
            }
        </ConfirmationWrapper>
    );
};

WelfareOptions.propTypes = {
    readonly: PropTypes.bool,
    onConfirmSuccess: PropTypes.func,
    onDataLoading: PropTypes.func,
};
export default WelfareOptions;