import {createSlice} from '@reduxjs/toolkit';
import moment from 'moment';

const tablePropsDefaults = {
    initialized: false,
    pagination: {
        pageIndex: 0,
        pageSize: 20
    },
    filters: {},
    quickFilters: {},
    columnVisibility: {},
    sorting: []
};

const initialState = {
    tables: {}
};

const tableSlice = createSlice({
    name: 'table',
    initialState,
    reducers: {
        initialize(state, action) {
            const {key, filters, sorting} = action.payload;

            if (!state.tables[key]) {
                state.tables[key] = {...tablePropsDefaults};
            }

            state.tables[key].filters = filters;
            state.tables[key].sorting = sorting;
            state.tables[key].initialized = true;
        },
        setPagination(state, action) {
            const {key, pagination} = action.payload;

            if (!state.tables[key]) {
                state.tables[key] = {...tablePropsDefaults};
            }

            state.tables[key].pagination = pagination;
        },
        setFilters(state, action) {
            const {key, filters, quickFilters} = action.payload;

            if (!state.tables[key]) {
                state.tables[key] = {...tablePropsDefaults};
            }

            state.tables[key].filters = filters;
            state.tables[key].quickFilters = quickFilters;
        },
        setSorting(state, action) {
            const {key, sorting} = action.payload;

            if (!state.tables[key]) {
                state.tables[key] = {...tablePropsDefaults};
            }

            state.tables[key].sorting = sorting;
        },
        setColumnVisibility(state, action) {
            const {key, columnVisibility} = action.payload;
            const [[targetId, targetValues] = []] = Object.entries(columnVisibility.dynamic || {});

            if (!state.tables[key]) {
                state.tables[key] = {...tablePropsDefaults};
            }

            state.tables[key].columnVisibility = {
                default: columnVisibility?.default,
                dynamic: {
                    ...state.tables[key].columnVisibility.dynamic,
                    ...(targetId
                            ? {
                                [targetId]: {
                                    maxAge: moment().add(1, 'days').valueOf(),
                                    values: targetValues
                                }
                            }
                            : {}
                    )
                }
            };
        },
        resetTableState(state, action) {
            const columnVisibility = state.tables[action.payload]?.columnVisibility;

            state.tables[action.payload] = {...tablePropsDefaults, columnVisibility};
        },
        loadTablePreferences(state, action) {
            const key = action.payload;
            const columnVisibility = JSON.parse(localStorage.getItem(`tablePreferences_${key}`));

            if (!state.tables[key]) {
                state.tables[key] = {...tablePropsDefaults};
            }

            state.tables[key].columnVisibility = {
                default: columnVisibility?.default || {},
                dynamic: columnVisibility?.dynamic &&
                    Object.entries(columnVisibility.dynamic)
                        .reduce((acc, [key, value]) => {
                            if (!value.maxAge || value.maxAge > Date.now()) {
                                acc[key] = value;
                            }

                            return acc;
                        }, {})
            };
        },
        persistTablePreferences(state, action) {
            const key = action.payload;

            if (!state.tables[key]) return;

            localStorage.setItem(
                `tablePreferences_${key}`,
                JSON.stringify(state.tables[key].columnVisibility ?? {})
            );
        }
    }
});

export const {
    initialize,
    setPagination,
    setFilters,
    setSorting,
    resetTableState,
    setColumnVisibility,
    loadTablePreferences,
    persistTablePreferences
} = tableSlice.actions;

export default tableSlice.reducer;
