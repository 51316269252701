import React, {useEffect} from 'react';
import useTableState from '../../utility/hooks/useTableState';
import useNotification from '../../utility/hooks/useNotification';
import PropTypes from 'prop-types';
import FILTER_OPERATIONS from '../../constants/filterOperations';
import moment from 'moment';

const buildColumnFiltersOnApply = (filters, filtersFns) =>
    ({
            filters: filters.map(x => ({
                propertyName: x.id,
                value: moment.isMoment(x.value) ? x.value.format('YYYY-MM-DDTHH:mm:ss') : x.value,
                operation: filtersFns[x.id]
            }))
        }
    );

const withPaginationV2 = (
    Component,
    fetchFunction = {
        func: () => {
        },
        props: {},
        settings: {}
    },
    overrides = {}
) => {
    function TableHOC(
        {
            id = 'default',
            initialState = {
                columnFilters: [],
                sorting: []
            },
            ...rest
        }) {
        const {
            initialized,
            pagination,
            filters,
            quickFilters,
            columnVisibility,
            sorting,
            initialize,
            onFiltersChange,
            onSortingChange,
            onPaginationChange,
            onColumnVisibilityChange
        } = useTableState(id);

        useEffect(() => {
            initialize({
                filters: Object.keys(filters).length > 0
                    ? filters
                    : initialState.columnFilters.length > 0
                        ? buildColumnFiltersOnApply(
                            initialState.columnFilters,
                            rest.columns
                                .filter(c => c.enableColumnFilter !== false || c.enableColumnFilter === undefined)
                                .reduce((fns, c) => {
                                    fns[c.accessorKey] = c.filterFn ?? FILTER_OPERATIONS.CONTAINS;

                                    return fns;
                                }, {}))
                        : {},
                sorting: initialState.sorting
            });
        }, []);

        const {data, isLoading, isFetching, isError, error} = fetchFunction.func({
            pageSize: pagination.pageSize,
            pageNumber: pagination.pageIndex,
            sorting: sorting?.length ? sorting[0] : null,
            ...filters,
            ...quickFilters,
            ...fetchFunction.props
        }, {skip: !initialized, ...fetchFunction.settings});
        const {showErrorNotification} = useNotification();

        useEffect(() => {
            if (isError) {
                showErrorNotification(error?.message ?? '');
            }
        }, [isError]);

        const handleColumnFiltersApply = (filters, quickFilters, filtersFns) =>
            onFiltersChange({
                    filters: overrides.buildFiltersBodyOnApply
                        ? overrides.buildFiltersBodyOnApply(filters, filtersFns)
                        : buildColumnFiltersOnApply(filters, filtersFns),
                    quickFilters
                }
            );

        const handleColumnVisibilityChange = values => onColumnVisibilityChange({default: values});

        return (
            initialized
                ? <Component
                    {...rest}
                    initialState={{
                        columnFilters: Object.values(filters)
                            .filter(x => x)
                            .flatMap(x => x.map(f => ({id: f.propertyName, value: f.value}))),
                        sorting: initialState.sorting ?? []
                    }}
                    isLoading={isLoading}
                    isRefetching={isFetching}
                    data={data?.items ?? []}
                    totalItemCount={data?.totalCount ?? 0}
                    pagination={pagination}
                    columnVisibility={columnVisibility?.default}
                    onPaginationModelChange={onPaginationChange}
                    onColumnVisibilityChange={handleColumnVisibilityChange}
                    onColumnFiltersApply={handleColumnFiltersApply}
                    onSortingChange={onSortingChange}
                />
                : ''
        );
    }

    TableHOC.propTypes = {
        initialState: PropTypes.object,
        id: PropTypes.string
    };

    return TableHOC;
};

withPaginationV2.PropTypes = {
    Component: PropTypes.element,
    fetchFunction: PropTypes.shape({
        func: PropTypes.func.isRequired,
        props: PropTypes.object,
        settings: PropTypes.object
    }).isRequired,
    overrides: PropTypes.shape({
        buildFiltersBodyOnApply: PropTypes.func
    })
};

export default withPaginationV2;
