import React, {useEffect, useState} from 'react';
import {Box, Button, Divider, MenuItem, Select, TextField} from '@mui/material';
import Typography from '@mui/material/Typography';
import {DatePicker} from '@mui/x-date-pickers';
import moment from 'moment';
import PropTypes from 'prop-types';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import {useDispatch, useSelector} from 'react-redux';
import {resetUpdatingStatus, retrieveEthnicity} from '../../ProfilesSlice';
import {showSnackbar} from '../../../../../AppLayout/ApplicationSlice';
import {useParams} from 'react-router-dom';

const PassportFields = (
    {
        passports,
        setPassports,
        countries,
        setDeletedPassportIds,
        deletedPassportIds,
        readonly,
        asylymStatus,
        student
    }) => {
    const [editIndex, setEditIndex] = useState(null);
    const [editPassportData, setEditPassportData] = useState(null);
    const [isAddMode, setIsAddMode] = useState(false);
    const [newPassportData, setNewPassportData] = useState({});
    const [errors, setErrors] = useState({});
    const {studentId} = useParams();
    const {updatingSuccessful, currentSchool, selectedProfile} = useSelector(state => state.profilesInfo);
    const dispatch = useDispatch();

    useEffect(() => {
        showNotification(updatingSuccessful);
        if (updatingSuccessful) {
            dispatch(retrieveEthnicity({studentId}));
        }
        dispatch(resetUpdatingStatus());
    }, [updatingSuccessful]);

    const showNotification = (success) => success !== undefined && dispatch({
        true: () => showSnackbar({
            message: 'Ethnicity passport updated successfully', severity: 'success'
        }),
        false: () => showSnackbar({
            message: 'Failed to update ethnicity passport', severity: 'error'
        })
    }[success]());

    const handleCancel = () => {
        setIsAddMode(false);
        setNewPassportData({});
    };

    const handleSave = () => {
        newPassportData.isNew = true;
        setPassports([newPassportData, ...passports]);
        setIsAddMode(false);
        setNewPassportData({});
    };

    const handleChange = (value, key) => {
        let passportData = JSON.parse(JSON.stringify(newPassportData));
        passportData[key] = value;
        setNewPassportData(passportData);
    };

    const handleEditChange = (value, key) => {
        let passportData = JSON.parse(JSON.stringify(editPassportData));
        passportData[key] = value;
        setEditPassportData(passportData);
    };

    const handleEditCancel = () => {
        setEditIndex(null);
        setEditPassportData({});
    };

    const handleEditSave = () => {
        let editedPassports = JSON.parse(JSON.stringify(passports));
        let passport = editedPassports.find(e => e.id === editPassportData.id);
        passport.nationCode = editPassportData.nationCode;
        passport.passportNumber = editPassportData.passportNumber;
        passport.visaNumber = editPassportData.visaNumber;
        passport.nameOnPassport = editPassportData.nameOnPassport;
        passport.issueDate = editPassportData.issueDate;
        passport.expiryDate = editPassportData.expiryDate;
        passport.visaExpiryDate = editPassportData.visaExpiryDate;

        if (!passport.isNew) {
            passport.isEdited = true;
        }

        setPassports(editedPassports);

        setEditIndex(null);
        setEditPassportData({});
    };

    const handleStartEditing = (index) => {
        setEditIndex(index);
        setEditPassportData(passports.find(el => el.id === index));
    };

    const handleDeletePassport = (passportId) => {
        const deletedPassport = passports.find(e => e.id === passportId);
        setPassports(passports.filter(e => e.id !== passportId));
        if (!deletedPassport.isNew) {
            setDeletedPassportIds([passportId, ...deletedPassportIds]);
        }
    };

    const handleError = (errorMessage, key) => {
        let newErrors = {...errors};
        newErrors[key] = errorMessage;
        setErrors(newErrors)
    }

    const isErrorExist = () => Object.values(errors).some(value => value !== null);

    const addPassportFields = () => <Box width={'100%'}>
        <Typography fontWeight="bold" variant="body1">Nationality*</Typography>
        <Select
            value={newPassportData.nationCode}
            sx={{
                width: {
                    xs: '100%',
                    md: '50%',
                },
            }}
            onChange={(event) => handleChange(event.target.value, 'nationCode')}>
            {buildCountryOptions()}
        </Select>
        <Box width={'100%'} display={'flex'} justifyContent={'start'} flexWrap={'wrap'} gap={5} py={1}>
            <TextField
                label="Passport Number"
                variant={'standard'}
                value={newPassportData.passportNumber}
                sx={{width: 300}}
                onChange={(event) => handleChange(event.target.value, 'passportNumber')}
            />
            <TextField
                label={'Name On Passport'}
                variant={'standard'}
                value={newPassportData.nameOnPassport}
                sx={{width: 300}}
                onChange={(event) => handleChange(event.target.value, 'nameOnPassport')}
            />
            {
                newPassportData.nationCode !== 'GBR' && <TextField
                    label={'Visa Number'}
                    variant={'standard'}
                    value={newPassportData.visaNumber}
                    sx={{width: 300}}
                    onChange={(event) => handleChange(event.target.value, 'visaNumber')}
                />
            }
        </Box>

        <Box
            width={'100%'}
            display={'flex'}
            justifyContent={'start'}
            flexWrap={'wrap'}
            my={1}
            gap={5}
        >
            <DatePicker
                label="Issue Date"
                value={newPassportData.issueDate ? moment(newPassportData.issueDate) : null}
                onChange={(newValue) => handleChange(newValue, 'issueDate')}
                sx={{width: 300}}
                onError={e => handleError(e, 'issueDate')}
                maxDate={newPassportData.expiryDate ? moment(newPassportData.expiryDate) : null}
                required={false}
                format={'D-MMM-YYYY'}
            />
            <DatePicker
                label="Expiry Date"
                value={newPassportData.expiryDate ? moment(newPassportData.expiryDate) : null}
                onChange={(newValue) => handleChange(newValue, 'expiryDate')}
                sx={{width: 300}}
                onError={e => handleError(e, 'expiryDate')}
                minDate={newPassportData.issueDate ? moment(newPassportData.issueDate) : null}
                required={false}
                format={'D-MMM-YYYY'}
            />
            {
                newPassportData.nationCode !== 'GBR' && <DatePicker
                    label="Visa Expiry Date"
                    value={newPassportData.visaExpiryDate ? moment(newPassportData.visaExpiryDate) : null}
                    onChange={(newValue) => handleChange(newValue, 'visaExpiryDate')}
                    sx={{width: 300}}
                    onError={e => handleError(e, 'visaExpiryDate')}
                    required={false}
                    format={'D-MMM-YYYY'}
                />
            }
        </Box>
        <Box display={'flex'} gap={1}>
            <Button variant="contained" style={{width: 100}} onClick={handleCancel}>Cancel</Button>
            <Button variant="contained" style={{width: 100}} onClick={handleSave}
                    disabled={!newPassportData.nationCode || isErrorExist()}>Add</Button>
        </Box>

        <Divider sx={{py: 2}}/>
    </Box>;

    const buildCountryOptions = () => {
        let filteredCountries = asylymStatus && asylymStatus !== 'NOT_APPLICABLE'
            ? countries.filter(e => e.code !== 'GBR')
            : countries;
        return filteredCountries?.map(({code, description}) => (
            <MenuItem key={code} value={code}>
                {description}
            </MenuItem>
        ))
    }

    const getPronoun = () => {
        if (!selectedProfile.gender) {
            return 'his/her';
        }
        return selectedProfile.gender === 'M' ? 'his' : 'her';
    }

    return <>
        {
            readonly
                ? ''
                : <>
                    <Typography variant="body1" py={2}>
                        If you are able to provide {`${student.name}’s`} passport details it would be appreciated, but this is
                        not compulsory. As well as providing a formal record of {getPronoun()} identity, we may also use passport
                        information for flight reservations if {student.name} attends any international trips while
                        on-roll at {currentSchool.name}.
                    </Typography>
                    {!isAddMode
                        ? <Button
                            variant="contained"
                            style={{width: 250}}
                            sx={{mb: 2}}
                            onClick={() => setIsAddMode(true)}
                        >Add Passport Details
                        </Button>
                        : addPassportFields()
                    }
                </>
        }
        {passports && passports.map((passport) =>
            <Box key={passport.id} width={'100%'}>
                <Box width={'100%'} display={'flex'} justifyContent={'space-between'} flexWrap={'wrap'} py={1} gap={3}
                     alignItems={'center'}>
                    <Box flex={2}>
                        <Typography fontWeight="bold" variant="body1"
                                    color={editIndex !== passport.id ? 'silver' : 'standard'}>
                            {editIndex !== passport.id ? 'Nationality' : 'Nationality*'}
                        </Typography>
                        <Select
                            value={editIndex !== passport.id ? passport.nationCode : editPassportData.nationCode}
                            sx={{
                                width: {
                                    xs: '100%',
                                    md: '50%',
                                },
                            }}
                            onChange={(event) => handleEditChange(event.target.value, 'nationCode')}
                            disabled={readonly || editIndex !== passport.id}
                        >
                            {buildCountryOptions()}
                        </Select>
                    </Box>
                    {
                        !readonly && <>
                            {editIndex !== passport.id
                                ? <Box display={'flex'} gap={2}>
                                    <EditIcon onClick={() => handleStartEditing(passport.id)} sx={{cursor: 'pointer'}}/>
                                    <DeleteIcon sx={{cursor: 'pointer'}} onClick={() => handleDeletePassport(passport.id)}/>
                                </Box>
                                : <Box display={'flex'} gap={2}>
                                    <SaveIcon
                                        sx={{cursor: !isErrorExist() ? 'pointer' : 'not-allowed'}}
                                        color={!isErrorExist() ? 'primary' : 'disabled'}
                                        onClick={!isErrorExist() ? handleEditSave : undefined}
                                    />
                                    <CancelIcon onClick={handleEditCancel} sx={{cursor: 'pointer'}}/>
                                </Box>}
                        </>
                    }
                </Box>

                <Box width={'100%'} display={'flex'} justifyContent={'start'} flexWrap={'wrap'} py={1} gap={5}>
                    <TextField
                        label="Passport Number"
                        variant={'standard'}
                        value={editIndex !== passport.id ? passport.passportNumber : editPassportData.passportNumber}
                        sx={{width: 300}}
                        onChange={(event) => handleEditChange(event.target.value, 'passportNumber')}
                        disabled={readonly || editIndex !== passport.id}
                    />
                    <TextField
                        label={'Name On Passport'}
                        variant={'standard'}
                        value={editIndex !== passport.id ? passport.nameOnPassport : editPassportData.nameOnPassport}
                        sx={{width: 300}}
                        onChange={(event) => handleEditChange(event.target.value, 'nameOnPassport')}
                        disabled={readonly || editIndex !== passport.id}
                    />

                    {
                        (editIndex !== passport.id
                            ? passport?.nationCode !== 'GBR'
                            : editPassportData?.nationCode !== 'GBR') &&
                            <TextField
                                label={'Visa Number'}
                                variant={'standard'}
                                value={editIndex !== passport.id ? passport.visaNumber : editPassportData.visaNumber}
                                sx={{width: 300}}
                                onChange={(event) => handleEditChange(event.target.value, 'visaNumber')}
                                disabled={readonly || editIndex !== passport.id}
                            />
                    }
                </Box>
                <Box width={'100%'} display={'flex'} justifyContent={'start'} flexWrap={'wrap'} my={2} gap={5}>
                    <DatePicker
                        label="Issue Date"
                        value={editIndex !== passport.id
                            ? passport.issueDate ? moment(passport.issueDate) : null
                            : editPassportData.issueDate ? moment(editPassportData.issueDate) : null
                        }
                        onChange={(newValue) => handleEditChange(newValue, 'issueDate')}
                        sx={{width: 300}}
                        onError={e => handleError(e, 'issueDate')}
                        required={false}
                        disabled={readonly || editIndex !== passport.id}
                        maxDate={editPassportData?.expiryDate ? moment(editPassportData.expiryDate) : null}
                        format={'D-MMM-YYYY'}
                    />

                    <DatePicker
                        label="Expiry Date"
                        value={editIndex !== passport.id
                            ? passport.expiryDate ? moment(passport.expiryDate) : null
                            : editPassportData.expiryDate ? moment(editPassportData.expiryDate) : null
                        }
                        onChange={(newValue) => handleEditChange(newValue, 'expiryDate')}
                        sx={{width: 300}}
                        onError={e => handleError(e, 'expiryDate')}
                        required={false}
                        disabled={readonly || editIndex !== passport.id}
                        minDate={editPassportData?.issueDate ? moment(editPassportData.issueDate) : null}
                        format={'D-MMM-YYYY'}
                    />

                    {
                        (editIndex !== passport.id
                            ? passport?.nationCode !== 'GBR'
                            : editPassportData?.nationCode !== 'GBR') &&
                            <DatePicker
                            label="Visa Expiry Date"
                            value={editIndex !== passport.id
                                ? passport.visaExpiryDate ? moment(passport.visaExpiryDate) : null
                                : editPassportData.visaExpiryDate ? moment(editPassportData.visaExpiryDate) : null
                            }
                            onChange={(newValue) => handleEditChange(newValue, 'visaExpiryDate')}
                            sx={{width: 300}}
                            onError={e => handleError(e, 'visaExpiryDate')}
                            required={false}
                            disabled={readonly || editIndex !== passport.id}
                            format={'D-MMM-YYYY'}
                        />
                    }
                </Box>
            </Box>
        )}
    </>;
};

PassportFields.propTypes = {
    passports: PropTypes.array,
    countries: PropTypes.array,
    deletedPassportIds: PropTypes.array,
    setPassports: PropTypes.func,
    setDeletedPassportIds: PropTypes.func,
    readonly: PropTypes.bool,
    asylymStatus: PropTypes.string,
    student: PropTypes.object,
};

export default PassportFields;
