import React, {useEffect, useMemo, useState} from 'react';
import {
    Box,
    Button,
    Checkbox,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    MenuItem,
    Stack,
    Switch,
    TextField,
    Typography
} from '@mui/material';
import PropTypes from 'prop-types';
import useTextFields from '../utility/hooks/useTextFields';
import {yellow} from '@mui/material/colors';
import WarningIcon from '@mui/icons-material/Warning';
import {DatePicker} from '@mui/x-date-pickers';

const OptionComponent = ({type, ...props}) => ({
    checkbox: () => <Checkbox {...props}/>,
    switch: () => <Switch {...props}/>
})[type]() ?? '';

const AdmicityForm = (
    {
        title,
        textFields,
        options,
        custom,
        handleSubmit,
        handleChange,
        errorCaption,
        buttonText,
        actionsContainerProps,
        buttonFullWidth = true,
        isButtonDisabled,
        setIsChildFormValid = null,
        showWarningMessage = false
    }, ref) => {
    const {fields, reset} = useTextFields(textFields);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [customFields, setCustomFields] = useState({});
    const [isSubmitted, setIsSubmitted] = useState(false);
    /* eslint-disable react/prop-types */
    const isFormValid = useMemo(() => {
        return !Object.values(fields).some(({textFieldProps: props}) => props.error || (props.value === '' && props.required));
    }, [fields]);
    /* eslint-enable react/prop-types */

    const fieldComponents = {
        select: (textFieldProps) => (
            <TextField
                key={`select_${textFieldProps.name}`}
                variant="outlined"
                select
                margin="dense"
                disabled={isSubmitted}
                fullWidth
                {...textFieldProps}
            >
                {textFields.length
                    ? textFields.find(x => x.name === textFieldProps.name)
                        ?.options
                        ?.map(x => (
                            <MenuItem
                                key={x.value}
                                value={x.value}
                            >
                                {x.title}
                            </MenuItem>
                        ))
                    : <MenuItem/>
                }
            </TextField>
        ),
        date: (textFieldProps) => (
            <>
            <DatePicker
                format={'D-MMM-YYYY'}
                slotProps={{
                    textField: {
                        fullWidth: true,
                        name: textFieldProps.name,
                        margin: 'dense'
                    },
                    field: {
                        readOnly: true
                    }
                }}
                {...{
                    ...textFieldProps,
                    onChange: value => textFieldProps.onChange({target: {value, name: textFieldProps.name}}, {})
                }}
            />
                {textFieldProps.error && <FormHelperText error>
                    {textFieldProps.helperText}
                </FormHelperText>
                }
            </>
        ),
        text: (textFieldProps) => (
            <React.Fragment key={textFieldProps.name}>
                {showWarningMessage && textFieldProps.changed && textFieldProps.onChangeWarningMessage && (
                    <Box display="flex" alignItems="center" mt={1}>
                        <WarningIcon sx={{color: yellow[700], mr: 1}}/>
                        <FormHelperText sx={{fontWeight: 'bold'}} error>
                            {textFieldProps.onChangeWarningMessage}
                        </FormHelperText>
                    </Box>
                )}
                <TextField
                    key={`textField_${textFieldProps.name}`}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    disabled={isSubmitted}
                    InputLabelProps={{
                        style: {overflow: 'visible'},
                    }}
                    {...textFieldProps}
                />
            </React.Fragment>
        )
    };

    useEffect(() => {
        return () => {
            reset();
            setSelectedOptions([]);
        };
    }, []);

    useEffect(() => {
        let activeOptionsIndexes = options?.reduce((result, option, index) => {
            if (option.initialValue) {
                result.push(index);
            }
            return result;
        }, []);

        setSelectedOptions(activeOptionsIndexes);
    }, [options]);

    useEffect(() => {
        setCustomFields(custom?.reduce((fields, customField) => {
            fields[customField.name] = customField.initialValue;
            return fields;
        }, {}));
    }, [custom]);

    useEffect(() => {
        if (setIsChildFormValid !== null) {
            setIsChildFormValid(isFormValid);
        }
    }, [isFormValid]);

    const onSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitted(true);
        const textFieldValues = Object.values(fields).reduce((obj, field) => {
            obj[field.textFieldProps.name] = field.otherProps.nullableOnEmpty && field.textFieldProps.value === ''
                ? null
                : field.textFieldProps.value;
            return obj;
        }, {});
        const optionValues = options?.reduce((obj, option, index) => {
            obj[option.name] = selectedOptions.includes(index);
            return obj;
        }, {});

        try {
            await handleSubmit({...textFieldValues, ...optionValues, ...customFields});
        } catch (e) {
        } finally {
            setIsSubmitted(false);
        }
    };

    const handleSelectOption = (event, index) => {
        if (!event.target.checked) {
            setSelectedOptions(selectedOptions.filter(x => x !== index));
        } else {
            setSelectedOptions([...selectedOptions, index]);
        }
    };

    return (
        <form ref={ref} onChange={handleChange} onSubmit={onSubmit}>
            <Typography
                variant="h6"
                textAlign="center"
            >{title}
            </Typography>
            {
                Object.values(fields).map(({textFieldProps}) =>
                    fieldComponents[textFieldProps.inputType ?? 'text'](textFieldProps))
            }
            {
                options
                    ? <FormGroup>
                        {
                            options.map(({label, type = 'checkbox'}, index) => (
                                <FormControlLabel
                                    key={index}
                                    disabled={isSubmitted}
                                    control={<OptionComponent
                                        type={type}
                                        checked={selectedOptions.includes(index)}
                                        onChange={(e) => handleSelectOption(e, index)}
                                    />}
                                    label={label}
                                />
                            ))
                        }
                    </FormGroup>
                    : ''
            }
            {
                custom
                    ? custom.map((option) => option.render({
                        onChange: data => setCustomFields(prev => ({...prev, [option.name]: data})),
                        value: customFields[option.name]
                    }))
                    : ''
            }
            {
                errorCaption
                    ? <Typography variant="caption" color="red">{errorCaption}</Typography>
                    : ''
            }
            <Stack
                flexDirection="row"
                justifyContent="flex-start"
                {...actionsContainerProps?.container}
            >
                <Button
                    sx={{marginTop: 1, marginBottom: 1, flexGrow: 0}}
                    variant="contained"
                    color="primary"
                    type="submit"
                    fullWidth={buttonFullWidth}
                    disabled={isSubmitted || !isFormValid || isButtonDisabled}
                    {...actionsContainerProps?.button}
                >
                    {buttonText}
                </Button>
            </Stack>
        </form>
    );
};

AdmicityForm.defaultProps = {
    errorCaption: '',
    handleChange: () => {
    },
    buttonFullWidth: true
};

AdmicityForm.propTypes = {
    title: PropTypes.string,
    textFields: PropTypes.array,
    dateFields: PropTypes.array,
    options: PropTypes.array,
    custom: PropTypes.array,
    handleSubmit: PropTypes.func,
    handleChange: PropTypes.func,
    setIsChildFormValid: PropTypes.func,
    errorCaption: PropTypes.string,
    buttonText: PropTypes.string,
    buttonFullWidth: PropTypes.bool,
    isButtonDisabled: PropTypes.bool,
    actionsContainerProps: PropTypes.object,
    showWarningMessage: PropTypes.bool,
};

export default React.forwardRef(AdmicityForm);