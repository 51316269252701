import React, {useEffect} from 'react';
import FormRequest from './FormRequest';
import {useParams} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {useGetFormRequestQuery, useSubmitFormMutation} from '../../../../api/services/formRequestsService';
import {showSnackbar} from '../../../../AppLayout/ApplicationSlice';
import useUser from '../../../../utility/hooks/useUser';
import PropTypes from 'prop-types';
import objectToFormData from '../../../../utility/objectToFormData';
import {generateUUID} from '../../../../utility/uuidUtil';
import useFileDownloader from '../../../../utility/hooks/useFileDownloader';
import {downloadFormRequestAttachment} from '../../../../api/services/filesService';

const SubmitPrivateForm = ({navigateBackRoute}) => {
    const {user} = useUser();
    const {formRequestId} = useParams();
    const dispatch = useDispatch();
    const {
        data: formRequest,
        isLoading,
        isFetching,
        error,
        refetch: refetchFormRequest
    } = useGetFormRequestQuery({requestId: formRequestId, role: user.role});
    const [submitForm, {
        isLoading: isFormSubmitLoading,
        isSuccess: isFormSubmitSuccess,
        isError: isFormSubmitError,
        error: submitError
    }] = useSubmitFormMutation();
    const downloadFile = useFileDownloader();

    useEffect(() => {
        if (isFormSubmitError || isFormSubmitSuccess) {
            if (isFormSubmitSuccess) {
                refetchFormRequest();
            }
            showNotification(isFormSubmitSuccess);
        }
    }, [isFormSubmitSuccess, isFormSubmitError]);

    const showNotification = success => success !== undefined && dispatch(
        {
            true: () => showSnackbar({
                message: `${formRequest.formName} submitted successfully`,
                severity: 'success'
            }),
            false: () => showSnackbar({
                message: submitError,
                severity: 'error'
            })
        }[success]()
    );

    const handleDownloadAttachment = async (fileId) => {
        await downloadFile(downloadFormRequestAttachment, {
            fileId,
            formRequestId,
            role: user.role,
            studentId: formRequest.studentId,
        })
    }

    const handleSubmit = async (data, placeholders) => {
        const responses = {};
        const attachments = [];
        const attachmentKeys = [];

        for (const key in data) {
            if (data[key] instanceof Blob) {
                const file = data[key];
                const fileKey = generateUUID();

                attachments.push(file);
                attachmentKeys.push(fileKey);
                responses[key] = {name: file.name, fileKey};
            } else {
                responses[key] = data[key];
            }
        }

        await submitForm(objectToFormData({
            id: formRequestId,
            responses: JSON.stringify(responses),
            attachments,
            attachmentKeys,
            placeholders
        }));
    };

    return <FormRequest
        formRequest={formRequest}
        isLoading={isLoading || isFetching || isFormSubmitLoading}
        error={error}
        onSubmit={handleSubmit}
        onDownloadAttachment={handleDownloadAttachment}
        navigateBackRoute={navigateBackRoute}
    />;
};

SubmitPrivateForm.propTypes = {
    navigateBackRoute: PropTypes.string.isRequired
};
export default SubmitPrivateForm;