import React from 'react';
import {Box, Button} from '@mui/material';
import Typography from '@mui/material/Typography';
import {formatDate} from '../../../../utility/dateUtil';
import PropTypes from 'prop-types';

const ActionConfirmButton = (
    {
        confirmedAt,
        confirmedBy,
        onConfirm,
        onSave,
        enableSaveButton,
        enableConfirmButton = true,
        readonly,
        confirmButtonText = 'Confirm'
    }) => {
    return (
        <Box
            display="flex"
            justifyContent="end"
            paddingTop={1}
            gap={2}
        >
            {
                confirmedAt && confirmedBy
                    ? <Typography mt={3}>Confirmed at {formatDate(confirmedAt)} by {confirmedBy}</Typography>
                    : ''
            }
            {!enableSaveButton && !confirmedAt
                ? <Button
                    variant="contained"
                    sx={{
                        my: 2,
                        width: 280
                    }}
                    disabled={!enableConfirmButton}
                    onClick={onConfirm}>{confirmButtonText}
                </Button>
                : !readonly
                    ? <Button
                        variant="contained"
                        sx={{
                            my: 2,
                            width: 100
                        }}
                        onClick={onSave}
                        disabled={!enableSaveButton}>Save
                    </Button>
                    : null
            }
        </Box>
    );
};

ActionConfirmButton.propTypes = {
    confirmedAt: PropTypes.string,
    confirmedBy: PropTypes.string,
    onConfirm: PropTypes.func,
    onSave: PropTypes.func,
    enableSaveButton: PropTypes.bool,
    enableConfirmButton: PropTypes.bool,
    readonly: PropTypes.bool,
    confirmButtonText: PropTypes.string
};

export default ActionConfirmButton;