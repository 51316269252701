import {useNavigate, useParams, useSearchParams} from 'react-router-dom';
import {useEffect} from 'react';
import PropTypes from 'prop-types';

const RedirectRoute = ({path}) => {
    const {id} = useParams();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const queryParams = searchParams.size > 0 ? `?${searchParams.toString()}` : '';

    useEffect(() => {
        navigate(id ? `/${path}/${id}${queryParams}` : `/${path}${queryParams}`);
    }, [id, navigate]);

    return null;
};

RedirectRoute.propTypes = {
    path: PropTypes.string
};

export default RedirectRoute;