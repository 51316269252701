export const validateFieldLength = (fieldValue, maxLength, isRequired = true) => {
    let errorMessage = '';

    if (isRequired && (!fieldValue || fieldValue.length === 0)) {
        errorMessage = 'This field is required.';
    } else if (fieldValue.length > maxLength) {
        errorMessage = `The field cannot exceed ${maxLength} characters.`;
    }

    return {
        isValid: errorMessage === '',
        message: errorMessage,
    };
};

export const validateEmail = (value) => {
    const emailRegex = /^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9-]+.)+[a-zA-Z]{2,4}$/;
    const errorMessage = emailRegex.test(value) ? '' : 'Field must be a valid email.';

    return {
        isValid: errorMessage === '',
        message: errorMessage,
    };
};

export const validatePassword = (password) => {
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasDigit = /\d/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);
    let errorMessage = '';

    if (!hasUpperCase) {
        errorMessage = 'Password must include at least one uppercase letter.';
    } else if (!hasLowerCase) {
        errorMessage = 'Password must include at least one lowercase letter.';
    } else if (!hasDigit) {
        errorMessage = 'Password must include at least one digit.';
    } else if (!hasSpecialChar) {
        errorMessage = 'Password must include at least one special character.';
    } else if (password.length < 6) {
        errorMessage = 'Passwords must be at least 6 characters.';
    }

    return {
        isValid: errorMessage === '',
        message: errorMessage
    };
};

export const isValidUPN = (strUpn) => {
    let flag = true;
    strUpn = strUpn.trim();
    if (strUpn.length !== 13) {
        flag = false;
    }
    if (flag && !/[A-HJ-NP-RT-Z](\d{12})/.test(strUpn) && !/[A-HJ-NP-RT-Z](\d{11})[A-HJ-NP-RT-Z]/.test(strUpn)) {
        flag = false;
    }
    if (flag) {
        let upnCheckChar = calculateUPNCheckChar(strUpn);
        if (strUpn.substring(0, 1) !== getAlphaForUPNNumber(upnCheckChar)) {
            flag = false;
        }
    }
    return flag;
};

const calculateUPNCheckChar = (upn) => {
    let num1 = 0;
    for (let startIndex = 1; startIndex < 13; ++startIndex) {
        let num2;
        switch (upn.substring(startIndex, startIndex + 1)) {
            case '0':
                num2 = 0;
                break;
            case '1':
                num2 = 1;
                break;
            case '2':
                num2 = 2;
                break;
            case '3':
                num2 = 3;
                break;
            case '4':
                num2 = 4;
                break;
            case '5':
                num2 = 5;
                break;
            case '6':
                num2 = 6;
                break;
            case '7':
                num2 = 7;
                break;
            case '8':
                num2 = 8;
                break;
            case '9':
                num2 = 9;
                break;
            case 'A':
                num2 = 0;
                break;
            case 'B':
                num2 = 1;
                break;
            case 'C':
                num2 = 2;
                break;
            case 'D':
                num2 = 3;
                break;
            case 'E':
                num2 = 4;
                break;
            case 'F':
                num2 = 5;
                break;
            case 'G':
                num2 = 6;
                break;
            case 'H':
                num2 = 7;
                break;
            case 'J':
                num2 = 8;
                break;
            case 'K':
                num2 = 9;
                break;
            case 'L':
                num2 = 10;
                break;
            case 'M':
                num2 = 11;
                break;
            case 'N':
                num2 = 12;
                break;
            case 'P':
                num2 = 13;
                break;
            case 'Q':
                num2 = 14;
                break;
            case 'R':
                num2 = 15;
                break;
            case 'T':
                num2 = 16;
                break;
            case 'U':
                num2 = 17;
                break;
            case 'V':
                num2 = 18;
                break;
            case 'W':
                num2 = 19;
                break;
            case 'X':
                num2 = 20;
                break;
            case 'Y':
                num2 = 21;
                break;
            case 'Z':
                num2 = 22;
                break;
            default:
                num2 = 0;
                break;
        }
        num1 += num2 * (startIndex + 1);
    }
    return num1 % 23;
};

const getAlphaForUPNNumber = (ThisNum) => {
    let ch = '';
    switch (ThisNum) {
        case 0:
            ch = 'A';
            break;
        case 1:
            ch = 'B';
            break;
        case 2:
            ch = 'C';
            break;
        case 3:
            ch = 'D';
            break;
        case 4:
            ch = 'E';
            break;
        case 5:
            ch = 'F';
            break;
        case 6:
            ch = 'G';
            break;
        case 7:
            ch = 'H';
            break;
        case 8:
            ch = 'J';
            break;
        case 9:
            ch = 'K';
            break;
        case 10:
            ch = 'L';
            break;
        case 11:
            ch = 'M';
            break;
        case 12:
            ch = 'N';
            break;
        case 13:
            ch = 'P';
            break;
        case 14:
            ch = 'Q';
            break;
        case 15:
            ch = 'R';
            break;
        case 16:
            ch = 'T';
            break;
        case 17:
            ch = 'U';
            break;
        case 18:
            ch = 'V';
            break;
        case 19:
            ch = 'W';
            break;
        case 20:
            ch = 'X';
            break;
        case 21:
            ch = 'Y';
            break;
        case 22:
            ch = 'Z';
            break;
    }
    return ch;
};

function validBFPO(postcode) {
    const regex = /^BFPO +[1-9]\d{0,2}$/i;

    return regex.test(postcode);
}

export const validateCountry = (value, fields) => {
    const fieldValues = Object.values(fields).map(field => field.textFieldProps.value);
    const allFieldsEmpty = fieldValues.every(val => !val);
    const someFieldsFilledAndValueFilled = fieldValues.some(val => !!val) && !!value;

    const isValid = allFieldsEmpty || someFieldsFilledAndValueFilled;

    return {
        isValid
    };
};

export const validatePostcode = (postcode, countryCode) => {
    const isBFPO = validBFPO(postcode);

    if (!isBFPO && (!countryCode || countryCode.toUpperCase() !== 'GBR')) {
        return {
            isValid: true
        };
    }

    let str = postcode.trim().toUpperCase();

    while (str.indexOf('  ') >= 0) {
        str = str.replace('  ', ' ');
    }

    if (isBFPO) {
        return {
            isValid: true
        };
    }

    if (str.length < 5) {
        return {
            isValid: false,
            message: 'Invalid postcode'
        };
    }
    if (str.indexOf(' ') < 0) {
        str = str.slice(0, str.length - 3) + ' ' + str.slice(str.length - 3);
    }
    let input = str.replace(' O', ' 0');

    let patterns = [
        '^[a-z]\\d \\d[a-z]{2}$',
        '^[a-z]{2}\\d \\d[a-z]{2}$',
        '^[a-z]\\d[a-z] \\d[a-z]{2}$',
        '^[a-z]\\d{2} \\d[a-z]{2}$',
        '^[a-z]{2}\\d[a-z] \\d[a-z]{2}$',
        '^[a-z]{2}\\d{2} \\d[a-z]{2}$'
    ];

    let regex = new RegExp(patterns.join('|'), 'i');

    return {
        isValid: regex.test(input),
        message: 'Invalid postcode'
    };

};

export const validateGroupFilters = filters => {
    const isValid = filters
            .filter(filter => !filter.isDeleted)
            .length === 0 ||
        filters.some(filter => !filter.excluded && !filter.isDeleted);

    return {
        isValid,
        error: isValid ? '' : 'Student Group Settings cannot consist solely of excluded groups. Please include at least one non-excluded group to proceed'
    };
};
