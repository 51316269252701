export const FIELDS_MAPPING = [
    { title: 'Legal Name', value: 'LegalName', readonly: true },
    { title: 'Preferred Name', value: 'PreferredName' },
    { title: 'Profile Status', value: 'ProfileStatus' },
    { title: 'Sex', value: 'Sex' },
    { title: 'Boarder Status', value: 'BoarderStatus' },
    { title: 'School Groups', value: 'SchoolGroups' },
    { title: 'Parent Contact 1', value: 'ParentContact1' },
    { title: 'Parent Contact 2', value: 'ParentContact2' },
    { title: 'Medical Needs', value: 'MedicalNeeds' },
    { title: 'OTC Medicine', value: 'OtcMedicine' },
    { title: 'SEN', value: 'Sen' },
    { title: 'SEN under assessment', value: 'SenUnderAssessmentSummary' },
    { title: 'Dietary Requirements', value: 'DietaryRequirements' },
    { title: 'Welfare', value: 'Welfare' },
    { title: 'Free School Meals', value: 'FreeSchoolMeals' },
    { title: 'Consents', value: 'Consents' },
    { title: 'Travel Options', value: 'TravelOptions' },
    { title: 'Year Group', value: 'YearGroup' },
    { title: 'Form', value: 'Form' },
    { title: 'House', value: 'House' },
    { title: 'Intake Group', value: 'IntakeGroup' },
    { title: 'Application Status', value: 'ApplicationStatus' },
];