import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import api from '../../../axios';

const initialState = {
    profiles: [],
    totalItemCount: 0,
    totalPages: 0,
    isLoading: true,
    groupName: '',
    selectedProfile: {
        basicInfo: {},
        documentData: [],
        consentData: {},
        ethnicityData: {},
        senStatus: ''
    },
    currentSchool: {},
    isCurrentSchoolLoading: false,
    lastSelectedPaths: [],
    updatingSuccessful: undefined,
    countries: [],
    formGroups: [],
    houseGroups: [],
    yearGroups: [],
    boarderGroups: [],
    isBasicDataLoading: true,
    isDocumentDataLoading: true,
    isConsentDataLoading: true,
    isEthnicityDataLoading: true,
    isEthnicityLookupsLoading: true,
    contactRelations: [],
    ethnicityLookups: {
        ethnicities: [],
        languages: [],
        religions: [],
        asylymStatuses: [],
        nationalIdentities: [],
        firstLanguageSources: [],
        nations: []
    },
    filterId: undefined,
    profileFilters: {
        group: {
            label: 'Group',
            value: ''
        },
        keyword: {
            label: 'Keyword',
            value: '',
            deletable: true
        },
        status: {
            label: 'Status',
            value: 0,
        }
    }
}

export const retrieveProfilesByParent = createAsyncThunk(
    'profiles/retrieveProfilesByParent',
    async ({pageNumber, pageSize}, thunkAPI) => {
        try {
            const response = await api.post('/parent/student/list', {pageNumber, pageSize});
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue({error: error.message});
        }
    }
);

export const retrieveProfile = createAsyncThunk(
    'profiles/retrieveProfile',
    async ({studentId}, thunkAPI) => {
        try {
            const response = await api.get(`/student/${studentId}`);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue({error: error.message});
        }
    }
);

export const retrieveBasicInfo = createAsyncThunk(
    'profiles/retrieveProfileBasicInfo',
    async ({studentId}, thunkAPI) => {
        try {
            const response = await api.get(`/student/${studentId}/basic`);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue({error: error.message});
        }
    }
);

export const retrieveCurrentSchool = createAsyncThunk(
    'profiles/retrieveCurrentSchool',
    async (thunkAPI) => {
        try {
            const response = await api.get('/school/current');
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue({error: error.message});
        }
    }
);

export const updateBasicInfo = createAsyncThunk(
    'profiles/updateProfileBasicInfo',
    async ({studentId, id, studentDetails}, thunkAPI) => {
        try {
            const response = await api.put(
                `/student/${studentId}/basic`,
                {id, studentDetails, studentId});
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue({error: error.message});
        }
    }
);

export const updateStudentByParent = createAsyncThunk(
    'profiles/updateStudentByParent',
    async ({studentId, id, studentDetails}, thunkAPI) => {
        try {
            const response = await api.put(
                `/parent/student/${studentId}/basic`,
                {id, studentDetails, studentId});
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue({error: error.message});
        }
    }
);

export const retrieveCountries = createAsyncThunk(
    'profiles/retrieveCountries',
    async (thunkAPI) => {
        try {
            const response = await api.get('/country/list');
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue({error: error.message});
        }
    }
);

export const retrieveGroupsForBasicDetails = createAsyncThunk(
    'profiles/retrieveGroupsForBasicDetails',
    async ({isApplicant}, thunkAPI) => {
        try {
            const response = await api.get(`/group/basic/list?isApplicant=${isApplicant}`);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue({error: error.message});
        }
    }
);

export const retrieveContactRelations = createAsyncThunk(
    'profiles/retrieveContactRelations',
    async (thunkAPI) => {
        try {
            const response = await api.get('/lookup/relations');
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue({error: error.message});
        }
    }
);

export const confirmProfile = createAsyncThunk(
    'profiles/confirm',
    async ({studentId, type}, thunkAPI) => {
        const body = {studentId, type}
        try {
            const response = await api.patch(`/student/${studentId}/confirm`, body);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue({error: error.message});
        }
    }
);

export const retrieveEthnicity = createAsyncThunk(
    'profiles/retrieveEthnicity',
    async ({studentId}, thunkAPI) => {
        try {
            const response = await api.get(`/student/${studentId}/ethnicity`);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue({error: error.message});
        }
    }
);

export const retrieveEthnicityLookups = createAsyncThunk(
    'profiles/retrieveEthnicityLookups',
    async (thunkAPI) => {
        try {
            const response = await api.get('/lookup/ethnicity');
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue({error: error.message});
        }
    }
);

export const updateEthnicity = createAsyncThunk(
    'profiles/updateEthnicity',
    async ({studentId, ethnicity, newPassports, editedPassports, deletedPassportIds}, thunkAPI) => {
        const body = {
            studentId,
            ethnicity: !ethnicity
                ? null
                : {
                    asylymStatusCode: ethnicity.asylymStatusCode,
                    ethnicityCode: ethnicity.ethnicityCode,
                    firstLanguageCode: ethnicity.firstLanguageCode,
                    homeLanguageCode: ethnicity.homeLanguageCode,
                    nationalIdentityCode: ethnicity.nationalIdentityCode,
                    religionCode: ethnicity.religionCode,
                    firstLanguageSourceCode: ethnicity.firstLanguageSourceCode,
                    englishAdditional: ethnicity.isEnglishAdditional.toString(),
                    countryOfBirthCode: ethnicity.countryOfBirthCode
                },
            newPassports,
            editedPassports,
            deletedPassportIds
        }
        try {
            const response = await api.patch(`/student/${studentId}/ethnicity`, body);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue({error: error.message});
        }
    }
);

export const profilesSlice = createSlice({
    name: 'profile',
    initialState,
    reducers: {
        selectProfile: (state, action) => {
            state.selectedProfile = action.payload
        },
        resetUpdatingStatus: state => {
            state.updatingSuccessful = undefined
        },
        setFilterId: (state, action) => {
            state.filterId = action.payload;
        },
        setLastSelectedPaths: (state, action) => {
            state.lastSelectedPaths = action.payload;
        },
        setProfileFilters: (state, action) => {
            Object.entries(action.payload).forEach(([key, value]) => {
                if (Object.hasOwn(state.profileFilters, key)) {
                    state.profileFilters[key].value = value;
                }
            });
        },
        resetProfileFilters: (state) => {
            state.profileFilters = initialState.profileFilters;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(retrieveProfilesByParent.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(retrieveProfilesByParent.fulfilled, (state, action) => {
                state.isLoading = false;
                state.profiles = action.payload.items;
                state.totalItemCount = action.payload.totalCount;
                state.totalPages = Math.ceil(action.payload.totalCount / 2);
                state.groupName = action.payload.groupName;
            })
            .addCase(retrieveProfilesByParent.rejected, (state) => {
                state.isLoading = false;
                // implement error handling logic
            })
            .addCase(retrieveBasicInfo.pending, (state) => {
                state.isBasicDataLoading = true;
            })
            .addCase(retrieveBasicInfo.fulfilled, (state, action) => {
                state.isBasicDataLoading = false;
                state.selectedProfile.basicInfo = action.payload;
            })
            .addCase(retrieveBasicInfo.rejected, (state) => {
                state.isBasicDataLoading = false;
                // implement error handling logic
            })
            .addCase(retrieveCurrentSchool.pending, (state) => {
                state.isCurrentSchoolLoading = true;
            })
            .addCase(retrieveCurrentSchool.fulfilled, (state, action) => {
                state.isCurrentSchoolLoading = false;
                state.currentSchool = action.payload;
            })
            .addCase(retrieveCurrentSchool.rejected, (state) => {
                state.isCurrentSchoolLoading = false;
            })
            .addCase(retrieveProfile.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(retrieveProfile.fulfilled, (state, action) => {
                state.isLoading = false;
                state.selectedProfile.email = action.payload.email;
                state.selectedProfile.id = action.payload.id;
                state.selectedProfile.isApplicant = action.payload.isApplicant;
                state.selectedProfile.name = action.payload.name;
                state.selectedProfile.photo = action.payload.photo;
                state.selectedProfile.status = action.payload.status;
                state.selectedProfile.statusCategory = action.payload.statusCategory;
                state.selectedProfile.surname = action.payload.surname;
                state.selectedProfile.confirmedAt = action.payload.confirmedAt;
                state.selectedProfile.consentsConfirmedAt = action.payload.consentsConfirmedAt;
                state.selectedProfile.contactsConfirmedAt = action.payload.contactsConfirmedAt;
                state.selectedProfile.medicalConfirmedAt = action.payload.medicalConfirmedAt;
                state.selectedProfile.senConfirmedAt = action.payload.senConfirmedAt;
                state.selectedProfile.dietaryConfirmedAt = action.payload.dietaryConfirmedAt;
                state.selectedProfile.freeSchoolMealsConfirmedAt = action.payload.freeSchoolMealsConfirmedAt;
                state.selectedProfile.welfareConfirmedAt = action.payload.welfareConfirmedAt;
                state.selectedProfile.culturalConfirmedAt = action.payload.culturalConfirmedAt;
                state.selectedProfile.travelModeConfirmedAt = action.payload.travelModeConfirmedAt;
                state.selectedProfile.gender = action.payload.gender;
            })
            .addCase(retrieveProfile.rejected, (state) => {
                state.isLoading = false;
                // implement error handling logic
            })
            .addCase(updateBasicInfo.fulfilled, (state) => {
                state.updatingSuccessful = true;
                // implement error handling logic
            })
            .addCase(updateBasicInfo.rejected, (state) => {
                state.updatingSuccessful = false;
                // implement error handling logic
            })
            .addCase(retrieveCountries.fulfilled, (state, action) => {
                state.countries = action.payload;
            })
            .addCase(retrieveGroupsForBasicDetails.fulfilled, (state, action) => {
                state.formGroups = action.payload.formGroups;
                state.houseGroups = action.payload.houseGroups;
                state.yearGroups = action.payload.yearGroups;
                state.boarderGroups = action.payload.boarderGroups;
            })
            .addCase(updateStudentByParent.fulfilled, (state) => {
                state.updatingSuccessful = true;
            })
            .addCase(updateStudentByParent.rejected, (state) => {
                state.updatingSuccessful = false;
            })
            .addCase(retrieveContactRelations.fulfilled, (state, action) => {
                state.contactRelations = action.payload;
            })
            .addCase(retrieveEthnicity.pending, (state) => {
                state.isEthnicityDataLoading = true;
            })
            .addCase(retrieveEthnicity.fulfilled, (state, action) => {
                state.isEthnicityDataLoading = false;
                state.selectedProfile.ethnicityData = action.payload;
            })
            .addCase(retrieveEthnicity.rejected, (state) => {
                state.isEthnicityDataLoading = false;
            })
            .addCase(updateEthnicity.fulfilled, (state) => {
                state.updatingSuccessful = true;
            })
            .addCase(updateEthnicity.rejected, (state) => {
                state.updatingSuccessful = false;
            })
            .addCase(retrieveEthnicityLookups.pending, (state) => {
                state.isEthnicityLookupsLoading = true;
            })
            .addCase(retrieveEthnicityLookups.fulfilled, (state, action) => {
                state.isEthnicityLookupsLoading = false;
                state.ethnicityLookups = action.payload;
            })
            .addCase(retrieveEthnicityLookups.rejected, (state) => {
                state.isEthnicityLookupsLoading = false;
            })
            .addCase(confirmProfile.fulfilled, (state) => {
                state.updatingSuccessful = true;
            })
            .addCase(confirmProfile.rejected, (state) => {
                state.updatingSuccessful = false;
            })
    },
})

export const {
    selectProfile,
    resetUpdatingStatus,
    setFilterId,
    setProfileFilters,
    resetProfileFilters
} = profilesSlice.actions

export default profilesSlice.reducer