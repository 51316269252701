import api from '../axios';

export const axiosBaseQuery = ({baseUrl} = {baseUrl: process.env.REACT_APP_BACKEND_URL}, args = {}) =>
    async ({url, method, data, params, headers}) => {
        try {
            const result = await api({url: baseUrl + url, method, data, params, headers, ...args});

            return {
                data: result.data,
                headers: result.headers
            };
        } catch (axiosError) {
            let err = axiosError;
            if (args.responseType === 'blob') {
                const errorBody = await err.response?.data?.text()
                return {
                    error: {
                        status: err.response?.status,
                        data: JSON.parse(errorBody)?.message
                    },
                };
            }
            return {
                error: {
                    status: err.response?.status,
                    data: err.response?.data || err.message
                },
            };
        }
    };