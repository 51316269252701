import React from 'react';
import {Box, Button, FormControlLabel, Grid, Switch} from '@mui/material';
import {FIELDS_MAPPING} from '../const/ReportFieldsMapping';

/* eslint-disable react/prop-types */
const FieldsTab = ({excludedFields, handleToggleField, setExcludedFields}) => {
    return (
        <>
            <Box display={'flex'} justifyContent={'end'}>
                <Button onClick={() => setExcludedFields([])}>Show All</Button>
            </Box>
            <Grid container>
                {FIELDS_MAPPING.map(({title, value, readonly}) => (
                    <Grid item xs={4} key={value}>
                        <FormControlLabel
                            control={<Switch
                                checked={!excludedFields.includes(value)}
                                onChange={() => handleToggleField(value)}
                                color="primary"
                                disabled={readonly}
                            />}
                            label={title}
                            labelPlacement="end"
                        />
                    </Grid>
                ))}
            </Grid>
        </>
    );
};

export default FieldsTab;