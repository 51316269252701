import React, {useCallback, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {
    BtnBold,
    BtnBulletList,
    BtnClearFormatting,
    BtnItalic,
    BtnLink,
    BtnNumberedList,
    BtnRedo,
    BtnStrikeThrough,
    BtnStyles,
    BtnUnderline,
    BtnUndo,
    createDropdown,
    Editor,
    EditorProvider,
    Separator,
    Toolbar,
} from 'react-simple-wysiwyg';
import {Box} from '@mui/material';
import {FORM_ACCESS_LEVELS} from '../../../../../../constants/formAccessLevels';
import ColorPicker from './ColorPicker';
import ToggleColorPickerButton from './BtnToogleColorPicker';

const schoolPlaceHolder = [
    ['School Name', 'InsertText', ' @SchoolName ']
];

const placeholders = [
    ['Parent', 'InsertText', ' @Parent '],
    ['Student Forename', 'InsertText', ' @StudentForename '],
    ['Student Full Name', 'InsertText', ' @StudentFullName '],
    ['His/Her', 'InsertText', ' @HisHer '],
    ['He/She', 'InsertText', ' @HeShe '],
    ['Him/Her', 'InsertText', ' @HimHer '],
    ['Parent Title', 'InsertText', ' @Title '],
];

const PrivatePlaceholderDropdown = createDropdown('Placeholders',
    [...schoolPlaceHolder, ...placeholders]);

const PublicPlaceholderDropdown = createDropdown('Placeholders', schoolPlaceHolder);

const RichEditor = ({index, value, currentDescription, setCurrentDescription, isFormLive, accessLevel}) => {
    const [showPicker, setShowPicker] = useState(false);
    const buttonRef = useRef(null);
    const [textColor, setTextColor] = useState('#000000');

    const handlePlaceholderSelect = (placeholder) => {
        setCurrentDescription(currentDescription + placeholder)
    };

    const applyColorCommand = useCallback((color) => {
        setTextColor(color);
        document.execCommand('styleWithCSS', false, true);
        document.execCommand('foreColor', false, color);
    }, []);

    return (
        <Box sx={{pt: 1}}>
            <EditorProvider>
                <Toolbar>
                    <BtnUndo/>
                    <BtnRedo/>
                    <Separator/>
                    <BtnBold/>
                    <BtnItalic/>
                    <BtnUnderline/>
                    <BtnStrikeThrough/>
                    <ToggleColorPickerButton onClick={() => setShowPicker(!showPicker)} ref={buttonRef}
                                             color={textColor}/>
                    <Separator/>
                    <BtnNumberedList/>
                    <BtnBulletList/>
                    <Separator/>
                    <BtnLink/>
                    <BtnClearFormatting/>
                    <Separator/>
                    <BtnStyles/>
                    <Separator/>
                    {showPicker &&
                        <ColorPicker
                            applyColorCommand={applyColorCommand}
                            onClose={() => setShowPicker(false)}
                            anchorEl={buttonRef.current}
                        />}
                    {accessLevel === FORM_ACCESS_LEVELS.private || accessLevel === FORM_ACCESS_LEVELS.publicWithStudentLink
                        ? <PrivatePlaceholderDropdown onChange={(value) => handlePlaceholderSelect(value)}/>
                        : <PublicPlaceholderDropdown onChange={(value) => handlePlaceholderSelect(value)}/>
                    }
                </Toolbar>
                <Box sx={{cursor: 'text'}}>
                    <Editor disabled={isFormLive} value={currentDescription}
                            onChange={e => setCurrentDescription(e.target.value)}/>
                </Box>
            </EditorProvider>
        </Box>
    );
};

RichEditor.propTypes = {
    index: PropTypes.number.isRequired,
    value: PropTypes.string.isRequired,
    currentDescription: PropTypes.string.isRequired,
    setCurrentDescription: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    isFormLive: PropTypes.bool.isRequired,
    accessLevel: PropTypes.number.isRequired,
};

export default RichEditor;