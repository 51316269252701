import {FORM_REQUEST_STATUSES, FORM_REQUEST_STATUSES_NAMES} from '../../../../constants/formRequestStatuses';

export const getFormRequestStatusInfo = (status) => ({
    label: FORM_REQUEST_STATUSES_NAMES[status] ?? 'none',
    color: {
        [FORM_REQUEST_STATUSES.created]: 'blueGrey',
        [FORM_REQUEST_STATUSES.sent]: 'orange',
        [FORM_REQUEST_STATUSES.failedToSend]: 'red',
        [FORM_REQUEST_STATUSES.submitted]: 'green',
        [FORM_REQUEST_STATUSES.expired]: 'red',
    }[status] ?? 'grey'
});