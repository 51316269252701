import React from 'react';
import Typography from '@mui/material/Typography';
import {Card, CardContent, CardHeader, List, ListItem, ListItemIcon, ListItemText, Stack, styled} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import InsertDriveFileRoundedIcon from '@mui/icons-material/InsertDriveFileRounded';
import PropTypes from 'prop-types';
import blueGrey from '@mui/material/colors/blueGrey';

const DocumentsCountSpan = styled('span')(({theme}) => ({
    backgroundColor: blueGrey[50],
    color: blueGrey[700],
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(0.75),
    paddingBottom: theme.spacing(0.75),
    borderRadius: theme.spacing(1),
    fontWeight: 600,
    fontSize: 12
}));

const DocumentList = ({documents, readonly}) => {
    return (
        <Stack flexDirection="column" gap={2}>
            {
                documents.map(x => x.documents.length > 0
                    ? (
                        <Card
                            key={`card_${x.sectionName}`}
                            variant="outlined">
                            <CardHeader
                                key={`cardHeader_${x.sectionName}`}
                                disableTypography
                                title={
                                    x.sectionName
                                        ? <Stack flexDirection="row" gap={1}>
                                            <Typography
                                                key={`label_${x.sectionName}`}
                                                variant="subtitle1"
                                                color="text.secondary"
                                                fontWeight={600}>{x.sectionName}
                                            </Typography>
                                            <DocumentsCountSpan>{x.documents.length}</DocumentsCountSpan>
                                        </Stack>
                                        : ''
                                }
                            />
                            <CardContent
                                sx={{
                                    maxHeight: 400,
                                    overflow: 'hidden',
                                    overflowY: 'scroll',
                                    paddingTop: 0
                                }}
                            >
                                <List
                                    key={`list_${x.sectionName}`}
                                    sx={{
                                        padding: 0,
                                    }}
                                    onScroll={x.paginationHandler}
                                >
                                    {
                                        x.documents.map((document, index) => <ListItem
                                            sx={{
                                                paddingLeft: 0
                                            }}
                                            key={`${x.sectionName}_${index}`}
                                            secondaryAction={
                                                <Stack direction="row" spacing={1}>
                                                    {
                                                        document.id &&
                                                        <IconButton
                                                            edge="end"
                                                            sx={{
                                                                ':hover': {
                                                                    color: 'primary.main'
                                                                }
                                                            }}
                                                            onClick={async () => await x.onDownload(document.id)}
                                                        >
                                                            <FileDownloadRoundedIcon fontSize={'small'}/>
                                                        </IconButton>
                                                    }
                                                    {
                                                        x.allowDelete && !readonly
                                                            ? <IconButton
                                                                edge="end"
                                                                sx={{
                                                                    ':hover': {
                                                                        color: 'error.light'
                                                                    }
                                                                }}
                                                                onClick={() => x.onDelete(document)}
                                                            >
                                                                <DeleteOutlineRoundedIcon fontSize={'small'}/>
                                                            </IconButton>
                                                            : ''
                                                    }
                                                </Stack>
                                            }
                                        >
                                            <ListItemIcon>
                                                <InsertDriveFileRoundedIcon sx={{color: 'primary.main'}}/>
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={document.name}
                                                secondary={
                                                    <>
                                                        {document.isForParent ? <span>Available for parent</span> : ''}
                                                        {document.summary ? <span>{document.summary}</span> : ''}
                                                    </>
                                                }
                                                primaryTypographyProps={{
                                                    style: {
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        maxWidth: 'calc(100% - 60px)'
                                                    }
                                                }}
                                                secondaryTypographyProps={{
                                                    style: {
                                                        maxWidth: 'calc(100% - 60px)'
                                                    }
                                                }}
                                            />
                                        </ListItem>)
                                    }
                                </List>
                            </CardContent>
                        </Card>
                    )
                    : null
                )
            }
        </Stack>
    );
};

DocumentList.propTypes = {
    documents: PropTypes.array,
    readonly: PropTypes.bool
};

export default DocumentList;