import {Box, styled} from '@mui/material';

export const SettingsSection = styled(Box)(({theme}) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    maxWidth: '100%',
    [theme.breakpoints.up('sm')]: {
        maxWidth: '65%',
    },
    [theme.breakpoints.up('md')]: {
        maxWidth: '50%',
    },
    [theme.breakpoints.up('lg')]: {
        maxWidth: '45%',
    },
}));