import React, {useEffect, useRef, useState} from 'react';
import {Box, Checkbox} from '@mui/material';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import EditMultiFieldsBuilder from './Shared/EditMultiFieldsBuilder';
import RichEditorResultText from './Shared/RichEditorResultText';
import {stripHtmlTags} from './AdditionalNotes';

const MultipleChoice = ({
                            index,
                            id,
                            value,
                            onChange,
                            description,
                            isEditMode,
                            enableValidation,
                            options,
                            isFormArchived,
                            relatedComponent,
                            readonly,
                            placeholderValues,
                            accessLevel,
                            onUpdateDescription,
                            componentToEditIndex,
                        }) => {
    const [currentValue, setCurrentValue] = useState(description);
    const prevPropRef = useRef();

    useEffect(() => {
        setCurrentValue(description)
    }, [description]);

    useEffect(() => {
        if (prevPropRef.current === index && componentToEditIndex !== prevPropRef.current) {
            onUpdateDescription({index, description: stripHtmlTags(currentValue), formattedDescription: currentValue});
        }
        prevPropRef.current = componentToEditIndex;
    }, [componentToEditIndex]);

    return (
        <Box>
            {isEditMode
                ? <EditMultiFieldsBuilder onChange={onChange} enableValidation={enableValidation}
                                          description={description} index={index} options={options}
                                          isFormArchived={isFormArchived} relatedComponent={relatedComponent}
                                          accessLevel={accessLevel} currentDescription={currentValue}
                                          setCurrentDescription={setCurrentValue}/>
                : <Box key={`${description}-${value}`}>
                    <RichEditorResultText id={id} description={description} placeholderValues={placeholderValues}
                                          readonly={readonly} enableValidation={enableValidation}/>
                    {options.map((option) => <Box display={'flex'} gap={1}
                                                  key={`${description}-option-${option.id}`}
                                                  alignItems={'center'}
                        >
                            <Checkbox
                                size={'small'}
                                value={value?.includes(option.id)} checked={value?.includes(option.id)}
                                onChange={e => onChange({
                                    id,
                                    optionValue: e.target.checked,
                                    optionIndex: option.id
                                })}/>
                            <Typography sx={{
                                marginTop: 0.5,
                                marginBottom: 0.5,
                                wordWrap: 'break-word',
                                maxWidth: '800px'
                            }}
                            >{option.description}</Typography>
                        </Box>
                    )}
                </Box>
            }
        </Box>
    );
};

MultipleChoice.propTypes = {
    id: PropTypes.string,
    value: PropTypes.string,
    index: PropTypes.number,
    options: PropTypes.array,
    description: PropTypes.string,
    enableValidation: PropTypes.bool,
    onChange: PropTypes.func,
    isEditMode: PropTypes.bool,
    isFormArchived: PropTypes.bool,
    readOnly: PropTypes.bool,
    relatedComponent: PropTypes.object,
    readonly: PropTypes.bool,
    placeholderValues: PropTypes.object,
    accessLevel: PropTypes.number,
    onUpdateDescription: PropTypes.func,
    componentToEditIndex: PropTypes.number
};

export default MultipleChoice;