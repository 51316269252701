import React, {useEffect, useState} from 'react';
import Typography from '@mui/material/Typography';
import {Box, Checkbox, Link, styled, TextField} from '@mui/material';
import moment from 'moment';
import {DatePicker} from '@mui/x-date-pickers';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import PropTypes from 'prop-types';
import {STUDENT_PROFILE_CONFIRMATION_TYPES} from '../../const/StudentProfileConfirmationTypes';
import ConfirmationWrapper from '../../Components/ConfirmationWrapper';
import {useGetFreeSchoolMealsQuery, useManageFreeSchoolMealsMutation} from '../../../../../api/services/studentService';

export const Item = styled(Box)({
    display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', paddingTop: 2
});

const FreeSchoolMealsFields = ({readonly, onConfirmSuccess}) => {
    const [currentlyFree, setCurrentlyFree] = useState(false);
    const [lastFree, setLastFree] = useState(false);
    const [parentFullName, setParentFullName] = useState(null);
    const [parentDateOfBirth, setParentDateOfBirth] = useState(null);
    const [isNotApplicable, setIsNotApplicable] = useState(undefined);
    const [parentInsuranceNumber, setParentInsuranceNumber] = useState(null);
    const [parentFullNameError, setParentFullNameError] = useState(false);
    const [parentDateOfBirthError, setParentDateOfBirthError] = useState(false);
    const [parentInsuranceNumberError, setParentInsuranceNumberError] = useState(false);
    const {selectedProfile, currentSchool} = useSelector(state => state.profilesInfo);
    const {studentId} = useParams();
    const [updateFreeSchoolMeals] = useManageFreeSchoolMealsMutation();
    const {
        data: freeSchoolMeals = {options: {}},
        refetch: refetchFreeSchoolMealsData,
    } = useGetFreeSchoolMealsQuery(studentId);

    useEffect(() => {
        setCurrentlyFree(freeSchoolMeals.currentlyFreeMeals);
        setLastFree(freeSchoolMeals.lastFreeMeals);
        setParentFullName(freeSchoolMeals.parentFullName);
        setParentDateOfBirth(freeSchoolMeals.parentDateOfBirth);
        setParentInsuranceNumber(freeSchoolMeals.parentInsuranceNumber);
        setIsNotApplicable(freeSchoolMeals.isNotApplicable);
    }, [freeSchoolMeals]);

    const handleSave = async () => {
        const body = {
            currentlyFreeMeals: currentlyFree,
            lastFreeMeals: lastFree,
            parentFullName,
            parentDateOfBirth,
            parentInsuranceNumber,
            isNotApplicable,
            studentId
        };

        await updateFreeSchoolMeals({studentId, body});
    };

    const isEdited = () => {
        const flagsEdited = freeSchoolMeals.currentlyFreeMeals !== currentlyFree || freeSchoolMeals.lastFreeMeals !== lastFree || isNotApplicable !== freeSchoolMeals.isNotApplicable;
        const fieldsEdited = freeSchoolMeals.parentFullName !== parentFullName || freeSchoolMeals.parentDateOfBirth !== parentDateOfBirth || freeSchoolMeals.parentInsuranceNumber !== parentInsuranceNumber;
        return flagsEdited || fieldsEdited;
    };

    const isValid = () => {
        const errorExist = parentFullNameError || parentDateOfBirthError || parentInsuranceNumberError;
        const requiredFieldsEmpty = (currentlyFree || lastFree) && (!parentFullName || !parentDateOfBirth || !parentInsuranceNumber);
        return !errorExist && !requiredFieldsEmpty;
    };

    return (
        freeSchoolMeals && <ConfirmationWrapper
                readonly={readonly}
                confirmData={{
                    confirmMessage: `I confirm that the information in the free school meals section of ${selectedProfile.name} ${selectedProfile.surname} student profile is correct and understand it will be relied upon by school staff and any other person or organisation who may have ${selectedProfile.name} ${selectedProfile.surname} in their care on behalf of ${currentSchool.name}`,
                    confirmedAt: freeSchoolMeals.confirmedAt,
                    confirmedBy: freeSchoolMeals.confirmedBy,
                    enableSaveButton: !(((!currentlyFree && !lastFree) && !isNotApplicable) || !isValid() || !isEdited()),
                    enableConfirmButton: !(((!currentlyFree && !lastFree) && !isNotApplicable) || !isValid()),
                    studentId,
                    type: STUDENT_PROFILE_CONFIRMATION_TYPES.FreeSchoolMeals,
                    handleSave,
                    onConfirmSuccess: async () => {
                        await onConfirmSuccess();
                        await refetchFreeSchoolMealsData();
                    },
                    confirmButtonText: 'Confirm Free School Meals'
                }}>
                <Typography variant="h6" gutterBottom>FREE SCHOOL MEALS ELIGIBILITY</Typography>
                <Typography variant="body1" fontWeight={'bold'}>If you are not sure whether or not {selectedProfile.name} is eligible for Free
                    School
                    Meals,
                    further information can be found here: <Link
                        href={'https://www.gov.uk/apply-free-school-meals'}>https://www.gov.uk/apply-free-school-meals</Link>.
                    Changes to {`${selectedProfile.name}'s`} Free School Meal status can be updated in this section
                    of {`${selectedProfile.name}'s `}
                    Student Profile at any time.</Typography>
                <Box py={1} key={`FreeMeals-${currentlyFree}-${lastFree}`}>
                    <Item>
                        <Typography>{selectedProfile.name} does not currently receive Free School Meals
                            and has
                            not
                            been
                            eligible in the last 6 years</Typography>
                        <Checkbox
                            sx={{width: '25px', height: '25px'}}
                            value={isNotApplicable}
                            checked={!!isNotApplicable}
                            disabled={readonly || (currentlyFree || lastFree)}
                            onChange={event => setIsNotApplicable(event.target.checked)}
                        />
                    </Item>
                    {!isNotApplicable &&
                        <>
                            <Item>
                                <Typography>{selectedProfile.name} is currently in receipt of Free
                                    School
                                    Meals</Typography>
                                <Checkbox
                                    sx={{width: '25px', height: '25px'}}
                                    checked={currentlyFree}
                                    disabled={readonly || (isNotApplicable || lastFree)}
                                    onChange={event => setCurrentlyFree(event.target.checked)}
                                />
                            </Item>
                            <Item>
                                <Typography>{selectedProfile.name} has received Free School Meals in the
                                    last 6
                                    years
                                    but
                                    is no longer eligible</Typography>
                                <Checkbox
                                    sx={{width: '25px', height: '25px'}}
                                    checked={lastFree}
                                    disabled={readonly || (currentlyFree || isNotApplicable)}
                                    onChange={event => setLastFree(event.target.checked)}
                                />
                            </Item>
                            {
                                (currentlyFree || lastFree) && <Box>
                                    <TextField
                                        fullWidth
                                        label={'Full name of parent registered for Free School Meals with the Local Authority'}
                                        value={parentFullName}
                                        required={true}
                                        error={parentFullNameError}
                                        helperText={parentFullNameError ? 'This field is required' : ''}
                                        autoComplete="off"
                                        disabled={readonly}
                                        onChange={event => {
                                            if (event.target.value.length <= 100) {
                                                setParentFullName(event.target.value);
                                                setParentFullNameError(!event.target.value);
                                            }
                                        }}
                                    />
                                    <Box display={'flex'} gap={3} pt={2}>
                                        <DatePicker
                                            variant={'fulfilled'}
                                            label={'Parent’s date of birth *'}
                                            value={parentDateOfBirth ? moment(parentDateOfBirth) : null}
                                            onChange={value => {
                                                setParentDateOfBirth(value);
                                            }}
                                            onError={(errorMessage, value) => {
                                                setParentDateOfBirthError(errorMessage);
                                            }}
                                            sx={{width: '100%', my: 0}}
                                            maxDate={moment(new Date())}
                                            required={true}
                                            disabled={readonly}
                                            format={'D-MMM-YYYY'}
                                        />
                                        <TextField
                                            fullWidth
                                            label={'Parent’s National Insurance Number'}
                                            value={parentInsuranceNumber}
                                            required={true}
                                            error={parentInsuranceNumberError}
                                            helperText={parentInsuranceNumberError ? 'This field is required' : ''}
                                            onChange={event => {
                                                if (event.target.value.length <= 20) {
                                                    setParentInsuranceNumber(event.target.value);
                                                    setParentInsuranceNumberError(!event.target.value);
                                                }
                                            }}
                                            autoComplete="off"
                                            disabled={readonly}
                                        />
                                    </Box>
                                </Box>
                            }
                        </>
                    }
                </Box>
            </ConfirmationWrapper>
    );
};

FreeSchoolMealsFields.propTypes = {
    readonly: PropTypes.bool,
    onConfirmSuccess: PropTypes.func,
    onDataLoading: PropTypes.func
};

export default FreeSchoolMealsFields;
