import React, {useEffect, useRef, useState} from 'react';
import {Box} from '@mui/material';
import PropTypes from 'prop-types';
import RichEditor from './Shared/RichEditor';
import RichEditorResultText from './Shared/RichEditorResultText';

export const stripHtmlTags = (html) => {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || '';
};

const AdditionalNotes = ({
                             index,
                             id,
                             description,
                             onChange,
                             onUpdateDescription,
                             isEditMode,
                             isFormArchived,
                             placeholderValues,
                             readOnly,
                             accessLevel,
                             componentToEditIndex,
                         }) => {
    const [currentValue, setCurrentValue] = useState(description);
    const prevPropRef = useRef();

    useEffect(() => {
        setCurrentValue(description)
    }, [description]);

    useEffect(() => {
        if (prevPropRef.current === index && componentToEditIndex !== prevPropRef.current) {
            onUpdateDescription({index, description: stripHtmlTags(currentValue), formattedDescription: currentValue});
        }
        prevPropRef.current = componentToEditIndex;
    }, [componentToEditIndex]);

    return (
        <Box>
            {isEditMode
                ? <Box sx={{maxWidth: '52vw', cursor: 'pointer'}}>
                    <RichEditor value={description} onChange={onChange} id={id} index={index} isEditMode={isEditMode}
                                isFormLive={isFormArchived} accessLevel={accessLevel}
                                componentToEditIndex={componentToEditIndex} currentDescription={currentValue}
                                setCurrentDescription={setCurrentValue}/>
                </Box>
                : <RichEditorResultText id={id} description={description} placeholderValues={placeholderValues}
                                        readonly={readOnly} enableValidation={false}/>
            }
        </Box>
    );
};

AdditionalNotes.propTypes = {
    id: PropTypes.string,
    description: PropTypes.string,
    index: PropTypes.number,
    onChange: PropTypes.func,
    onUpdateDescription: PropTypes.func,
    isEditMode: PropTypes.bool,
    isFormArchived: PropTypes.bool,
    readOnly: PropTypes.bool,
    accessLevel: PropTypes.number,
    placeholderValues: PropTypes.object,
    componentToEditIndex: PropTypes.number
};

export default AdditionalNotes;