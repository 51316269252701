import {DateRange} from 'react-date-range';
import {InputAdornment, Popover, TextField} from '@mui/material';
import {useState} from 'react';
import {formatDate} from '../../../../../utility/dateUtil';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import moment from 'moment';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
/* eslint-disable react/prop-types */

const DateRangePicker = ({column, header}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedDateRange, setSelectedDateRange] = useState([
        {
            startDate: undefined,
            endDate: undefined,
            key: 'selection',
        },
    ]);

    const handleClick = (event) => setAnchorEl(event.currentTarget);

    const handleClose = () => setAnchorEl(null);

    const handleSelect = (ranges) => {
        const {startDate, endDate} = ranges.selection;

        if (startDate && endDate) {
            column.setFilterValue([
                moment(startDate).startOf('day').toISOString(),
                moment(endDate).endOf('day').toISOString()]);
            setSelectedDateRange([ranges.selection]);
        } else {
            column.setFilterValue(null);
        }

        handleClose();
    };

    const handleClear = (event) => {
        event.stopPropagation();
        setSelectedDateRange([
            {
                startDate: undefined,
                endDate: undefined,
                key: 'selection',
            },
        ]);
        header.column.setFilterValue(undefined);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'date-range-popover' : undefined;

    return (
        <>
            <TextField
                fullWidth
                placeholder="Select Date Range"
                value={!column.getFilterValue()
                    ? ''
                    : `${formatDate(column.getFilterValue()[0])} - ${formatDate(column.getFilterValue()[1])}`
                }
                onClick={handleClick}
                variant="standard"
                readOnly
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                disabled={column.getFilterValue() == null}
                                size="small"
                                onClick={handleClear}
                            >
                                <ClearIcon fontSize="small"/>
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <DateRange
                    ranges={selectedDateRange}
                    onChange={handleSelect}
                    moveRangeOnFirstSelection={false}
                />
            </Popover>
        </>
    );
};

export default DateRangePicker;