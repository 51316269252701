/* eslint-disable react/prop-types */
import {Autocomplete, Checkbox, TextField} from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import React from 'react';

const StudentsAutocomplete = ({label, options, value, onChange, disablePortal = true}) => (
    <Autocomplete
        multiple
        disableCloseOnSelect
        disablePortal={disablePortal}
        limitTags={3}
        options={options}
        getOptionLabel={(option) => option.label}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderInput={(params) => <TextField {...params} label={label}/>}
        value={value}
        onChange={onChange}
        renderOption={(props, option, {selected}) => (
            <li {...props} key={option.id}>
                <Checkbox
                    icon={<CheckBoxOutlineBlankIcon fontSize="small"/>}
                    checkedIcon={<CheckBoxIcon fontSize="small"/>}
                    style={{marginRight: 8}}
                    checked={selected}
                />
                {option.label}
            </li>
        )}
    />
);

export default StudentsAutocomplete;