import React from 'react';
import {Box, Button, TextField} from '@mui/material';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import AddIcon from '@mui/icons-material/Add';
import RequiredCheckbox from './RequiredCheckbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import RichEditor from './RichEditor';

const EditMultiFieldsBuilder = ({
                                    index,
                                    onChange,
                                    description,
                                    enableValidation,
                                    options,
                                    isFormArchived,
                                    optionLengthLimit,
                                    relatedComponent,
                                    accessLevel,
                                    currentDescription,
                                    setCurrentDescription
                                }) => {

    return (
        <>
            <Box>
                <RichEditor value={description} onChange={onChange} index={index}
                            isFormLive={isFormArchived} accessLevel={accessLevel}
                            currentDescription={currentDescription}
                            setCurrentDescription={setCurrentDescription}/>
                <Box>
                    {options.map((option, optionIndex) =>
                        <Box py={1} display={'flex'} justifyContent={'space-between'} gap={1} alignItems={'center'}
                             key={`${description}-option-${optionIndex}`}>
                            <Typography>{optionIndex + 1}.</Typography>
                            <TextField value={option.description} fullWidth
                                       multiline
                                       disabled={isFormArchived}
                                       label={!option.description ? 'Enter a choice for your respondents to select' : null}
                                       InputLabelProps={{
                                           shrink: false,
                                       }}
                                       onChange={e => {
                                           if (!optionLengthLimit || e.target.value.length < optionLengthLimit) {
                                               onChange({
                                                   index,
                                                   option: {
                                                       description: e.target.value
                                                   },
                                                   optionId: option.id
                                               })
                                           }
                                       }}
                            />
                            <Tooltip
                                title={relatedComponent?.targetState === option.id
                                    ? `This option cannot be deleted as it is currently used in the ${relatedComponent?.relatedFormName} form.`
                                    : null}
                                disabled={isFormArchived}
                            >
                                <IconButton>
                                    <DeleteIcon
                                        onClick={() => relatedComponent?.targetState === option.id || isFormArchived
                                            ? {}
                                            : onChange({index, isRemove: true, optionId: option.id})}
                                    />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    )
                    }
                </Box>
                <Button onClick={() => onChange({index, isAdd: true})} startIcon={<AddIcon/>}
                        cursor={'pointer'}
                        disabled={isFormArchived}>
                    Add option
                </Button>
            </Box>
            <RequiredCheckbox enableValidation={enableValidation} index={index} onChange={onChange}
                              isFormArchived={isFormArchived}/>
        </>
    );
};

EditMultiFieldsBuilder.propTypes = {
    index: PropTypes.number,
    options: PropTypes.array,
    description: PropTypes.string,
    enableValidation: PropTypes.bool,
    isFormArchived: PropTypes.bool,
    onChange: PropTypes.func,
    optionLengthLimit: PropTypes.number,
    relatedComponent: PropTypes.object,
    accessLevel: PropTypes.number,
    currentDescription: PropTypes.string.isRequired,
    setCurrentDescription: PropTypes.func.isRequired,
};

export default EditMultiFieldsBuilder;