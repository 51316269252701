import PropTypes from 'prop-types';
import {Box, Button, Collapse, List, ListItem, ListItemText, styled, Typography} from '@mui/material';
import React, {useState} from 'react';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded';

const initialItemsToShow = 3;

const ListItemStyled = styled(ListItem)({
    paddingLeft: 0
});

export const SchoolGroupsList = ({data: items}) => {
    const [viewAll, setViewAll] = useState(false);

    const handleToggleView = () => setViewAll(!viewAll);

    return (
        <Box>
            {
                items.length === 0
                    ? <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        minHeight={64}
                    >
                        <Typography
                            color="text.secondary"
                            align="center"
                        >No groups to display</Typography>
                    </Box>
                    : <>
                        <List
                            sx={{
                                padding: 0
                            }}
                        >
                            {
                                items
                                    .slice(0, initialItemsToShow)
                                    .map((item, index) => (
                                        <ListItemStyled key={index}>
                                            <ListItemText primary={item}/>
                                        </ListItemStyled>
                                    ))
                            }
                            <Collapse in={viewAll} timeout="auto" unmountOnExit>
                                {
                                    items
                                        .slice(initialItemsToShow)
                                        .map((item, index) => (
                                            <ListItemStyled key={initialItemsToShow + index}>
                                                <ListItemText primary={item}/>
                                            </ListItemStyled>
                                        ))
                                }
                            </Collapse>
                        </List>
                        {items.length > initialItemsToShow && (
                            <Button
                                onClick={handleToggleView}
                                endIcon={viewAll ? <ExpandLessRoundedIcon/> : <ExpandMoreRoundedIcon/>}
                            >
                                {viewAll ? 'View Less' : 'View All'}
                            </Button>
                        )}
                    </>
            }
        </Box>
    );
};

SchoolGroupsList.propTypes = {
    data: PropTypes.array.isRequired
};

export default SchoolGroupsList;