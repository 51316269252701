export const tabs = [
    {
        label: 'Basic',
        route: 'basic',
        shouldRender: (permissions) => [
            'Permission.StudentProfile.Edit',
            'Permission.StudentProfile.View',
        ].some(x => permissions.includes(x))
    },
    {
        label: 'Contacts and Cultural Information',
        route: 'contact',
        shouldRender: (permissions) => [
            'Permission.StudentContacts.Edit',
            'Permission.StudentContacts.View',
            'Permission.StudentEthnicity.Edit',
            'Permission.StudentEthnicity.View',
        ].some(x => permissions.includes(x))
    },
    {
        label: 'Medical',
        route: 'medical',
        shouldRender: (permissions) => [
            'Permission.StudentMedicalNotes.Edit',
            'Permission.StudentMedicalNotes.View'
        ].some(x => permissions.includes(x))
    },
    {
        label: 'SEN and Disabilities',
        route: 'sen',
        shouldRender: (permissions) => [
            'Permission.StudentSEN.Edit',
            'Permission.StudentSEN.View',
        ].some(x => permissions.includes(x))
    },
    {
        label: 'Dietary and Welfare',
        route: 'ethnicity',
        shouldRender: (permissions) => [
            'Permission.StudentDietaryNeedsAndFoodAllergies.Edit',
            'Permission.StudentDietaryNeedsAndFoodAllergies.View',
            'Permission.StudentFreeSchoolMealsEligibility.Edit',
            'Permission.StudentFreeSchoolMealsEligibility.View',
            'Permission.StudentWelfare.Edit',
            'Permission.StudentWelfare.View',
        ].some(x => permissions.includes(x))
    },
    {
        label: 'Consents and Transport',
        route: 'consents',
        shouldRender: (permissions) => [
            'Permission.StudentConsents.View',
            'Permission.StudentConsents.Edit',
            'Permission.StudentTravelMode.View',
            'Permission.StudentTravelMode.Edit'
        ].some(x => permissions.includes(x))
    },
    {
        label: 'Documentation',
        route: 'documentation',
        shouldRender: (_) => true
    },
    {
        label: 'Forms',
        route: 'forms',
        shouldRender: (_) => true
    }
];