/* eslint-disable react/prop-types */

import {Autocomplete, TextField} from '@mui/material';

const CustomAutocomplete = ({header}) => {
    const handleInputChange = (event, newValue) =>
        header.column.setFilterValue(newValue?.value || undefined);

    return (
        <Autocomplete
            value={header.column
                .columnDef
                .filterSelectOptions
                ?.find(option => option.value === header.column.getFilterValue()) || null}
            onChange={handleInputChange}
            options={header.column.columnDef.filterSelectOptions || []}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            getOptionLabel={(option) => option.label}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="standard"
                    placeholder={`Filter by ${header.column.columnDef.header}`}
                />
            )}
        />
    );
};

export default CustomAutocomplete;