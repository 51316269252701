import React, {useEffect, useMemo} from 'react';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import SchoolSetupForm from './SchoolSetupForm';
import {useNavigate, useParams} from 'react-router-dom';
import SchoolMembersSetup from './SchoolMembersSetup';
import AdmicityTabs from '../../../shared-components/AdmicityTabs';
import {Box, Button} from '@mui/material';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import {
    useCreateSchoolMutation,
    useGetSchoolQuery,
    useUpdateSchoolMutation
} from '../../../api/services/schoolManagementService';
import useNotification from '../../../utility/hooks/useNotification';

const SchoolSetup = ({mode, title}) => {
    const {id} = useParams();
    const isEditMode = mode === 'edit';
    const navigate = useNavigate();
    const {
        data: schoolInfo = {
            name: '',
            dfeNumber: '',
            notes: '',
            domain: ''
        },
        isError
    } = useGetSchoolQuery(id, {skip: !isEditMode});
    const [saveSchool] = !isEditMode
        ? useCreateSchoolMutation()
        : useUpdateSchoolMutation();
    const {showErrorNotification, showSuccessNotification, showDetailedErrorNotification} = useNotification();

    useEffect(() => {
        if (isError) {
            showErrorNotification('Failed retrieve school information');
        }
    }, [isError]);

    const handleSaveForm = async (payload) => {
        const {logo, ...rest} = payload;

        try {
            await saveSchool(rest).unwrap();
            showSuccessNotification('School information saved successfully');
        } catch (e) {
            if (e.data.validationErrors) {
                showDetailedErrorNotification('Failed to save school information', e.data.validationErrors);
            } else {
                showErrorNotification('Something went wrong while saving the school information');
            }
        }
    };

    const tabs = useMemo(() => [
        {
            label: 'General',
            component: <SchoolSetupForm
                onSubmit={handleSaveForm}
                editMode
                schoolInfo={schoolInfo}
            />
        },
        {
            label: 'User invites',
            component: <SchoolMembersSetup
                onSubmit={handleSaveForm}
                schoolInfo={schoolInfo}
            />
        }
    ], [schoolInfo]);

    return <>
        <Box paddingTop={2}>
            <Button
                variant="text"
                startIcon={<ArrowBackRoundedIcon/>}
                onClick={() => navigate('/management')}
            >
                Back
            </Button>
            <Typography
                variant="h5"
                py={2}>
                {isEditMode ? `${schoolInfo.name} — ${title}` : title}
            </Typography>
        </Box>
        {
            isEditMode
                ? <AdmicityTabs tabs={tabs}/>
                : <SchoolSetupForm
                    schoolInfo={schoolInfo}
                    onSubmit={handleSaveForm}
                />
        }
    </>;
};

SchoolSetup.propTypes = {
    mode: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
};

export default SchoolSetup;