const convert = value => {
    if (typeof value === 'string' || value instanceof Blob) {
        return value;
    }
    if (typeof value === 'number' || typeof value === 'boolean') {
        return value.toString();
    }
    if (value instanceof Date) {
        return value.toISOString();
    }

    return undefined;
};

const isPlainObject = value => value && typeof value === 'object' && value.constructor === Object;
const objectToFormData = input => {
    const formData = new FormData();

    const serialize = (obj, data = '') => {
        for (const key in obj) {
            if (!Object.prototype.hasOwnProperty.call(obj, key)) {
                continue;
            }

            const path = data ? `${data}[${key}]` : key;
            const value = obj[key];

            if (Array.isArray(value)) {
                if (value.every(item => item instanceof Blob) || value.every(item => typeof item === 'string')) {
                    value.forEach(item => formData.append(key, item));
                } else {
                    value.forEach((item, index) => serialize(item, `${path}[${index}]`));
                }
            } else if (isPlainObject(value)) {
                serialize(value, path);
            } else {
                const convertedValue = convert(value);

                if (convertedValue !== undefined) {
                    formData.append(path, convertedValue);
                }
            }
        }
    };

    serialize(input);

    return formData;
};
export default objectToFormData;