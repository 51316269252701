import {apiService} from '../apiService';

export const lookupsService = apiService.injectEndpoints({
    endpoints: (builder) =>
        ({
            getDietaryNeedOptions: builder.query({
                query: () => ({
                    url: '/lookup/special-dietary-needs'
                })
            }),
            getWelfareOptions: builder.query({
                query: () => ({
                    url: '/lookup/welfare'
                })
            }),
            getMedicalConsentOptions: builder.query({
                query: () => ({
                    url: '/lookup/medical-consents'
                }),
                providesTags: (_) => ['MedicalConsents']
            }),
            manageMedicalConsents: builder.mutation({
                query: (body) => ({
                    url: '/lookup/medical-consents',
                    method: 'POST',
                    data: body,
                }),
                invalidatesTags: ['MedicalConsents']
            }),
            getTravelOptions: builder.query({
                query: () => ({
                    url: '/lookup/travels'
                }),
            }),
            getMedicalEventTypes: builder.query({
                query: () => ({
                    url: '/lookup/medical-events'
                }),
            }),
            getContactTitles: builder.query({
                query: () => ({
                    url: '/lookup/titles'
                }),
            })
        }),
});

export const {
    useGetDietaryNeedOptionsQuery,
    useGetWelfareOptionsQuery,
    useGetMedicalConsentOptionsQuery,
    useManageMedicalConsentsMutation,
    useGetTravelOptionsQuery,
    useGetMedicalEventTypesQuery,
    useGetContactTitlesQuery,
} = lookupsService;
