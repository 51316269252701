import React, {useEffect, useState} from 'react';
import {Box, Button, CircularProgress, Container, Paper, Stack, TextField} from '@mui/material';
import {validateEmail, validateFieldLength, validatePassword} from '../../utility/validationUtil';
import {useNavigate, useParams} from 'react-router-dom';
import TaskAltRoundedIcon from '@mui/icons-material/TaskAltRounded';
import Typography from '@mui/material/Typography';
import {useGetInviteDataQuery} from '../../api/services/profilesService';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import api from '../../axios';
import PropTypes from 'prop-types';

const InviteNotFoundView = () =>
    (
        <Box textAlign="center">
            <ErrorOutlineRoundedIcon sx={{fontSize: 64}} color="error"/>
            <Typography variant="h5" gutterBottom>
                Invalid Link
            </Typography>
            <Typography variant="body1" color="text.secondary" gutterBottom>
                It looks like the invitation link you used is no longer valid. This can happen if the link has
                expired or a new invitation has been generated.
            </Typography>
        </Box>
    );

const RegistrationPage = () => {
    const {inviteId} = useParams();
    const navigate = useNavigate();
    const [registrationError, setRegistrationError] = useState('');
    const [isRegistrationSuccess, setIsRegistrationSuccess] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isFormInvalid, setIsFormInvalid] = useState(true);
    const [registrationData, setRegistrationData] = useState(null);

    const {data: inviteData, isLoading, error} = useGetInviteDataQuery({inviteId});

    useEffect(() => {
        return () => {
            setRegistrationError('');
            setIsRegistrationSuccess(false);
            setIsFormInvalid(true);
        };
    }, []);

    useEffect(() => {
        if (inviteData?.isCompleted) {
            navigate('/login');
        }

        setRegistrationData({
            email: {value: inviteData?.email},
            firstName: {value: inviteData?.firstName},
            lastName: {value: inviteData?.lastName},
            password: {value: ''},
            confirmPassword: {value: ''},
        });
    }, [inviteData]);

    useEffect(() => {
        if (registrationData) {
            setIsFormInvalid(Object.keys(registrationData)
                .some(key => {
                    return registrationData[key].error || (!registrationData[key].value && textFields.find(e => e.name === key)?.required);
                }));
        }
    }, [registrationData]);

    const textFields = [{
        label: 'Email',
        name: 'email',
        disabled: true,
        required: !inviteData?.email,
        initialValue: inviteData?.email,
        validate: (value) => validateEmail(value)
    }, {
        label: 'First Name',
        name: 'firstName',
        disabled: !!inviteData?.firstName,
        required: !inviteData?.firstName,
        initialValue: inviteData?.firstName,
        validate: (value) => validateFieldLength(value, 128)
    }, {
        label: 'Last Name',
        name: 'lastName',
        disabled: !!inviteData?.lastName,
        required: !inviteData?.lastName,
        initialValue: inviteData?.lastName,
        validate: (value) => validateFieldLength(value, 128)
    }, {
        label: 'Password',
        type: 'password',
        name: 'password',
        required: true,
        validate: (password) => validatePassword(password)
    }, {
        label: 'Confirm Password',
        name: 'confirmPassword',
        type: 'password',
        required: true,
        validate: (confirmPassword) => {
            const isMatch = registrationData.password?.value === confirmPassword;
            return {
                isValid: isMatch, message: isMatch ? '' : 'Passwords must match'
            };
        }
    }];

    const handleSubmit = async (e) => {
        try {
            e.preventDefault();
            setIsSubmitted(true);
            const body = {
                firstName: registrationData.firstName.value,
                lastName: registrationData.lastName.value,
                password: registrationData.password.value,
                inviteId
            };
            const url = `/account/register/${inviteId}`;

            await api.post(url, body);
            setIsRegistrationSuccess(true);
        } catch (e) {
            const errorMessages = {
                410: 'Registration invite expired.', 409: 'A user with the same email already exists.'
            };

            setRegistrationError(errorMessages[e.response.status] ?? 'Something went wrong. Please try again later.');

            throw e;
        } finally {
            setIsSubmitted(false);
        }
    };

    const onChange = (value, key, validate) => {
        const updatedValues = JSON.parse(JSON.stringify(registrationData));
        const validationResult = validate(value);

        updatedValues[key].value = value;
        updatedValues[key].error = !validationResult.isValid;
        updatedValues[key].helperText = validationResult.valid ? '' : validationResult.message;

        if (key === 'password' && value === registrationData.confirmPassword?.value) {
            updatedValues.confirmPassword.error = false;
            updatedValues.confirmPassword.helperText = '';
        }

        setRegistrationData(updatedValues);
    };

    const RegistrationSuccessView = () => <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        width="100%"
    >
        <TaskAltRoundedIcon color="success" sx={{fontSize: 64}}/>
        <Typography variant="h5" gutterBottom>Success</Typography>
        <Typography
            textAlign="center"
            gutterBottom
        >Your account has been created successfully. You can now log in using your credentials.
        </Typography>
        <Button
            sx={{
                marginTop: 1
            }}
            variant="contained"
            onClick={() => navigate('/login')}
        >Back to Login Page
        </Button>
    </Box>;

    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="100%"
            flexGrow={1}
        >
            {
                isLoading
                    ? <CircularProgress size={40}/>
                    : <Container maxWidth="xs">
                        {isRegistrationSuccess
                            ? <RegistrationSuccessView/>
                            : error?.status === 404 || error?.status === 400 || error?.status === 410
                                ? <InviteNotFoundView/>
                                : <Paper variant="outlined" sx={{p: 3, display: 'flex', flexDirection: 'column'}}>
                                    <form onChange={() => setRegistrationError('')} onSubmit={handleSubmit}>
                                        <Typography variant="h6" textAlign={'center'}>Create account</Typography>
                                        {registrationData && textFields.map((field, index) => (<TextField
                                            key={`textField-${index}`}
                                            label={field.label}
                                            variant="outlined"
                                            margin="normal"
                                            value={registrationData[field.name]?.value || ''}
                                            helperText={registrationData[field.name]?.helperText}
                                            error={registrationData[field.name]?.error}
                                            onChange={(event) => onChange(event.target.value, field.name, field.validate)}
                                            fullWidth
                                            required
                                            InputLabelProps={{
                                                style: {overflow: 'visible'},
                                            }}
                                            {...field}
                                        />))}
                                        {registrationError
                                            ? <Typography variant="caption" color="red">{registrationError}</Typography>
                                            : ''}
                                        <Stack
                                            flexDirection="row"
                                            justifyContent="flex-start"
                                        >
                                            <Button
                                                sx={{marginTop: 1, marginBottom: 1, flexGrow: 0}}
                                                variant="contained"
                                                color="primary"
                                                type="submit"
                                                fullWidth
                                                disabled={isSubmitted || isFormInvalid}
                                            >
                                                Sign Up
                                            </Button>
                                        </Stack>
                                    </form>
                                </Paper>}
                    </Container>
            }
        </Box>);
};

RegistrationPage.propTypes = {
    role: PropTypes.string
};

export default RegistrationPage;