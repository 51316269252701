import React, {useEffect, useState} from 'react';

/* eslint-disable react/prop-types */
const ImageWithConditionalRender = ({imageUrl, altText}) => {
    const [isValidImage, setIsValidImage] = useState(false);

    useEffect(() => {
        const img = new Image();
        img.src = imageUrl;

        img.onload = () => {
            setIsValidImage(true);
        };

        img.onerror = () => {
            setIsValidImage(false);
        };
    }, [imageUrl]);

    return (
        <>
            {isValidImage
                ? (<img src={imageUrl} alt={altText} style={{height: 100}}/>)
                : null
            }
        </>
    );
};

export default ImageWithConditionalRender;
