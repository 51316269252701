const findParentNodes = (data, value) => {
    if (!value) {
        return [];
    }

    const nodes = Object.values(data)
        .reduce((x, y) => {
            const {filters, ...rest} = y;
            const nodeIndex = filters.findIndex(f => f.id === value);

            if (rest.id === value) {
                x[rest.level] = rest;
            }

            if (nodeIndex !== -1) {
                x[rest.level] = rest;
                const root = filters[nodeIndex];

                for (let i = nodeIndex; i >= 0; i--) {
                    const level = filters[i].level;
                    if (!x[level] && level <= root.level) {
                        x[level] = filters[i];
                    }
                }
            }

            return x;
        }, {});

    return Object.values(nodes);
};

export default findParentNodes;