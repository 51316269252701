import React from 'react';
import ContentPasteOffRoundedIcon from '@mui/icons-material/ContentPasteOffRounded';
import {useNavigate, useOutletContext} from 'react-router-dom';
import AdmicityStatus from '../../../../shared-components/AdmicityStatus';
import {getFormRequestStatusInfo} from '../common/common';
import {
    useGetFormRequestsByParentQuery,
    useGetFormsByCurrentStudentQuery
} from '../../../../api/services/formRequestsService';
import AdmicityTableV2 from '../../../../shared-components/Table/V2/AdmicityTableV2';
import {formatDate} from '../../../../utility/dateUtil';
import withPaginationV2 from '../../../../hoc/pagination/withPaginationV2';
import {
    downloadFormResponsesPdfReport,
    downloadParentFormResponsesPdfReport
} from '../../../../api/services/filesService';
import useFileDownloader from '../../../../utility/hooks/useFileDownloader';
import useNotification from '../../../../utility/hooks/useNotification';
import {FORM_REQUEST_STATUSES} from '../../../../constants/formRequestStatuses';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import {ROLES} from '../../../../constants/roles';
import useUser from '../../../../utility/hooks/useUser';

/* eslint-disable react/prop-types */
const tableColumnsByRole = {
    PARENT: [
        {accessorKey: 'formName', header: 'Form Name'},
        {accessorKey: 'student.forename', header: 'First Name'},
        {accessorKey: 'student.legalSurname', header: 'Last Name'},
        {
            accessorKey: 'status',
            header: 'Status',
            Cell: ({cell}) => <AdmicityStatus {...getFormRequestStatusInfo(cell.getValue())} />,
        },
        {
            accessorKey: 'expirationDate',
            header: 'Expiration Date',
            Cell: ({cell}) => (cell.getValue() ? formatDate(cell.getValue()) : ''),
        },
        {
            id: 'editedBy',
            accessorFn: (row) => row?.editedBy?.fullname,
            header: 'Submitted By',
        },
        {
            accessorKey: 'editedAt',
            header: 'Submitted At',
            Cell: ({cell}) => (cell.getValue() ? formatDate(cell.getValue()) : ''),
        },
    ],
    DEFAULT: [
        {accessorKey: 'formName', header: 'Form Name'},
        {
            accessorKey: 'status',
            header: 'Status',
            Cell: ({cell}) => <AdmicityStatus {...getFormRequestStatusInfo(cell.getValue())} />,
        },
        {
            accessorKey: 'expirationDate',
            header: 'Expiration Date',
            Cell: ({cell}) => (cell.getValue() ? formatDate(cell.getValue()) : ''),
        },
    ],
};

/* eslint-disable react/prop-types */

const RequestAndResponses = () => {
    const navigate = useNavigate();
    const downloadFile = useFileDownloader();
    const {showSuccessNotification} = useNotification();
    const {user} = useUser();
    const outletContext = useOutletContext();
    const isParent = user.role === ROLES.PARENT;

    const TableV2 = withPaginationV2(AdmicityTableV2, {
        func: isParent
            ? useGetFormRequestsByParentQuery
            : useGetFormsByCurrentStudentQuery
    });

    const downloadPdfReport = async (formRequestId = null, formId = null, isBulk = true) => {
        if (isParent) {
            if (formRequestId && !isBulk) {
                await downloadFile(
                    downloadFormResponsesPdfReport,
                    {
                        formId,
                        formRequestId
                    }
                );
            } else {
                await downloadParentFormResponsesPdfReport();
                showSuccessNotification(
                    'The PDFs is being created. It will be sent to your email address when the process is complete.'
                );
            }
        }
    };

    const toolbarIconActions = isParent
        ? [{
            icon: <FileDownloadRoundedIcon/>,
            title: 'Download report',
            type: 'select',
            hideIfEmpty: true,
            menuItems: [
                {
                    label: 'Download PDF',
                    onClick: downloadPdfReport
                }
            ]
        }]
        : [];

    return (
        <TableV2
            id={outletContext?.key}
            role={user.role}
            columns={isParent ? tableColumnsByRole.PARENT : tableColumnsByRole.DEFAULT}
            tableProps={{
                enableSorting: false,
                enableFilters: false,
                enableFullScreenToggle: false
            }}
            toolbarIconActions={toolbarIconActions}
            rowActions={[
                ...(isParent
                    ? [
                        {
                            label: 'Download PDF',
                            action: params => downloadPdfReport(params.id, params.form.id, false),
                            disabled: row => row.status !== FORM_REQUEST_STATUSES.submitted,
                        }
                    ]
                    : [])
            ]}
            onRowClick={(params) => navigate(`${params.id}`)}
            noRowsOverlay={{
                icon: <ContentPasteOffRoundedIcon/>,
                text: 'No requests and responses yet'
            }}
        />
    );
};

export default RequestAndResponses;