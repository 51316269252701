import React, {useState} from 'react';
import {Box, Button, Container, Paper} from '@mui/material';
import {validatePassword} from '../../utility/validationUtil';
import AdmicityForm from '../../shared-components/AdmicityForm';
import api from '../../axios';
import {useNavigate, useParams} from 'react-router-dom';
import TaskAltRoundedIcon from '@mui/icons-material/TaskAltRounded';
import Typography from '@mui/material/Typography';

const ResetPasswordPage = () => {
    const {requestId} = useParams();
    const navigate = useNavigate();
    const [resetPasswordError, setResetPasswordError] = useState('');
    const [isPasswordChangedSuccess, setIsPasswordChangedSuccess] = useState(false);

    const textFields = [
        {
            label: 'Password',
            type: 'password',
            name: 'password',
            dependencies: (fields) =>
                fields.confirmPassword.textFieldProps.value !== ''
                    ? ['confirmPassword']
                    : [],
            validate: validatePassword
        },
        {
            label: 'Confirm Password',
            type: 'password',
            name: 'confirmPassword',
            validate: (confirmPassword, fields) => {
                const isMatch = fields.password.textFieldProps.value === confirmPassword;
                return {
                    isValid: isMatch,
                    message: isMatch ? '' : 'Passwords must match'
                };
            }
        }
    ];

    const handleSubmit = async (payload) => {
        try {
            await api.post(`/account/reset/${requestId}`, {password: payload.password, requestId});
            setIsPasswordChangedSuccess(true);
        } catch (e) {

            setResetPasswordError(
                e.response.status === 404 || e.response.status === 400 || e.response.status === 410
                    ? 'It looks like the reset password link you used is no longer valid. This can happen if the link has expired or a new link has been generated.'
                    : 'Something went wrong. Please try again later.'
            );

            throw e;
        }
    };

    const RegistrationSuccessView = () =>
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            width="100%"
        >
            <TaskAltRoundedIcon color="success" sx={{fontSize: 64}}/>
            <Typography variant="h5" gutterBottom>Success</Typography>
            <Typography textAlign="center" gutterBottom>
                Your password has been changed successfully. You can now log in using your new credentials.
            </Typography>
            <Button sx={{marginTop: 1}} variant="contained" onClick={() => navigate('/login')}>
                Back to Login Page
            </Button>
        </Box>;

    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="100%"
            flexGrow={1}
        >
            <Container maxWidth="xs">
                {
                    isPasswordChangedSuccess
                        ? <RegistrationSuccessView/>
                        : <Paper
                            variant="outlined"
                            sx={{p: 3, display: 'flex', flexDirection: 'column'}}
                        >
                            <AdmicityForm
                                title="Reset Password"
                                textFields={textFields}
                                handleSubmit={handleSubmit}
                                handleChange={() => setResetPasswordError('')}
                                buttonText="Update"
                                errorCaption={resetPasswordError}
                            />
                        </Paper>
                }
            </Container>
        </Box>
    );
};

export default ResetPasswordPage;