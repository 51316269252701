import React from 'react';
import ContentPasteOffRoundedIcon from '@mui/icons-material/ContentPasteOffRounded';
import {useNavigate, useOutletContext, useParams} from 'react-router-dom';
import AdmicityStatus from '../../../../../shared-components/AdmicityStatus';
import {useGetFormsByStudentQuery} from '../../../../../api/services/formRequestsService';
import AdmicityTableV2 from '../../../../../shared-components/Table/V2/AdmicityTableV2';
import {formatDate} from '../../../../../utility/dateUtil';
import withPaginationV2 from '../../../../../hoc/pagination/withPaginationV2';
import {getFormRequestStatusInfo} from '../../../RequestAndResponses/common/common';
import FILTER_OPERATIONS from '../../../../../constants/filterOperations';
import {FORM_REQUEST_STATUSES, FORM_REQUEST_STATUSES_NAMES} from '../../../../../constants/formRequestStatuses';
import {
    downloadFormResponsesPdfReport,
    downloadParentFormResponsesPdfReport
} from '../../../../../api/services/filesService';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import useFileDownloader from '../../../../../utility/hooks/useFileDownloader';
import useNotification from '../../../../../utility/hooks/useNotification';

/* eslint-disable react/prop-types */
const tableProps = {
    columns: [
        {
            accessorKey: 'form.name',
            header: 'Form Name',
            filterFn: FILTER_OPERATIONS.CONTAINS,
        },
        {
            accessorKey: 'status',
            header: 'Status',
            Cell: ({cell}) => <AdmicityStatus {...getFormRequestStatusInfo(cell.getValue())}/>,
            filterVariant: 'select',
            filterFn: FILTER_OPERATIONS.EQUALS,
            filterSelectOptions: Object.values(FORM_REQUEST_STATUSES)
                .map(statusId => ({
                    value: statusId,
                    label: FORM_REQUEST_STATUSES_NAMES[statusId]
                })),
        },
        {
            accessorKey: 'expirationDate',
            header: 'Expiration Date',
            Cell: ({cell}) => cell.getValue() ? formatDate(cell.getValue()) : '',
            enableColumnFilter: false
        },
        {
            accessorKey: 'editedBy.fullname',
            header: 'Submitted By',
            enableColumnFilter: false
        },
        {
            accessorKey: 'editedAt',
            header: 'Submitted At',
            Cell: ({cell}) => cell.getValue() ? formatDate(cell.getValue()) : '',
            enableColumnFilter: false,
        },
    ]
};

/* eslint-enable react/prop-types */
const StudentForms = () => {
    const downloadFile = useFileDownloader();
    const {showSuccessNotification} = useNotification();
    const navigate = useNavigate();
    const {studentId} = useParams();
    const outletContext = useOutletContext();
    const Table = withPaginationV2(AdmicityTableV2, {func: useGetFormsByStudentQuery, props: {studentId}});

    const downloadPdfReport = async (formRequestId = null, formId = null, isBulk = true) => {
        if (formRequestId && !isBulk) {
            await downloadFile(
                downloadFormResponsesPdfReport,
                {
                    formId,
                    formRequestId
                });
        } else {
            await downloadParentFormResponsesPdfReport(studentId);
            showSuccessNotification('The PDFs is being created. It will be sent to your email address when the process is complete.');
        }
    };

    return (
        <Table
            id={outletContext?.key}
            columns={tableProps.columns}
            tableProps={{
                enableSorting: false,
                enableFullScreenToggle: false
            }}
            toolbarIconActions={
                [{
                    icon: <FileDownloadRoundedIcon/>,
                    title: 'Download report',
                    type: 'select',
                    hideIfEmpty: true,
                    menuItems: [
                        {
                            label: 'Download PDF',
                            onClick: downloadPdfReport
                        },
                    ]
                }]
            }
            onRowClick={(params) => navigate(`${params.id}`)}
            noRowsOverlay={{
                icon: <ContentPasteOffRoundedIcon/>,
                text: 'No requests and responses yet'
            }}
            rowActions={[
                {
                    label: 'Download PDF',
                    action: params => downloadPdfReport(params.id, params.form.id, false),
                    disabled: row => row.status !== FORM_REQUEST_STATUSES.submitted
                }
            ]}
        />
    );
};

export default StudentForms;