import useNotification from './useNotification';

const errorMessage = 'Something went wrong while downloading the report';

const useFileDownloader = () => {
    const {showErrorNotification} = useNotification();

    return async (fetchFunction, params = {}) => {
        try {
            const response = await fetchFunction(params);

            if (response.error) {
                showErrorNotification(response.error.customMessage ?? errorMessage);
                return;
            }

            const url = window.URL.createObjectURL(response.blob);
            const link = document.createElement('a');

            link.href = url;
            link.setAttribute('download', response.filename);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            window.URL.revokeObjectURL(url);
        } catch (_) {
            showErrorNotification(errorMessage);
        }
    };
};

export default useFileDownloader;
