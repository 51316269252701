import React, {useEffect, useMemo, useRef, useState} from 'react';
import withPaginationV2 from '../../../../hoc/pagination/withPaginationV2';
import AdmicityTableV2 from '../../../../shared-components/Table/V2/AdmicityTableV2';
import {useGetSchoolUsersQuery, useUpdateUserRoleMutation} from '../../../../api/services/schoolManagementService';
import PersonOffRoundedIcon from '@mui/icons-material/PersonOffRounded';
import {ROLES, ROLES_LABELS} from '../../../../constants/roles';
import AdmicityDialog from '../../../../shared-components/AdmicityDialog';
import AdmicityForm from '../../../../shared-components/AdmicityForm';
import useUser from '../../../../utility/hooks/useUser';
import useNotification from '../../../../utility/hooks/useNotification';

const Table = withPaginationV2(AdmicityTableV2, {func: useGetSchoolUsersQuery});

const columns = [
    {
        accessorKey: 'firstName',
        header: 'First Name',
    },
    {
        accessorKey: 'lastName',
        header: 'Last Name',
    },
    {
        accessorKey: 'email',
        header: 'Email',
    },
    {
        accessorKey: 'role',
        header: 'Role',
        Cell: ({cell}) => cell.getValue()
            ? ROLES_LABELS[cell.getValue()] ?? ''
            : '',
        filterVariant: 'select',
        filterSelectOptions: Object.entries(ROLES_LABELS)
            .filter(([key, _]) => key !== ROLES.SUPER_ADMIN && key !== ROLES.NONE)
            .map(([key, value]) => ({value: key, label: value}))
    }
];

const SchoolUsers = () => {
    const [openEditUserDialog, setOpenEditUserDialog] = useState(false);
    const [userToEdit, setUserToEdit] = useState(undefined);
    const formRef = useRef(null);
    const [updateRole, updateRoleResult] = useUpdateUserRoleMutation();
    const {user} = useUser();
    const {showSuccessNotification, showDetailedErrorNotification} = useNotification();

    useEffect(() => {
        const isSuccess = updateRoleResult.isSuccess;
        const isError = updateRoleResult.isError;
        const error = updateRoleResult.error;

        if (isSuccess) {
            showSuccessNotification('User role successfully updated');
        } else if (isError) {
            showDetailedErrorNotification('Failed to update user role', error.data.validationErrors);
        }
    }, [updateRoleResult]);

    const handleDialogClose = () => {
        setOpenEditUserDialog(false);
        setUserToEdit(undefined);
    };

    const handleDialogOpen = () => setOpenEditUserDialog(true);

    const textFields = useMemo(() => [
        {
            label: 'Role',
            name: 'role',
            select: true,
            inputType: 'select',
            initialValue: userToEdit?.role,
            options: Object.entries(ROLES_LABELS)
                .filter(([key, _]) => key !== ROLES.SUPER_ADMIN && key !== ROLES.NONE)
                .map(([key, label]) => ({
                    value: key,
                    title: label
                }))
        },
    ], [userToEdit?.role]);

    const handleUpdateRole = async data => {
        if (userToEdit.role !== data.role) {
            await updateRole({
                userId: userToEdit.id,
                role: data.role,
            });
        }

        handleDialogClose();
    };

    return (
        <>
            <Table
                columns={columns}
                tableProps={{
                    enableSorting: false,
                    enableColumnResizing: false,
                    enableHiding: false,
                    muiTableBodyRowProps: ({row}) => row.original.id === user.id
                        ? {
                            sx: {
                                '& .MuiTableCell-root': {
                                    fontWeight: 'bold',
                                    cursor: 'pointer'
                                },

                            }
                        }
                        : {}
                }}
                rowActions={[
                    {
                        label: 'Edit',
                        action: (row) => {
                            setUserToEdit({
                                id: row.id,
                                role: row.role
                            });
                            handleDialogOpen();
                        },
                        disabled: (row) => row.id === user.id
                    }
                ]}
                noRowsOverlay={{
                    icon: <PersonOffRoundedIcon/>,
                    text: 'No users has been added'
                }}
            />
            <AdmicityDialog
                handleClose={handleDialogClose}
                title={'Edit user'}
                open={openEditUserDialog}
                actions={[
                    {label: 'Cancel', onClick: handleDialogClose},
                    {
                        label: 'Save',
                        disabled: updateRoleResult.isLoading,
                        onClick: () => {
                            if (typeof formRef.current.requestSubmit === 'function') {
                                formRef.current.requestSubmit();
                            } else {
                                formRef.current.dispatchEvent(new Event('submit', {cancelable: true}));
                            }
                        }
                    }
                ]}
            >
                <AdmicityForm
                    ref={formRef}
                    textFields={textFields}
                    buttonText="Save"
                    handleSubmit={handleUpdateRole}
                    actionsContainerProps={{
                        container: {
                            display: 'none'
                        }
                    }}
                />
            </AdmicityDialog>
        </>
    );
};

export default SchoolUsers;