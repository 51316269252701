import {React, useRef, useState} from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Checkbox,
    Divider,
    ListItemText,
    MenuItem,
    Popover,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PropTypes from 'prop-types';
import useTextField from '../../../utility/hooks/useTextField';
import {validateFieldLength} from '../../../utility/validationUtil';
import FormBuilder from './FormBuilder/FormBuilder';
import {CalendarIcon, DatePicker} from '@mui/x-date-pickers';
import moment from 'moment';
import CancelIcon from '@mui/icons-material/Close';

// Function identifies Accordions in the list to append to their parent Accordions
const RecursiveAccordion = ({category, templates, handleTemplateChange}) => {
    const [expanded, setExpanded] = useState(false);
// Find where category equals this category when called
    const categoryForms = templates.filter(
        (template) => template.category === category);

    // Sort "Empty Form" of "Blank" Category to the top
    const sortedCategoryForms = categoryForms.some(
        (form) => form.category === 'Blank'
    )
        ? categoryForms.sort((a, b) =>
            a.category === 'Blank' && a.name === 'Empty Form'
                ? -1
                : b.category === 'Blank' && b.name === 'Empty Form' ? 1 : 0
        )
        : categoryForms;

    // Find subcategories where the `parentCategory` matches this category
    const subcategories = [
        ...new Set(
            templates
                .filter((template) => template.parentCategory === category)
                .map((template) => template.category)
        ),
    ];

    return (
        <Accordion
            expanded={expanded}
            onChange={(e, isExpanded) => setExpanded(isExpanded)}
            sx={{
                boxShadow: 'none',
                '&.Mui-expanded': {
                    my: 0,
                },
                '&:before': {
                    display: 'none',
                },
            }}
        >
            <AccordionSummary
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    pl: 0,
                    pr: 1,
                    minHeight: '35px',
                    maxHeight: '35px',
                    '&.Mui-expanded': {
                        minHeight: '35px',
                        maxHeight: '35px',
                    },
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        transform: expanded ? 'rotate(360deg)' : 'rotate(270deg)',
                        transition: 'transform 0.2s',
                        mr: 1,
                    }}
                >
                    <ExpandMoreIcon/>
                </Box>
                <Typography sx={{fontWeight: 'bold'}}>{category}</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{pl: 2, py: 0}}>
                <Box>
                    {/* Render subcategories as nested accordions */}
                    {subcategories.map((subcategory) => (
                        <RecursiveAccordion
                            key={subcategory}
                            category={subcategory}
                            templates={templates}
                            handleTemplateChange={handleTemplateChange}
                        />
                    ))}
                    {/* Render all forms for this category */}
                    {sortedCategoryForms.map(({id, name}) => (
                        <MenuItem
                            key={id}
                            onClick={() => {
                                handleTemplateChange({target: {value: id}});
                            }}
                            sx={{
                                whiteSpace: 'normal',
                                wordBreak: 'break-word',
                                mt: 1,
                                mb: 0,
                                py: 0
                            }}
                        >
                            {name}
                        </MenuItem>
                    ))}
                </Box>
            </AccordionDetails>
        </Accordion>
    );
};

RecursiveAccordion.propTypes = {
    category: PropTypes.string.isRequired,
    templates: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
            name: PropTypes.string.isRequired,
            category: PropTypes.string.isRequired,
            parentCategory: PropTypes.string,
        })
    ).isRequired,
    handleTemplateChange: PropTypes.func.isRequired,
};

export const TemplateDropdown = ({templates, handleTemplateChange}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedFormName, setSelectedFormName] = useState('');
    const textFieldRef = useRef();

    // Extract only top-level categories (those without a `parentCategory`)
    const topCategories = [
        ...new Set(
            templates
                .filter((template) => !template.parentCategory)
                .map((template) => template.category)
        ),
    ];
// Sort so 'Blank' is the first category
    const sortedTopCategories = topCategories.sort((a, b) => {
        if (a === 'Blank') return -1;
        if (b === 'Blank') return 1;
        return 0;
    });

    const handleDropdownOpen = () => setAnchorEl(textFieldRef.current);
    const handleDropdownClose = () => setAnchorEl(null);

    const open = Boolean(anchorEl);

    return (
        <Box>
            <TextField
                ref={textFieldRef}
                fullWidth
                onClick={handleDropdownOpen}
                placeholder="Select a Template"
                variant="outlined"
                value={selectedFormName}
                InputProps={{
                    readOnly: true,
                    sx: {
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                    },
                }}
                sx={{flex: 3}}
            />
            <Popover
                open={open}
                anchorEl={anchorEl}
                disablePortal
                onClose={handleDropdownClose}
                anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
                transformOrigin={{vertical: 'top', horizontal: 'left'}}
                slotProps={{
                    paper: {
                        sx: {
                            width: textFieldRef.current?.offsetWidth + 30 || '100%',
                            maxHeight: 400,
                            padding: 1,
                            '&::-webkit-scrollbar': {
                                width: '8px',
                            },
                            '&::-webkit-scrollbar-track': {
                                backgroundColor: '#f1f1f1',
                            },
                            '&::-webkit-scrollbar-thumb': {
                                backgroundColor: '#c1c1c1',
                                borderRadius: '4px',
                            },
                            '&::-webkit-scrollbar-thumb:hover': {
                                backgroundColor: '#a1a1a1',
                            },
                        },
                    }
                }}
            >
                {sortedTopCategories.map((category) => (
                    <RecursiveAccordion
                        key={category}
                        category={category}
                        templates={templates}
                        handleTemplateChange={(e) => {
                            const selectedTemplate = templates.find(
                                (t) => t.id === e.target.value
                            );
                            setSelectedFormName(selectedTemplate?.name || '');
                            handleTemplateChange(e);
                            handleDropdownClose();
                        }}
                    />
                ))}
            </Popover>
        </Box>
    );
};

TemplateDropdown.propTypes = {
    templates: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
            name: PropTypes.string.isRequired,
            category: PropTypes.string.isRequired,
            parentCategory: PropTypes.string,
        })
    ).isRequired,
    handleTemplateChange: PropTypes.func.isRequired,
};

const FormStructure = (props) => {
    const {
        name,
        schema,
        templateId,
        accessLevel,
        expirationDate,
        onExpirationDateChange,
        templates,
        formCategories,
        onNameChange,
        handleTemplateChange,
        editMode,
        setSchema,
        isFormArchived,
        formId,
        remindInterval,
        onRemindIntervalChange,
        categories,
        onCategoriesChange,
        componentToEditIndex,
        setComponentToEditIndex
    } = props;
    const nameField = useTextField({
        initialValue: name ?? '',
        validate: (value) => validateFieldLength(value, 128),
        onChange: onNameChange
    });

    return (
        <>
            <Stack
                flexDirection="row"
                flexWrap="wrap"
                gap={2}
                padding={2}
            >
                {
                    !editMode
                        ? <>
                            <TemplateDropdown
                                templates={templates || []}
                                templateId={templateId}
                                handleTemplateChange={handleTemplateChange}
                            />
                            <Divider orientation="vertical" variant="middle" flexItem/>
                        </>
                        : ''
                }
                <TextField
                    sx={{
                        flex: 3,
                        '&.MuiFormControl-root': {
                            margin: 0
                        }
                    }}
                    fullWidth
                    disabled={isFormArchived}
                    label="Form Name"
                    variant="outlined"
                    margin="normal"
                    required={nameField.required}
                    helperText={nameField.helperText ? nameField.helperText : ' '}
                    error={nameField.error}
                    value={nameField.value}
                    onChange={nameField.onChange}
                />
                <Divider orientation="vertical" variant="middle" flexItem/>
                <DatePicker
                    label="Expiration Date"
                    value={expirationDate ? moment(expirationDate) : null}
                    onChange={(newValue) => !expirationDate && onExpirationDateChange(newValue)}
                    onOpen={() => onExpirationDateChange(null)}
                    timezone="UTC"
                    disabled={isFormArchived}
                    format={'D-MMM-YYYY'}
                    minDate={moment(new Date())}
                    slots={{
                        openPickerIcon: expirationDate ? CancelIcon : CalendarIcon,
                    }}
                    slotProps={{
                        field: {
                            readOnly: true,
                            sx: {
                                flex: 3
                            },
                        },
                        textField: {
                            helperText: ' '
                        }
                    }}
                />
                <Divider orientation="vertical" variant="middle" flexItem/>
                <TextField
                    sx={{margin: 0, flex: 2}}
                    required
                    disabled={isFormArchived}
                    label="Remind interval"
                    variant="outlined"
                    value={remindInterval}
                    onChange={(e) => onRemindIntervalChange(e.target.value)}
                    margin="normal"
                    inputProps={{
                        inputMode: 'numeric',
                        pattern: '[0-9]*',
                    }}
                    helperText="Enter interval in hours (0 - Disabled)"
                />
                <Divider orientation="vertical" variant="middle" flexItem/>
                <TextField
                    select
                    value={categories}
                    label="Categories"
                    helperText=" "
                    defaultValue=""
                    SelectProps={{
                        multiple: true,
                        renderValue: (selected) => formCategories
                            .filter(x => selected.includes(x.id))
                            .map(x => x.name)
                            .join(', '),
                        MenuProps: {
                            sx: {
                                '& .MuiMenuItem-root': {
                                    whiteSpace: 'unset',
                                    wordBreak: 'break-all',
                                    '& .MuiBox-root': {
                                        whiteSpace: 'unset',
                                        wordBreak: 'break-all'
                                    }
                                },
                            },
                            slotProps: {
                                paper: {
                                    sx: {
                                        maxWidth: {
                                            md: '50vw',
                                            lg: '50vw'
                                        },
                                        maxHeight: '70vh'
                                    }
                                }
                            }
                        }
                    }}
                    sx={{
                        flex: 3
                    }}
                    onChange={onCategoriesChange}
                >
                    {
                        formCategories?.length > 0
                            ? formCategories.map(({id, name}) => (
                                <MenuItem
                                    key={id}
                                    value={id}
                                >
                                    <Checkbox checked={categories.includes(id)}/>
                                    <ListItemText primary={name}/>
                                </MenuItem>
                            ))
                            : <MenuItem value="" disabled>No options</MenuItem>
                    }
                </TextField>
            </Stack>
            <Divider/>
            {schema && <FormBuilder
                schema={schema}
                setSchema={setSchema}
                isFormArchived={isFormArchived}
                accessLevel={accessLevel}
                formId={formId}
                componentToEditIndex={componentToEditIndex}
                setComponentToEditIndex={setComponentToEditIndex}
            />}
        </>
    );
};

FormStructure.propTypes = {
    accessLevel: PropTypes.number,
    onNameChange: PropTypes.func,
    schema: PropTypes.array,
    formRef: PropTypes.object,
    name: PropTypes.string,
    templates: PropTypes.array,
    handleTemplateChange: PropTypes.func,
    setSchema: PropTypes.func,
    templateId: PropTypes.number,
    editMode: PropTypes.bool,
    isFormArchived: PropTypes.bool,
    expirationDate: PropTypes.string,
    remindInterval: PropTypes.number,
    onRemindIntervalChange: PropTypes.func,
    onExpirationDateChange: PropTypes.func,
    formId: PropTypes.number,
    formCategories: PropTypes.array,
    categories: PropTypes.array,
    onCategoriesChange: PropTypes.func,
    componentToEditIndex: PropTypes.number,
    setComponentToEditIndex: PropTypes.func
};

export default FormStructure;