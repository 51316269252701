import {
    useGetMedicalConsentOptionsQuery,
    useManageMedicalConsentsMutation
} from '../../../../api/services/lookupsService';
import {Box, Button} from '@mui/material';
import Typography from '@mui/material/Typography';
import React, {useEffect, useRef, useState} from 'react';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import IconButton from '@mui/material/IconButton';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import AdmicityDialog from '../../../../shared-components/AdmicityDialog';
import AdmicityForm from '../../../../shared-components/AdmicityForm';
import EditIcon from '@mui/icons-material/Edit';
import useNotification from '../../../../utility/hooks/useNotification';

const Lookups = () => {
    const {data: medicalConsentOptions = {options: []},} = useGetMedicalConsentOptionsQuery();
    const [openAddDialog, setOpenAddDialog] = useState(false);
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const formRef = useRef(null);
    const [selectedConsent, setSelectedConsent] = useState({})
    const [activeConsents, setActiveConsents] = useState([]);
    const {showErrorNotification, showSuccessNotification} = useNotification();

    const [manageConsents, {
        isSuccess,
        isError,
    }] = useManageMedicalConsentsMutation();

    useEffect(() => {
        setActiveConsents(medicalConsentOptions?.options);
    }, [medicalConsentOptions]);

    useEffect(() => {
        if (isError) {
            showErrorNotification('Failed to update medical consents')
        } else if (isSuccess) {
            showSuccessNotification('Medical consents updated successfully')
        }
    }, [isSuccess, isError]);

    const textFields = [{
        label: 'Description',
        name: 'description',
        required: true,
        initialValue: selectedConsent?.description ?? undefined,
        validate: (value) => {
            return {
                isValid: value && value.length <= 100,
                message: getValidationMessage(value)
            };
        }
    }];

    const getValidationMessage = (value) => {
        if (value.length > 0 && value.length <= 100) {
            return '';
        }

        return value.length === 0
            ? 'Please provide a description.'
            : 'Description cannot exceed 100 characters.';
    }

    const deleteConsent = (description) => {
        setActiveConsents(activeConsents.filter(e => e.description !== description))
    }

    const handleAdd = async (payload) => {
        setActiveConsents([...activeConsents, {description: payload.description, id: null}]);
        handleFinishEditing();
    };

    const startEditing = (consent) => {
        setSelectedConsent(consent);
        setOpenEditDialog(true);
    }

    const handleEdit = async (payload) => {
        const newConsents = activeConsents.map(consent => {
            if (consent.description === selectedConsent.description) {
                return {
                    ...consent,
                    description: payload.description
                };
            }
            return consent;
        });

        setActiveConsents(newConsents);
        handleFinishEditing();
    };

    const handleFinishEditing = () => {
        setOpenAddDialog(false)
        setOpenEditDialog(false)
        setSelectedConsent(undefined)
    }

    return (
        <Box
            display="flex"
            flexDirection="column"
            sx={{
                width: {
                    xs: '100%',
                    sm: '75%',
                    md: '50%',
                    lg: '50%',
                },
            }}
        >
            <Typography variant="h5" py={2}>Medical Consents</Typography>
            <Button
                variant="contained"
                sx={{mb: 2, mt: 2, width: 150}}
                startIcon={<AddCircleOutlineIcon/>}
                onClick={() => setOpenAddDialog(true)}
            >
                Add new
            </Button>
            <Box px={3}>
                {activeConsents.map(entity =>
                    <Box key={entity?.description} py={1} display={'flex'} justifyContent={'space-between'}
                         alignItems={'center'}>
                        <Typography fontWeight={'bold'} variant={'body1'}>{entity?.description}</Typography>
                        <Box display={'flex'}>
                            <IconButton onClick={() => startEditing(entity)}>
                                <EditIcon fontSize={'small'}/>
                            </IconButton>
                            <IconButton onClick={() => deleteConsent(entity.description)}>
                                <DeleteIcon fontSize={'small'}/>
                            </IconButton>
                        </Box>
                    </Box>)}
            </Box>
            <Box display={'flex'} justifyContent={'end'} py={3}>
                <Button variant="contained" sx={{mr: 3, width: 150}}
                        onClick={() => manageConsents({activeMedicalConsents: activeConsents})}>
                    Save
                </Button>
            </Box>

            <AdmicityDialog
                handleClose={handleFinishEditing}
                title={openAddDialog ? 'New Medical Consent' : 'Update Medical Consent'}
                open={openAddDialog || openEditDialog}
                actions={[]}
            >
                <AdmicityForm
                    ref={formRef}
                    textFields={textFields}
                    handleSubmit={(data) => openAddDialog
                        ? handleAdd(data)
                        : handleEdit(data)}
                    buttonText="Save"
                />
            </AdmicityDialog>
        </Box>);
};

export default Lookups;