import ConfirmationWrapper from '../../Components/ConfirmationWrapper';
import {STUDENT_PROFILE_CONFIRMATION_TYPES} from '../../const/StudentProfileConfirmationTypes';
import {useGetTravelOptionsQuery} from '../../../../../api/services/lookupsService';
import {useGetTravelModeQuery, useUpdateTravelModeMutation} from '../../../../../api/services/studentService';
import {useParams} from 'react-router-dom';
import React, {useEffect, useState} from 'react';
import {MenuItem, TextField} from '@mui/material';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

const TravelMode = (
    {
        readonly,
        profile,
        school,
        onConfirmSuccess
    }) => {
    const {studentId} = useParams();
    const {
        data: travelOptions = [],
        isLoading: isTravelOptionsLoading
    } = useGetTravelOptionsQuery(undefined);
    const {
        data: travelModeData = {},
        isLoading: isTravelsLoading,
        isFetching: isTravelsFetching,
        refetch: refetchTravelModeData
    } = useGetTravelModeQuery(studentId);
    const [updateTravelMode] = useUpdateTravelModeMutation();
    const [travelModeId, setTravelModeId] = useState(0);
    const isLoading = isTravelsLoading ||
        isTravelOptionsLoading ||
        isTravelsFetching;

    useEffect(() => {
        if (travelModeData?.modeId) {
            setTravelModeId(travelModeData.modeId);
        }
    }, [travelModeData]);

    const handleSave = async () =>
        await updateTravelMode({
            studentId,
            body: {
                modeId: travelModeId === 0 ? -1 : travelModeId,
                studentId
            }
        });

    return (
        isLoading
            ? ''
            : <ConfirmationWrapper
                readonly={readonly}
                confirmData={{
                    confirmMessage: `I confirm that the information in the Mode of Travel section of ${profile.name} ${profile.surname} student profile is correct and understand it will be relied upon by school staff and any other person or organisation who may have ${profile.name} ${profile.surname} in their care on behalf of ${school.name}`,
                    confirmedAt: travelModeData.confirmedAt,
                    confirmedBy: travelModeData.confirmedBy,
                    enableSaveButton: travelModeId > 0 && travelModeId !== travelModeData?.modeId,
                    enableConfirmButton: travelModeId > 0,
                    studentId,
                    type: STUDENT_PROFILE_CONFIRMATION_TYPES.TravelMode,
                    handleSave,
                    onConfirmSuccess: async () => {
                        await onConfirmSuccess();
                        await refetchTravelModeData();
                    },
                    confirmButtonText: 'Confirm Travel Mode'
                }}
            >
                <Typography variant={'h6'} sx={{py: 1}}>HOME-TO-SCHOOL TRANSPORT</Typography>
                <Typography>Mode of travel</Typography>
                <TextField
                    disabled={readonly}
                    select
                    value={travelModeId}
                    onChange={(e) => setTravelModeId(e.target.value)}
                >
                    <MenuItem value={0}>Not selected</MenuItem>
                    {travelOptions.map(({description, id}) => (
                        <MenuItem key={id} value={id}>
                            {description}
                        </MenuItem>
                    ))}
                </TextField>
            </ConfirmationWrapper>
    );
};

TravelMode.propTypes = {
    readonly: PropTypes.bool,
    profile: PropTypes.object.isRequired,
    school: PropTypes.object.isRequired,
    onDataLoading: PropTypes.func,
    onConfirmSuccess: PropTypes.func,
};

export default TravelMode;