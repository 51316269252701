import {Badge, Box, Button, Stack} from '@mui/material';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import Typography from '@mui/material/Typography';
import React, {useCallback} from 'react';
import {useDropzone} from 'react-dropzone';
import IconButton from '@mui/material/IconButton';
import CancelIcon from '@mui/icons-material/Cancel';
import PropTypes from 'prop-types';
import useNotification from '../../../utility/hooks/useNotification';

const LogoUploader = ({onChange, value}) => {
    const {showErrorNotification} = useNotification();

    const onDrop = useCallback((acceptedFiles) => {
        onChange(acceptedFiles[0]);
    }, []);

    const onDropRejected = useCallback((rejections) => {
        if (rejections.some(e => e.errors.some(error => error.code === 'file-too-large'))) {
            showErrorNotification('The size of the logo exceeds the limit of 100KB.');
        }
    }, []);

    const {getInputProps, open} = useDropzone({
        onDrop,
        onDropRejected,
        noClick: true,
        noKeyboard: true,
        accept: {
            'image/*': ['.jpeg', '.jpg', '.png'],
        },
        multiple: false,
        maxSize: 100 * 1024
    });

    const handleLogoRemove = () => onChange(null);

    return (
        <Box marginTop={2}>
            {
                value
                    ? <Badge badgeContent={
                        <IconButton onClick={handleLogoRemove} size="small">
                            <CancelIcon fontSize="small"/>
                        </IconButton>
                    }>
                        <img
                            src={URL.createObjectURL(value)}
                            alt="Logo preview"
                            width={150}
                            style={{objectFit: 'cover'}}
                        />
                    </Badge>
                    : <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: 100,
                            height: 100,
                            border: '1.5px dashed',
                            borderColor: 'grey.A400',
                            borderRadius: 2,
                            backgroundColor: 'grey.A100',
                            cursor: 'pointer'
                        }}
                        onClick={open}
                    >
                        <InsertPhotoIcon color={'disabled'}/>
                    </Box>
            }
            <input {...getInputProps()} />
            <Stack
                sx={{
                    '& .MuiTypography-root': {
                        margin: 0
                    }
                }}
                alignItems="center"
                flexDirection="row"
                marginTop={1}
            >
                <Box>
                    <Button onClick={open}>Upload Logo</Button>
                </Box>
                <Typography color="text.secondary"> — Max file size 100KB</Typography>
            </Stack>
        </Box>
    );
};

LogoUploader.propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.object
};

export default LogoUploader;