import {Box, Button, FormHelperText, MenuItem, Stack, TextField} from '@mui/material';
import {DatePicker} from '@mui/x-date-pickers';
import moment from 'moment';
import {useEffect, useState} from 'react';

/* eslint-disable react/prop-types */
const MedicalEventPopup = ({selectedMedicalEvent, medicalEventLookups, onSubmit, onClose}) => {
    const [formData, setFormData] = useState({
        eventTypeId: selectedMedicalEvent?.eventTypeId || '',
        eventId: selectedMedicalEvent?.eventId || '',
        eventDate: selectedMedicalEvent?.eventDate ? moment(selectedMedicalEvent.eventDate) : null,
        followUpDate: selectedMedicalEvent?.followUpDate ? moment(selectedMedicalEvent.followUpDate) : null,
    });
    const [errors, setErrors] = useState({});

    useEffect(() => {
        setFormData({
            eventTypeId: selectedMedicalEvent?.eventTypeId || '',
            eventId: selectedMedicalEvent?.eventId || '',
            eventDate: selectedMedicalEvent?.eventDate ? moment(selectedMedicalEvent.eventDate) : null,
            followUpDate: selectedMedicalEvent?.followUpDate ? moment(selectedMedicalEvent.followUpDate) : null,
        });
        setErrors({});
    }, [selectedMedicalEvent]);

    useEffect(() => {
        validateForm();
    }, [formData]);

    const validateForm = () => {
        const newErrors = {};

        if (formData.eventDate && formData.followUpDate && formData.eventDate >= formData.followUpDate) {
            newErrors.followUpDate = 'Follow Up Date should be greater than Event Date';
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = () => {
        if (validateForm()) {
            onSubmit(formData);
        }
    };

    const handleDateChange = (name, value) => {
        setFormData((prev) => ({...prev, [name]: value}));
    };

    const isFormValid = Object.keys(errors).length === 0 && formData.eventTypeId && formData.eventId;

    return (
        <Box
            sx={{
                padding: 1,
                paddingTop: 0,
                backgroundColor: 'white',
                margin: 'auto',
                marginTop: 4,
            }}
        >
            <Stack spacing={2}>
                <TextField
                    select
                    label="Event Type"
                    name="eventTypeId"
                    value={formData.eventTypeId}
                    onChange={(e) => {
                        const eventTypeLookup = medicalEventLookups.eventTypes.find(lookup => lookup.id === e.target.value);

                        setFormData((prev) => ({
                            ...prev,
                            eventTypeId: e.target.value,
                            eventId: eventTypeLookup?.medicalEvents.length === 1
                                ? eventTypeLookup?.medicalEvents[0]?.id
                                : null
                        }));
                    }}
                    fullWidth
                    error={Boolean(errors.eventTypeId)}
                    helperText={errors.eventTypeId}
                    required
                >
                    {medicalEventLookups.eventTypes.map((type) => (
                        <MenuItem key={type.id} value={type.id}>
                            {type.description}
                        </MenuItem>
                    ))}
                </TextField>
                <TextField
                    select
                    label="Description"
                    name="eventId"
                    value={formData.eventId}
                    onChange={(e) => {
                        const eventLookup = medicalEventLookups.medicalEvents.find(lookup => lookup.id === e.target.value);
                        setFormData((prev) => ({
                            ...prev,
                            eventId: e.target.value,
                            eventTypeId: eventLookup?.relatedSimsId
                        }));
                    }}
                    fullWidth
                    error={Boolean(errors.eventId)}
                    helperText={errors.eventId}
                    required
                >
                    {formData.eventTypeId
                        ? medicalEventLookups.eventTypes.find(type => type.id === formData.eventTypeId)?.medicalEvents.map(event => (
                            <MenuItem key={event.id} value={event.id}>
                                {event.description}
                            </MenuItem>
                        ))
                        : medicalEventLookups.medicalEvents.map(event => (
                            <MenuItem key={event.id} value={event.id}>
                                {event.description}
                            </MenuItem>
                        ))}
                </TextField>
                <DatePicker
                    label="Event Date"
                    value={formData.eventDate}
                    onChange={(date) => handleDateChange('eventDate', date)}
                    renderInput={(params) => (
                        <TextField {...params} fullWidth error={Boolean(errors.eventDate)}/>
                    )}
                />
                {
                    errors.eventDate && <FormHelperText error>{errors.eventDate}</FormHelperText>
                }
                <DatePicker
                    label="Follow Up Date"
                    value={formData.followUpDate}
                    onChange={(date) => handleDateChange('followUpDate', date)}
                    renderInput={(params) => (
                        <TextField {...params} fullWidth error={Boolean(errors.followUpDate)}/>
                    )}
                />
                {
                    errors.followUpDate && <FormHelperText error>{errors.followUpDate}</FormHelperText>
                }
            </Stack>
            <Stack direction="row" justifyContent="flex-end" spacing={2} marginTop={2}>
                <Button onClick={onClose} variant="outlined">
                    Cancel
                </Button>
                <Button onClick={handleSubmit} variant="contained" color="primary" disabled={!isFormValid}>
                    Save
                </Button>
            </Stack>
        </Box>
    );
};

export default MedicalEventPopup;