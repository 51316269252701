import {Alert, TextField} from '@mui/material';
import AdmicityGroupFilterSelect from '../../../../shared-components/AdmicityGroupFilterSelect';
import AdmicityDialog from '../../../../shared-components/AdmicityDialog';
import React, {useEffect, useState} from 'react';
import useTextField from '../../../../utility/hooks/useTextField';
import {validateFieldLength} from '../../../../utility/validationUtil';
import PropTypes from 'prop-types';

const CreateGroupFromResponsesDialog = (
    {
        open,
        title,
        onClose,
        onSave,
        groupFilters
    }) => {
    const nameField = useTextField({
        initialValue: '',
        validate: (value) => validateFieldLength(value, 256)
    });
    const [parentGroupFilterId, setParentGroupFilterId] = useState('');

    useEffect(() => {
        return () => {
            nameField.reset();
            setParentGroupFilterId('');
        };
    }, [open]);

    return (
        <AdmicityDialog
            handleClose={onClose}
            title={title}
            open={open}
            actions={[
                {
                    label: 'Cancel',
                    onClick: onClose
                },
                {
                    label: 'Save',
                    disabled: (nameField.error || !nameField.value),
                    onClick: () => onSave({
                        parentGroupFilterId,
                        groupName: nameField.value
                    })
                }
            ]}
        >
            <Alert
                severity="info"
            >
                Please note that students will be assigned to the new group based on the filters applied to the form
                responses
            </Alert>
            <TextField
                required
                fullWidth
                label="Group Name"
                variant="outlined"
                margin="normal"
                {...nameField}
            />
            <AdmicityGroupFilterSelect
                sx={{
                    marginTop: 1,
                    width: '100%'
                }}
                data={groupFilters}
                value={parentGroupFilterId}
                onChange={setParentGroupFilterId}
                isRequired={false}
            />
        </AdmicityDialog>
    );
};

CreateGroupFromResponsesDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    groupFilters: PropTypes.array.isRequired
};

export default CreateGroupFromResponsesDialog;