import React, {useState} from 'react';
import {Box, Button, Tab, Tabs, Tooltip} from '@mui/material';
import AdmicityDialog from '../../../../shared-components/AdmicityDialog';
import useFileDownloader from '../../../../utility/hooks/useFileDownloader';
import {downloadStudentsCsvReport, downloadStudentsXlsxReport} from '../../../../api/services/filesService';
import {useGetStudentFiltersQuery} from '../../../../api/services/studentService';
import FiltersTab from './FiltersTab';
import FieldsTab from './FieldsTab';
import {FIELDS_MAPPING} from '../const/ReportFieldsMapping';

/* eslint-disable react/prop-types */
const DownloadReportPopup = ({open, onClose, isCsv, groupFilters}) => {
    const [excludedFields, setExcludedFields] = useState(FIELDS_MAPPING.filter(e => !e.readonly).map((e) => e.value));
    const [activeTab, setActiveTab] = useState(0);
    const [strictRecipientsFiltering, setStrictRecipientsFiltering] = useState(false);
    const [settings, setSettings] = useState({
        groupFilters: [],
        studentFilters: [],
        applicationFilters: []
    });
    const downloadReport = useFileDownloader();
    const {data: studentsData} = useGetStudentFiltersQuery({});

    const handleToggleField = (field) => {
        if (excludedFields.includes(field)) {
            setExcludedFields((prev) => prev.filter((f) => f !== field));
        } else {
            setExcludedFields((prev) => [...prev, field]);
        }
    };

    const handleGenerate = async () => {
        await downloadReport(
            isCsv
                ? downloadStudentsCsvReport
                : downloadStudentsXlsxReport,
            {
                excludedFields,
                filterIds: settings.groupFilters.filter(e => !e.excluded).map(e => e.id),
                excludedFilterIds: settings.groupFilters.filter(e => e.excluded).map(e => e.id),
                strictRecipientsFiltering,
                studentIds: [...settings?.studentFilters?.map(e => e.id), ...settings?.applicationFilters?.map(e => e.id)]
            }
        );
        handleClose();
    };

    const handleClose = () => {
        setExcludedFields(FIELDS_MAPPING.filter(e => !e.readonly).map((e) => e.value));
        setSettings({
            groupFilters: [],
            studentFilters: [],
            applicationFilters: []
        });
        onClose();
    };

    const tabs = [
        {
            label: 'Filters',
            component: (
                <FiltersTab
                    data={groupFilters}
                    settings={settings}
                    studentsData={studentsData}
                    strictRecipientsFiltering={strictRecipientsFiltering}
                    setStrictRecipientsFiltering={setStrictRecipientsFiltering}
                    setSettings={setSettings}
                />
            ),
        },
        {
            label: 'Fields',
            component: (
                <FieldsTab
                    excludedFields={excludedFields}
                    handleToggleField={handleToggleField}
                    setExcludedFields={setExcludedFields}
                />
            ),
        },
    ];

    return (
        <AdmicityDialog
            open={open}
            handleClose={handleClose}
            title={'Field Visibility'}
            maxWidth={'md'}

            actions={[
                {
                    label: 'Cancel', onClick: handleClose,
                },
                {
                    label: 'Generate',
                    onClick: handleGenerate,
                    disabled: !settings.applicationFilters.length && !settings.studentFilters.length && !settings.groupFilters.filter(e => !e.excluded).length
                }
            ]}
        >
            <Box sx={{minHeight: '420px'}}>
                <Tabs value={activeTab} onChange={(event, newValue) => setActiveTab(newValue)}>
                    {tabs.map(({label}, index) => (<Tab key={index} label={label}/>))}
                </Tabs>
                <Box>{tabs[activeTab].component}</Box>
                <Box>
                    {tabs[activeTab].actions && tabs[activeTab].actions.map(({label, ...actionProps}) => (
                        <Tooltip key={`${label}-tooltip`} title={actionProps.tooltip || ''}>
                            <Button variant="contained" {...actionProps}>
                                {label}
                            </Button>
                        </Tooltip>
                    ))}
                </Box>
            </Box>
        </AdmicityDialog>
    );
};

export default DownloadReportPopup;