import {useDispatch, useSelector} from 'react-redux';
import {
    loadTablePreferences,
    persistTablePreferences, resetTableState,
    setColumnVisibility,
    setFilters,
    setPagination,
    setSorting,
    initialize
} from '../../store/tableSlice';
import {useEffect} from 'react';

const useTableState = key => {
    const isDefaultKey = key === 'default';
    const dispatch = useDispatch();
    const {
        initialized,
        pagination,
        filters,
        quickFilters,
        columnVisibility,
        sorting
    } = useSelector(state => state.table.tables[key]) ||
    {
        initialized: false,
        pagination: {
            pageIndex: 0,
            pageSize: 20
        },
        filters: {},
        quickFilters: {},
        columnVisibility: {},
        sorting: []
    };

    useEffect(() => {
        const handleBeforeUnload = () => {
            if (!isDefaultKey) {
                dispatch(persistTablePreferences(key));
            } else {
                dispatch(resetTableState(key));
            }
        };

        if (!isDefaultKey) {
            dispatch(loadTablePreferences(key));
        }

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
            handleBeforeUnload();
        };
    }, [dispatch]);

    const initializeState = state => dispatch(initialize({...state, key}));

    const onPaginationChange = ({pageIndex, pageSize}) => {
        return dispatch(setPagination({
            key,
            pagination: {pageIndex, pageSize}
        }));
    };

    const onFiltersChange = ({filters, quickFilters}) => dispatch(setFilters({key, filters, quickFilters}));

    const onSortingChange = sorting => dispatch(setSorting({key, sorting}));

    const onColumnVisibilityChange = columnVisibility => dispatch(setColumnVisibility({key, columnVisibility}));

    return {
        initialized,
        pagination,
        filters,
        quickFilters,
        columnVisibility,
        sorting,
        initialize: initializeState,
        onPaginationChange,
        onFiltersChange,
        onSortingChange,
        onColumnVisibilityChange,
    };
};

export default useTableState;
