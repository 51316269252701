import React, {useEffect, useMemo, useState} from 'react';
import {TreeItem, TreeView, useTreeItem} from '@mui/x-tree-view';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {Menu, MenuItem, Tooltip, Typography } from '@mui/material';

const mapDataToTree = items => {
    const root = [];
    const stack = [{children: root, level: 0, path: []}];
    const paths = {};
    const moduleItems = items.flatMap(({filters, ...module}) => {
        const _filters = filters.map(filter => ({...filter, parentId: module.id}));
        return [{...module, parentId: null}, ..._filters];
    });

    for (const item of moduleItems) {
        const node = {...item, children: [], path: []};

        while (stack[stack.length - 1].level >= item.level) {
            stack.pop();
        }

        const parent = stack[stack.length - 1];
        node.path = [...parent.path, {name: item.name, selectable: item.selectable, id: item.id}];
        paths[item.id] = node.path;

        parent.children.push(node);
        stack.push(node);
    }
    return {
        tree: root,
        paths
    };
};

/* eslint-disable */
const CustomContent = React.forwardRef(function CustomContent(props, ref) {
    const {
        classes,
        className,
        label,
        level,
        nodeId,
        icon: iconProp,
        expansionIcon,
        displayIcon,
        isSelectable = true,
        actions = [],
        customGroupProps,
        formCategory,
    } = props;

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => setAnchorEl(event.currentTarget);

    const handleClose = () => setAnchorEl(null);

    const {
        disabled,
        expanded,
        selected,
        focused,
        handleExpansion,
        handleSelection,
        preventSelection,
    } = useTreeItem(nodeId);

    const icon = iconProp || expansionIcon || displayIcon;

    const handleMouseDown = (event) => {
        preventSelection(event);
    };

    const handleExpansionClick = (event) => {
        handleExpansion(event);
    };

    const handleSelectionClick = (event) => {
        if (isSelectable) {
            handleSelection(event);
        }
    };

    return (
        <div
            className={clsx(className, classes.root, {
                [classes.expanded]: expanded,
                [classes.selected]: selected && isSelectable,
                [classes.focused]: focused,
                [classes.disabled]: disabled,
            })}
            onMouseDown={handleMouseDown}
            ref={ref}
        >
   <div onClick={handleExpansionClick} className={classes.iconContainer}>
                {icon}
            </div>
            <Typography
                onClick={handleSelectionClick}
                className={level === 1 ? {} : classes.label}
                sx={{
                    paddingTop: 1,
                    paddingBottom: 1,
                    fontWeight: 'bold',
                    wordBreak: 'break-word',
                    overflowWrap: 'break-word',
                    display: 'flex',
                    alignItems: 'center'
                }}
            >
                {formCategory && (
                     <Tooltip title={formCategory.name || 'No category'} arrow>
                    <span
                        style={{
                            display: 'inline-block',
                            width: 12,
                            height: 12,
                            borderRadius: '50%',
                            backgroundColor: formCategory.color,
                            marginRight: 8,
                        }}
                    />
                    </Tooltip>
                )}
                {label}
            </Typography>
            {actions.length > 0 && (
                <>
                    <IconButton
                        aria-label="more"
                        id="tree-item-button"
                        aria-controls={open ? 'tree-item-menu' : undefined}
                        aria-expanded={open ? 'true' : undefined}
                        aria-haspopup="true"
                        size="small"
                        onClick={handleClick}
                    >
                        <MoreVertIcon fontSize="small" />
                    </IconButton>
                    <Menu
                        id="tree-item-menu"
                        MenuListProps={{ 'aria-labelledby': 'tree-item-button' }}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                    >
                        {actions.map(({ onClick, label, ...rest }) => (
                            <MenuItem
                                onClick={() => {
                                    onClick({
                                        nodeId,
                                        label: props.label,
                                        ...customGroupProps,
                                    });
                                    handleClose();
                                }}
                                {...rest}
                            >
                                {label}
                            </MenuItem>
                        ))}
                    </Menu>
                </>
            )}
        </div>
    );
});

const CustomTreeItem = React.forwardRef(function CustomTreeItem(props, ref) {
    const {
        isSelectable,
        actions,
        studentIds,
        basedOnGroupFilterId,
        groupFilters,
        strictRecipientsFiltering,
        isCustomGroup,
        level,
        formCategoryId,
        formCategory,
        ...otherProps
    } = props;

    return <TreeItem
        ContentComponent={(props) => <CustomContent
            {...props}
            actions={actions}
            isSelectable={isSelectable}
            level={level}
            customGroupProps={
                isCustomGroup
                    ? {
                        basedOnGroupFilterId,
                        groupFilters,
                        strictRecipientsFiltering,
                        studentIds,
                        formCategoryId
                    }
                    : {}
            }
            formCategory={formCategory}
            basedOnGroupFilterId={basedOnGroupFilterId}
        />}
        {...otherProps}
        sx={{fontWeight: level === 1 ? 'bold' : 'initial'}}
        ref={ref}/>;
});

const orderGroupFilters = filters => {
    if (!filters) return [];

    return filters.map(filter => {
        if (filter.name === 'PRE-ADMISSION') {
            return {
                ...filter,
                filters: filter.filters.map(subFilter =>
                    subFilter.name === 'Intake Group' ? {...subFilter, order: 1} : subFilter
                )
            };
        }
        return filter;
    });
};

const AdmicityGroupFilterTree = (
    {
        data,
        selected,
        formCategoryMap,
        sx,
        onChange,
        expandMinLevel = 0,
        itemActions = []
    }) => {
    const {tree, paths} = useMemo(() => data
        ? mapDataToTree(orderGroupFilters(data))
        : {tree: undefined, paths: undefined}, [data]);
    const [expanded, setExpanded] = useState([]);

    useEffect(() => {
        const expandedNodes = expandMinLevel && data
            ? (
                expandMinLevel === 1
                    ? data.map(f => f.id)
                    : data.reduce((ids, module) => {
                        ids = [module.id, ...ids, ...module.filters.filter(f => f.level <= expandMinLevel).map(f => f.id)];
                        return ids;
                    }, [])
            )
            : [];

        if (paths && selected) {
            setExpanded([...new Set([...expandedNodes, ...(paths[selected]?.map(x => x.id) ?? [])])]);
        } else {
            setExpanded(expandedNodes);
        }
    }, [data, paths]);

    const renderTreeNode = (node) =>
        node
            ? <CustomTreeItem
                key={node.id}
                nodeId={node.id}
                label={node.name}
                level={node.level}
                onClick={(event) => event.stopPropagation()}
                isSelectable={node.selectable}
                studentIds={node.isCustomGroup ? node.studentIds : []}
                actions={node.selectable && node.isCustomGroup ? itemActions : []}
                isCustomGroup={node.isCustomGroup}
                basedOnGroupFilterId={node.basedOnGroupFilterId}
                groupFilters={node.groupFilters}
                strictRecipientsFiltering={node.strictRecipientsFiltering}
                formCategoryId={node.formCategoryId}
                formCategory={node.formCategoryId ? formCategoryMap?.[node.formCategoryId] : null}
            >
                {Array.isArray(node.children)
                    ? node.children.sort((a, b) => {
                        if (a.order != null && b.order != null) {
                            return a.order - b.order;
                        } else if (a.order != null) {
                            return -1;
                        } else if (b.order != null) {
                            return 1;
                        } else if (a.name.startsWith('Year ') && b.name.startsWith('Year ')) {
                            const numA = parseInt(a.name.match(/\d+/)[0]);
                            const numB = parseInt(b.name.match(/\d+/)[0]);
                            return numA - numB;
                        } else {
                            return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);
                        }
                    }).map((node) => renderTreeNode(node))
                    : null}
            </CustomTreeItem>
            : '';

    const handleToggle = (event, nodeIds) => {
        setExpanded(nodeIds);
    };

    const handleSelect = (event, nodeId) => {
        onChange(nodeId, paths[nodeId]);
    };

    return (
        <>
            <TreeView
                key={selected}
                sx={{...sx}}
                onNodeToggle={handleToggle}
                onNodeSelect={handleSelect}
                defaultCollapseIcon={<ExpandMoreIcon/>}
                defaultExpandIcon={<ChevronRightIcon/>}
                expanded={expanded}
                selected={selected}
            >
                {tree?.map(node => renderTreeNode(node))}
            </TreeView>
        </>
    );
};

AdmicityGroupFilterTree.defaultProps = {
    sx: {}
};

AdmicityGroupFilterTree.propTypes = {
    data: PropTypes.array,
    selected: PropTypes.string,
    sx: PropTypes.object,
    onChange: PropTypes.func,
    expandMinLevel: PropTypes.number,
    itemActions: PropTypes.array,
    formCategoryMap: PropTypes.object
};

export default AdmicityGroupFilterTree;