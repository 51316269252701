import React, {useState} from 'react';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';
import {Autocomplete} from '@mui/material';
import PropTypes from 'prop-types';
import {validateEmail} from '../utility/validationUtil';

const EMAILS_LIMIT = 5;

const EmailChipsInput = (
    {
        values,
        onChange,
        onBlur
    }) => {
    const [inputValue, setInputValue] = useState('');

    const handleChange = (e, newValues) => {
        if (newValues.length > EMAILS_LIMIT) {
            return;
        }

        const validEmails = newValues
            .map(x => {
                if (typeof x === 'object') {
                    return x;
                }

                return validateEmail(x).isValid ? {email: x} : null;
            })
            .filter(x => x != null);

        onChange(validEmails);
    };

    const handleBlur = (e) => {
        if (values.length >= EMAILS_LIMIT) {
            setInputValue('');

            return;
        }

        const email = e.target.value;

        if (!validateEmail(email).isValid) {
            return;
        }

        onBlur(email);
        setInputValue('');
    };

    return (
        <Autocomplete
            multiple
            freeSolo
            options={[]}
            value={values}
            limitTags={5}
            onChange={handleChange}
            onBlur={handleBlur}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
            renderTags={(value, getTagProps) =>
                value.map((value, index) => (
                    <Chip
                        key={index}
                        label={value.email}
                        {...getTagProps({index})}
                    />
                ))
            }
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="outlined"
                    label="Enter emails"
                    type="email"
                    helperText={`Type an email and press Enter (Max ${EMAILS_LIMIT} emails allowed)`}
                />
            )}
        />
    );
};

EmailChipsInput.propTypes = {
    values: PropTypes.array,
    onChange: PropTypes.func,
    onBlur: PropTypes.func
};

export default EmailChipsInput;
