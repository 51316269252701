import React, {useEffect} from 'react';
import {Button, Container, Typography} from '@mui/material';
import useFileDownloader from '../../utility/hooks/useFileDownloader';
import {useParams} from 'react-router-dom';
import {downloadPdfReport} from '../../api/services/filesService';

export default function DownloadPage() {
    const {id} = useParams();
    const downloadFile = useFileDownloader();

    const downloadReport = async () => {
        await downloadFile(
            downloadPdfReport,
            {
                id
            });
    }

    useEffect(() => {
        if (id) {
            downloadReport();
        }
    }, []);

    return (
        <Container
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100vh',
            }}
        >
            <Typography variant="h5" mt={2}>
                A download should start automatically in a few seconds...
            </Typography>
            <Button onClick={downloadReport} mt={1}>If not, click here to download</Button>
        </Container>
    );
}
