import {apiService} from '../apiService';

export const formsCategoriesService = apiService.injectEndpoints({
    endpoints: (builder) => {
        return {
            getFormCategories: builder.query({
                query: (body) => ({
                    url: '/forms/categories',
                    method: 'GET',
                    data: body
                }),
                transformErrorResponse: (_) => ({message: 'Failed to retrieve form categories'}),
                providesTags: (_) => [{type: 'FormCategories', id: 'LIST'}]
            }),
            addFormCategory: builder.mutation({
                query: body => ({
                    url: '/forms/categories',
                    method: 'POST',
                    data: body
                }),
                invalidatesTags: [{type: 'FormCategories', id: 'LIST'}],
            }),
            updateFormCategory: builder.mutation({
                query: body => ({
                    url: '/forms/categories',
                    method: 'PUT',
                    data: body
                }),
                invalidatesTags: [{type: 'FormCategories', id: 'LIST'}],
            }),
            deleteFormCategory: builder.mutation({
                query: body => ({
                    url: '/forms/categories',
                    method: 'DELETE',
                    data: body
                }),
                invalidatesTags: [{type: 'FormCategories', id: 'LIST'}],
            }),
        }
    },
});

export const {
    useGetFormCategoriesQuery,
    useAddFormCategoryMutation,
    useUpdateFormCategoryMutation,
    useDeleteFormCategoryMutation
} = formsCategoriesService;
