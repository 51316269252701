const FORM_ACCESS_LEVELS = {
    public: 1,
    private: 2,
    publicWithStudentLink: 3
};

const FORM_ACCESS_LEVELS_NAMES = {
    [FORM_ACCESS_LEVELS.public]: 'Public',
    [FORM_ACCESS_LEVELS.publicWithStudentLink]: 'Public (Linked to Students)',
    [FORM_ACCESS_LEVELS.private]: 'Private'
};

export {
    FORM_ACCESS_LEVELS,
    FORM_ACCESS_LEVELS_NAMES
};