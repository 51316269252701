import {Box, Checkbox, styled} from '@mui/material';
import Typography from '@mui/material/Typography';
import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import ConfirmationWrapper from '../../Components/ConfirmationWrapper';
import {STUDENT_PROFILE_CONFIRMATION_TYPES} from '../../const/StudentProfileConfirmationTypes';
import {useGetDietaryNeedsQuery, useManageDietaryNeedsMutation} from '../../../../../api/services/studentService';
import {useGetDietaryNeedOptionsQuery} from '../../../../../api/services/lookupsService';

export const DietaryNeedOrFoodAllergyItem = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
});

const DietaryNeeds = ({readonly, onConfirmSuccess}) => {
    const {studentId} = useParams();
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [deselectedOptions, setDeselectedOptions] = useState([]);
    const [isNotApplicable, setIsNotApplicable] = useState(undefined);
    const selectedProfile = useSelector(state => state.profilesInfo.selectedProfile);
    const currentSchool = useSelector(state => state.profilesInfo.currentSchool);
    const [manageDietaryNeeds] = useManageDietaryNeedsMutation();
    const {
        data: dietaryNeeds = [],
        refetch: refetchDietaryNeedsData
    } = useGetDietaryNeedsQuery(studentId);
    const {
        data: optionData = [],
        refetch: refetchDietaryNeedOptions
    } = useGetDietaryNeedOptionsQuery();

    useEffect(() => {
        if (dietaryNeeds?.options) {
            setSelectedOptions(dietaryNeeds?.options.map(x => x.optionId));
            setIsNotApplicable(dietaryNeeds.isNotApplicable);
        }
    }, [dietaryNeeds]);

    const handleSelect = (event) => {
        const {checked, value} = event.target;
        const optionId = parseInt(value);

        if (checked) {
            setSelectedOptions([...selectedOptions, optionId]);
            setDeselectedOptions(deselectedOptions.filter(id => id !== optionId));
        } else {
            setDeselectedOptions([...deselectedOptions, optionId]);
            setSelectedOptions(selectedOptions.filter(id => id !== optionId));
        }
    };

    const handleSave = async () => {
        const body = {
            selectedOptions: selectedOptions.filter(x => !dietaryNeeds?.options?.some(v => v.optionId === x)),
            deselectedOptions: deselectedOptions.filter(x => dietaryNeeds?.options?.some(v => v.optionId === x)),
            isNotApplicable,
            studentId
        };

        await manageDietaryNeeds({studentId, body});
    };

    const isEdited = () => {
        return selectedOptions.filter(x => !dietaryNeeds?.options?.some(v => v.optionId === x)).length !== 0 ||
            deselectedOptions.filter(x => dietaryNeeds?.options?.some(v => v.optionId === x)).length !== 0 || isNotApplicable !== dietaryNeeds.isNotApplicable;
    };

    return (
        dietaryNeeds && <ConfirmationWrapper
                readonly={readonly}
                confirmData={{
                    confirmMessage: `I confirm that the information in the dietary needs section of ${selectedProfile.name} ${selectedProfile.surname} student profile is correct and understand it will be relied upon by school staff and any other person or organisation who may have ${selectedProfile.name} ${selectedProfile.surname} in their care on behalf of ${currentSchool.name}`,
                    confirmedAt: dietaryNeeds.confirmedAt,
                    confirmedBy: dietaryNeeds.confirmedBy,
                    enableSaveButton: isEdited() && (selectedOptions.length !== 0 || isNotApplicable),
                    enableConfirmButton: selectedOptions.length !== 0 || isNotApplicable,
                    studentId,
                    type: STUDENT_PROFILE_CONFIRMATION_TYPES.Dietary,
                    handleSave,
                    onConfirmSuccess: async () => {
                        await onConfirmSuccess();
                        await refetchDietaryNeedsData();
                        await refetchDietaryNeedOptions();
                    },
                    confirmButtonText: 'Confirm Dietary Needs'
                }}>
                <Typography variant="h6" gutterBottom>
                    DIETARY NEEDS AND FOOD ALLERGIES
                </Typography>
                <DietaryNeedOrFoodAllergyItem>
                    <Typography>I confirm that {selectedProfile.name} does not have any dietary preferences, food
                        intolerances or food allergies.</Typography>
                    <Checkbox
                        sx={{width: '25px', height: '25px'}}
                        value={isNotApplicable}
                        checked={!!isNotApplicable}
                        disabled={readonly || (selectedOptions.length > 0 && !isNotApplicable)}
                        onChange={(e) => setIsNotApplicable(e.target.checked)}
                    />
                </DietaryNeedOrFoodAllergyItem>
                {
                    !isNotApplicable &&
                    <Typography variant="body1" fontWeight={'bold'}>
                        If {selectedProfile.name} has a dietary preference, food intolerance or food allergy, please tick all boxes that
                        apply
                    </Typography>
                }
                {!isNotApplicable &&
                    optionData.options?.map((field, index) => (
                        <DietaryNeedOrFoodAllergyItem key={index}>
                            <Box>
                                {
                                    field.title
                                        ? <Typography>{field.title}</Typography>
                                        : <Typography>{field.description}</Typography>
                                }
                                {
                                    field.description && field.title
                                        ? <Typography variant="body1">{field.description}</Typography>
                                        : ''
                                }
                            </Box>
                            <Checkbox
                                sx={{width: '25px', height: '25px'}}
                                value={field.id}
                                checked={selectedOptions?.some(id => id === field.id)}
                                onChange={handleSelect}
                                disabled={readonly}
                            />
                        </DietaryNeedOrFoodAllergyItem>
                    ))
                }
            </ConfirmationWrapper>
    );
};

DietaryNeeds.propTypes = {
    readonly: PropTypes.bool,
    onConfirmSuccess: PropTypes.func,
    onDataLoading: PropTypes.func,
};
export default DietaryNeeds;