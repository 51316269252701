import AdmicityGroupFilterSelect from '../../../../shared-components/AdmicityGroupFilterSelect';
import AdmicityDialog from '../../../../shared-components/AdmicityDialog';
import React, {useState} from 'react';
import {Box, Checkbox, FormControlLabel} from '@mui/material';

/* eslint-disable react/prop-types */

const AddStudentGroupDialog = (
    {
        open,
        onClose,
        groupFilters,
        onChange
    }) => {
    const [filter, setFilter] = useState(null);
    const [excludeGroup, setExcludeGroup] = useState(false);
    const isFilterNotSelected = filter == null || !filter.filterId;
    const rootFilterIds = groupFilters ? groupFilters.map(x => x.id) : [];

    const handleClose = () => {
        setFilter(null);
        setExcludeGroup(false);
        onClose()
    }

    return (
        <AdmicityDialog
            title="Add group"
            open={open}
            handleClose={handleClose}
            actions={[
                {
                    label: 'Add',
                    disabled: isFilterNotSelected,
                    onClick: () => {
                        onChange({...filter, excluded: excludeGroup});
                        handleClose();
                    }
                },
            ]}
        >
            <Box
                display="flex"
                flexDirection="column"
            >
                <AdmicityGroupFilterSelect
                    sx={{
                        width: '100%',
                        marginTop: 1
                    }}
                    data={groupFilters}
                    value={filter?.filterId || ''}
                    onChange={(filterId, paths) => {
                        setFilter({filterId, paths});
                        setExcludeGroup(false);
                    }}
                />
                <FormControlLabel
                    sx={{
                        marginTop: 1
                    }}
                    control={
                        <Checkbox
                            checked={excludeGroup}
                            disabled={isFilterNotSelected || rootFilterIds.includes(filter.filterId)}
                            onChange={(event) => setExcludeGroup(event.target.checked)}
                        />
                    }
                    label="Exclude group"/>
            </Box>
        </AdmicityDialog>
    );
};

export default AddStudentGroupDialog;