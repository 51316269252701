import {apiService} from '../apiService';

export const groupService = apiService.injectEndpoints({
    endpoints: (builder) =>
        ({
            getGroupFilters: builder.query({
                query: (params) => ({
                    url: '/group/filters',
                    params
                }),
                providesTags: (_) => ['GroupFilters']
            }),
            createGroup: builder.mutation({
                query: (body) => ({
                    method: 'POST',
                    url: '/group',
                    data: body
                }),
                invalidatesTags: (result, error) => error ? [] : ['GroupFilters']
            }),
            updateGroup: builder.mutation({
                query: (body) => ({
                    method: 'PUT',
                    url: '/group',
                    data: body
                }),
                invalidatesTags: (result, error) => error ? [] : ['GroupFilters']
            }),
            deleteGroup: builder.mutation({
                query: (body) => ({
                    method: 'DELETE',
                    url: '/group',
                    data: body
                }),
                invalidatesTags: (result, error) => error ? [] : ['GroupFilters']
            }),
            createGroupFromFormResponses: builder.mutation({
                query: (body) => ({
                    method: 'POST',
                    url: '/group/from-form',
                    data: body
                })
            }),
        }),
});

export const {
    useGetGroupFiltersQuery,
    useCreateGroupMutation,
    useUpdateGroupMutation,
    useDeleteGroupMutation,
    useCreateGroupFromFormResponsesMutation
} = groupService;
