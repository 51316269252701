import React from 'react';
import PropTypes from 'prop-types';
import ActionConfirmButton from './ActionConfirmButton';
import {useConfirm} from 'material-ui-confirm';
import {useManageConfirmMutation} from '../../../../api/services/profilesService';

const ConfirmationWrapper = (
    {
        children,
        readonly,
        confirmData,
    }) => {
    const confirm = useConfirm();
    const [manageConfirm] = useManageConfirmMutation();

    const handleSave = async () =>
        confirm({
            title: 'Confirmation',
            confirmationText: 'Confirm',
            description: confirmData.confirmMessage,
        })
            .then(async () => {
                await confirmData.handleSave();
                await confirmData?.onConfirmSuccess();
            })
            .catch(() => {
            });

    const handleConfirm = () =>
        confirm({
            title: 'Confirmation',
            confirmationText: 'Confirm',
            description: confirmData.confirmMessage,
        })
            .then(async () => {
                await manageConfirm({
                    studentId: confirmData.studentId,
                    type: confirmData.type
                });
                await confirmData?.onConfirmSuccess();
            })
            .catch(() => {
            });

    return (
        <>
            {children}
            <ActionConfirmButton
                readonly={readonly}
                confirmedAt={confirmData.confirmedAt}
                confirmedBy={confirmData.confirmedBy}
                onConfirm={handleConfirm}
                onSave={handleSave}
                enableSaveButton={confirmData.enableSaveButton}
                enableConfirmButton={confirmData.enableConfirmButton}
                confirmButtonText={confirmData.confirmButtonText}
            />
        </>
    );
};

ConfirmationWrapper.propTypes = {
    readonly: PropTypes.bool,
    children: PropTypes.node.isRequired,
    confirmData: PropTypes.object.isRequired,
};

export default ConfirmationWrapper;