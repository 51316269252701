import {useGetGroupFiltersQuery} from '../../../../api/services/groupService';
import {useGetStudentFiltersQuery} from '../../../../api/services/studentService';
import {
    useGetFormAssignmentsQuery,
    useGetFormStudentsAssignmentsQuery
} from '../../../../api/services/formAssignmentsService';
import FORM_AUDIENCE_TYPE from '../../../../constants/FormAudienceType';
import React, {useEffect, useState} from 'react';
import {SettingsSection} from './SettingsSection';
import {Box, Checkbox, FormControlLabel, Typography} from '@mui/material';
import EmailsInput from '../../../../shared-components/EmailsInput';
import AdmicityTableV2 from '../../../../shared-components/Table/V2/AdmicityTableV2';
import StudentsAutocomplete from './StudentsAutocomplete';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import AddStudentGroupDialog from './AddStudentGroupDialog';
import {mergeArraysByKey, mergeArraysByKeys} from '../../../../utility/mergeArraysByKey';
import {validateGroupFilters} from '../../../../utility/validationUtil';

/* eslint-disable react/prop-types */

const RegularFormSettings = (
    {
        audience,
        formId,
        settings,
        strictRecipientsFiltering,
        isFormLive,
        externalUsers,
        onSettingsChange,
        onSetStrictRecipientsFiltering
    }) => {
    const {
        groupFilters,
        studentFilters,
        applicationFilters,
        externalUserEmailAssignments
    } = settings;
    const [originalSettings, setOriginalSettings] = useState({
        groupFilters: [],
        studentFilters: [],
        applicationFilters: [],
        externalUserEmailAssignments: []
    });
    const [openAddStudentGroupDialog, setOpenAddStudentGroupDialog] = useState(false);
    const {data} = useGetGroupFiltersQuery({}, {skip: !audience});
    const {data: studentsData} = useGetStudentFiltersQuery({}, {skip: !audience});
    const {data: formAssignmentData} = useGetFormAssignmentsQuery({formId});
    const {data: studentFormAssignmentData} = useGetFormStudentsAssignmentsQuery({formId});

    useEffect(() => {
        if (formAssignmentData) {
            const groupFilters = formAssignmentData.map(x => ({
                id: x.filterId,
                displayName: x.displayName,
                groupId: x.id,
                excluded: x.excluded
            }));

            onSettingsChange(prev => ({
                ...prev,
                groupFilters,
                validation: validateGroupFilters(groupFilters)
            }));
            setOriginalSettings(prev => ({...prev, groupFilters}));
        }
    }, [formAssignmentData]);

    useEffect(() => {
        if (studentFormAssignmentData && studentsData) {
            const studentFilters = studentFormAssignmentData
                .filter(filter => studentsData.students.some(student => filter.studentId === student.id))
                .map(x => ({
                    id: x.studentId,
                    label: x.displayName,
                    assignmentId: x.id
                }));
            const applicationFilters = studentFormAssignmentData
                .filter(filter => studentsData.applications.some(application => filter.studentId === application.id))
                .map(x => ({
                    id: x.studentId,
                    label: x.displayName,
                    assignmentId: x.id
                }));

            onSettingsChange(prev => ({...prev, studentFilters, applicationFilters}));
            setOriginalSettings(prev => ({...prev, studentFilters, applicationFilters}));
        }
    }, [studentFormAssignmentData, studentsData]);

    useEffect(() => {
        if (externalUsers) {
            onSettingsChange(prev => ({
                ...prev,
                externalUserEmailAssignments: externalUsers
            }));
            setOriginalSettings(prev => ({
                ...prev,
                externalUserEmailAssignments: externalUsers
            }));
        }
    }, [externalUsers]);

    const handleAddGroupFilters = filter => {
        const newFilter = {
            id: filter.filterId,
            displayName: filter.paths.map(x => x.name).join(' - '),
            excluded: filter.excluded
        };
        const filters = [...settings.groupFilters];
        const filterIndex = filters.findIndex(x => x.id === newFilter.id);

        if (filterIndex >= 0) {
            filters[filterIndex] = newFilter;
        } else {
            filters.push(newFilter);
        }

        const newFilters = mergeArraysByKeys(filters, originalSettings.groupFilters, ['id', 'excluded']);

        onSettingsChange(prev => ({
            ...prev,
            groupFilters: newFilters,
            validation: validateGroupFilters(newFilters)
        }));
    };

    return (
        <Box display="flex" flexDirection="column" gap={2}>
            {
                audience === FORM_AUDIENCE_TYPE.externalUsers
                    ? <SettingsSection>
                        <Box>
                            <Typography variant="subtitle1" fontWeight="bold">External users</Typography>
                            <Typography variant="subtitle2">Type one or more user emails</Typography>
                        </Box>
                        <EmailsInput
                            values={externalUserEmailAssignments.filter(x => !x.isDeleted)}
                            onChange={emails => onSettingsChange(prev => ({
                                ...prev,
                                externalUserEmailAssignments: mergeArraysByKey(
                                    emails,
                                    originalSettings.externalUserEmailAssignments,
                                    'email')
                            }))}
                            onBlur={email => onSettingsChange(prev => ({
                                ...prev,
                                externalUserEmailAssignments: mergeArraysByKey(
                                    [...settings.externalUserEmailAssignments, {email}],
                                    originalSettings.externalUserEmailAssignments,
                                    'email')
                            }))}
                        />
                    </SettingsSection>
                    : null
            }
            <SettingsSection>
                <Box>
                    <Typography variant="subtitle1" fontWeight="bold">Select Student Groups</Typography>
                    <Typography variant="subtitle2">Choose one or more student groups to associate
                        with
                        the form</Typography>
                </Box>
                <FormControlLabel
                    sx={{
                        gap: 1
                    }}
                    control={
                        <Checkbox
                            sx={{
                                padding: 0,
                                marginLeft: 1
                            }}
                            checked={strictRecipientsFiltering}
                            onChange={async (e) =>
                                await onSetStrictRecipientsFiltering(e.target.checked)
                            }
                            disabled={isFormLive}
                        />
                    }
                    label="Must appear in all these groups"
                />
            </SettingsSection>
            <AdmicityTableV2
                columns={[
                    {
                        accessorKey: 'displayName',
                        header: 'Group Name',
                        grow: true,
                    },
                    {
                        accessorKey: 'excluded',
                        header: 'Status',
                        size: 50,
                        grow: false,
                        Cell: ({cell}) => cell.getValue() === true ? 'Excluded' : ''
                    }
                ]}
                toolbarActions={[
                    {
                        type: 'button',
                        label: 'Add',
                        size: 'small',
                        variant: 'contained',
                        startIcon: <AddCircleOutlineIcon/>,
                        onClick: () => setOpenAddStudentGroupDialog(true)
                    },
                    {
                        type: 'button',
                        label: 'Delete',
                        size: 'small',
                        enableOnRowSelect: true,
                        startIcon: <DeleteOutlineRoundedIcon/>,
                        onClick: (rows) => {
                            const filters = settings.groupFilters.filter(filter => !rows.some(row => row.id === filter.id));
                            const newFilters = mergeArraysByKeys(filters, originalSettings.groupFilters, ['id', 'excluded']);

                            onSettingsChange(prev => ({
                                ...prev,
                                groupFilters: newFilters,
                                validation: validateGroupFilters(newFilters)
                            }));
                        }
                    }
                ]}
                data={groupFilters.filter(filter => !filter.isDeleted)}
                tableProps={{
                    layoutMode: 'grid-no-grow',
                    enableRowSelection: true,
                    enableSorting: false,
                    enableFilters: false,
                    enableHiding: false,
                    enableFullScreenToggle: false,
                    enableColumnResizing: false,
                    enableBottomToolbar: false,
                    muiTableBodyProps: {
                        sx: {
                            '& :last-child td': {
                                borderBottom: 0,
                            },
                        }
                    },
                    muiTablePaperProps: {
                        variant: 'outlined',
                        elevation: 0,
                        sx: {
                            display: 'flex',
                            flexDirection: 'column',
                            flexGrow: 1,
                            borderLeft: 'none',
                            borderRight: 'none',
                            borderRadius: 0
                        }
                    },
                    muiTableContainerProps: {
                        sx: {
                            maxHeight: 350,
                            minHeight: 'auto',
                            border: 'none'
                        }
                    },
                }}
            />
            {<SettingsSection>
                <Box>
                    <Typography variant="subtitle1" fontWeight="bold">Select Individual Student</Typography>
                    <Typography variant="subtitle2">Choose a specific student to directly associate with this
                        form request</Typography>
                </Box>
                <StudentsAutocomplete
                    label="Applications"
                    options={studentsData?.applications ?? []}
                    value={applicationFilters.filter(filter => !filter.isDeleted && studentsData?.applications.some(application => application.id === filter.id))}
                    onChange={(e, newValue) => onSettingsChange(prev => ({
                        ...prev,
                        applicationFilters: mergeArraysByKey(newValue, originalSettings.applicationFilters)
                    }))}
                />
                <StudentsAutocomplete
                    label="Students"
                    options={studentsData?.students ?? []}
                    value={studentFilters.filter(filter => !filter.isDeleted && studentsData?.students.some(student => student.id === filter.id))}
                    onChange={(e, newValue) => onSettingsChange(prev => ({
                        ...prev,
                        studentFilters: mergeArraysByKey(newValue, originalSettings.studentFilters)
                    }))}
                />
            </SettingsSection>
            }
            <AddStudentGroupDialog
                open={openAddStudentGroupDialog}
                groupFilters={data?.modules}
                onClose={() => setOpenAddStudentGroupDialog(false)}
                onChange={handleAddGroupFilters}
            />
        </Box>
    );
};

export default RegularFormSettings;