import moment from 'moment';

export const formatDate = (date) => {
    const options = {year: 'numeric', month: 'short', day: 'numeric'};
    return new Date(date).toLocaleDateString('en-US', options);
}

export const formatDateTime = (date) => {
    return moment(date).format('MMM D, YYYY HH:mm');
}

export const isDateExpired = dateToCheck => {
    const date = new Date(dateToCheck);
    const currentDate = new Date();

    return date < currentDate;
};
