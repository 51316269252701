import React from 'react';
import PropTypes from 'prop-types';
import SingleLine from './SingleLine';
import TextBox from './TextBox';
import CheckboxQuestion from './CheckboxQuestion';
import AdditionalNotes from './AdditionalNotes';
import MultipleChoice from './MultipleChoice';
import SingleChoice from './SingleChoice';
import Attachment from './Attachment';
import Signature from './Signature';
import BoxGrid from './BoxGrid';
import Dropdown from './Dropdown';
import LinearScale from './LinearScale';
import Image from './Image';
import {FormBuilderComponentTypes} from '../ComponentList';
import OfferStatusChoice from './OfferStatusChoice';
import FileUploader from './FileUploader';

const ComponentBuilder = ({
                              index,
                              readOnly,
                              previewMode,
                              handleChangeEdit,
                              handleChangeSubmit,
                              handleDownloadAttachment,
                              submitData,
                              data,
                              submitMode,
                              field,
                              isFormArchived,
                              placeholderValues,
                              accessLevel,
                              relatedComponent,
                              componentToEditIndex
                          }) => {
    switch (field.type) {
        case 'singleText':
            return <SingleLine onChange={!submitMode && !previewMode ? handleChangeEdit : handleChangeSubmit}
                               onUpdateDescription={handleChangeEdit} componentToEditIndex={componentToEditIndex}
                               index={index} id={field.id} value={readOnly ? data[field.id] : submitData[field.id]}
                               description={field.formattedDescription ? field.formattedDescription : field.description}
                               enableValidation={field.enableValidation} accessLevel={accessLevel}
                               isEditMode={!submitMode && !previewMode} isFormArchived={isFormArchived}
                               readonly={readOnly} placeholderValues={placeholderValues} submitMode={submitMode}
            />
        case 'textBox':
            return <TextBox onChange={!submitMode && !previewMode ? handleChangeEdit : handleChangeSubmit}
                            onUpdateDescription={handleChangeEdit} componentToEditIndex={componentToEditIndex}
                            index={index} id={field.id} value={readOnly ? data[field.id] : submitData[field.id]}
                            description={field.formattedDescription ? field.formattedDescription : field.description}
                            enableValidation={field.enableValidation} accessLevel={accessLevel}
                            isEditMode={!submitMode && !previewMode} isFormArchived={isFormArchived}
                            readonly={readOnly} placeholderValues={placeholderValues} submitMode={submitMode}
            />
        case 'tickBoxGrid':
            return <BoxGrid onChange={!submitMode && !previewMode ? handleChangeEdit : handleChangeSubmit}
                            onUpdateDescription={handleChangeEdit} componentToEditIndex={componentToEditIndex}
                            index={index} id={field.id} value={readOnly ? data[field.id] : submitData[field.id]}
                            description={field.formattedDescription ? field.formattedDescription : field.description}
                            enableValidation={field.enableValidation} accessLevel={accessLevel}
                            isEditMode={!submitMode && !previewMode} isFormArchived={isFormArchived} rows={field.rows}
                            columns={field.columns} readOnly={readOnly} type={field.type}
                            readonly={readOnly} placeholderValues={placeholderValues}
            />
        case 'multipleChoiceGrid':
            return <BoxGrid onChange={!submitMode && !previewMode ? handleChangeEdit : handleChangeSubmit}
                            onUpdateDescription={handleChangeEdit} componentToEditIndex={componentToEditIndex}
                            index={index} id={field.id} value={readOnly ? data[field.id] : submitData[field.id]}
                            description={field.formattedDescription ? field.formattedDescription : field.description}
                            enableValidation={field.enableValidation} accessLevel={accessLevel}
                            isEditMode={!submitMode && !previewMode} isFormArchived={isFormArchived} rows={field.rows}
                            columns={field.columns} readOnly={readOnly} type={field.type}
                            readonly={readOnly} placeholderValues={placeholderValues}
            />
        case 'checkboxQuestion':
            return <CheckboxQuestion onChange={!submitMode && !previewMode ? handleChangeEdit : handleChangeSubmit}
                                     onUpdateDescription={handleChangeEdit} componentToEditIndex={componentToEditIndex}
                                     index={index} id={field.id} accessLevel={accessLevel}
                                     value={readOnly ? data[field.id] : submitData[field.id]}
                                     description={field.formattedDescription ? field.formattedDescription : field.description}
                                     enableValidation={field.enableValidation}
                                     isEditMode={!submitMode && !previewMode} isFormArchived={isFormArchived}
                                     readonly={readOnly} placeholderValues={placeholderValues}
            />
        case 'additionalNotes':
            return <AdditionalNotes onChange={!submitMode && !previewMode ? handleChangeEdit : handleChangeSubmit}
                                    onUpdateDescription={handleChangeEdit}
                                    index={index} id={field.id} readOnly={readOnly} accessLevel={accessLevel}
                                    description={field.formattedDescription ? field.formattedDescription : field.description}
                                    enableValidation={field.enableValidation}
                                    isEditMode={!submitMode && !previewMode} isFormArchived={isFormArchived}
                                    placeholderValues={placeholderValues} componentToEditIndex={componentToEditIndex}
            />
        case 'multipleChoice':
            return <MultipleChoice onChange={!submitMode && !previewMode ? handleChangeEdit : handleChangeSubmit}
                                   onUpdateDescription={handleChangeEdit} componentToEditIndex={componentToEditIndex}
                                   index={index} id={field.id} options={field.options}
                                   value={readOnly ? data[field.id] : submitData[field.id]}
                                   description={field.formattedDescription ? field.formattedDescription : field.description}
                                   enableValidation={field.enableValidation}
                                   isEditMode={!submitMode && !previewMode} isFormArchived={isFormArchived}
                                   relatedComponent={relatedComponent} accessLevel={accessLevel}
                                   readonly={readOnly} placeholderValues={placeholderValues}
            />
        case 'singleChoice':
            return <SingleChoice onChange={!submitMode && !previewMode ? handleChangeEdit : handleChangeSubmit}
                                 onUpdateDescription={handleChangeEdit} componentToEditIndex={componentToEditIndex}
                                 index={index} id={field.id} options={field.options}
                                 value={readOnly ? data[field.id] : submitData[field.id]}
                                 description={field.formattedDescription ? field.formattedDescription : field.description}
                                 enableValidation={field.enableValidation}
                                 isEditMode={!submitMode && !previewMode} isFormArchived={isFormArchived}
                                 relatedComponent={relatedComponent} accessLevel={accessLevel}
                                 readonly={readOnly} placeholderValues={placeholderValues}
            />
        case 'linearScale':
            return <LinearScale onChange={!submitMode && !previewMode ? handleChangeEdit : handleChangeSubmit}
                                onUpdateDescription={handleChangeEdit} componentToEditIndex={componentToEditIndex}
                                index={index} id={field.id} startFrom={field.startFrom} endAt={field.endAt}
                                startLabel={field.startLabel} endLabel={field.endLabel}
                                value={readOnly ? data[field.id] : submitData[field.id]}
                                description={field.formattedDescription ? field.formattedDescription : field.description}
                                enableValidation={field.enableValidation}
                                isEditMode={!submitMode && !previewMode} isFormArchived={isFormArchived}
                                isSubmitted={readOnly && submitMode} accessLevel={accessLevel}
                                readonly={readOnly} placeholderValues={placeholderValues}
            />
        case 'dropdown':
            return <Dropdown onChange={!submitMode && !previewMode ? handleChangeEdit : handleChangeSubmit}
                             onUpdateDescription={handleChangeEdit} componentToEditIndex={componentToEditIndex}
                             index={index} id={field.id} options={field.options}
                             value={readOnly ? data[field.id] : submitData[field.id]}
                             description={field.formattedDescription ? field.formattedDescription : field.description}
                             enableValidation={field.enableValidation}
                             isEditMode={!submitMode && !previewMode} isFormArchived={isFormArchived}
                             relatedComponent={relatedComponent} accessLevel={accessLevel}
                             readonly={readOnly} placeholderValues={placeholderValues}
            />
        case 'attachment':
            return <Attachment id={field.id} onChange={!submitMode && !previewMode ? handleChangeEdit : handleChangeSubmit}
                               onUpdateDescription={handleChangeEdit} componentToEditIndex={componentToEditIndex}
                               index={index}
                               description={field.formattedDescription ? field.formattedDescription : field.description}
                               attachments={field.attachments} accessLevel={accessLevel}
                               isEditMode={!submitMode && !previewMode} isFormArchived={isFormArchived}
                               readonly={readOnly} placeholderValues={placeholderValues}
            />
        case 'image':
            return <Image id={field.id} onChange={!submitMode && !previewMode ? handleChangeEdit : handleChangeSubmit}
                          onUpdateDescription={handleChangeEdit} componentToEditIndex={componentToEditIndex}
                          index={index}
                          description={field.formattedDescription ? field.formattedDescription : field.description}
                          attachments={field.attachments} accessLevel={accessLevel}
                          isEditMode={!submitMode && !previewMode} isFormArchived={isFormArchived}
                          readonly={readOnly} placeholderValues={placeholderValues}
            />
        case 'signature':
            return <Signature onChange={!submitMode && !previewMode ? handleChangeEdit : handleChangeSubmit}
                              id={field.id} value={readOnly ? data[field.id] : submitData[field.id]}
                              isEditMode={!submitMode && !previewMode} isSubmitted={readOnly && submitMode}
            />
        case FormBuilderComponentTypes.OfferStatusChoice:
            return <OfferStatusChoice onChange={!submitMode && !previewMode ? handleChangeEdit : handleChangeSubmit}
                                      index={index} id={field.id} options={field.options}
                                      value={readOnly ? data[field.id] : submitData[field.id]}
                                      onUpdateDescription={handleChangeEdit}
                                      description={field.formattedDescription ? field.formattedDescription : field.description}
                                      enableValidation={field.enableValidation} componentToEditIndex={componentToEditIndex}
                                      isEditMode={!submitMode && !previewMode} isFormArchived={isFormArchived}
                                      relatedComponent={relatedComponent} accessLevel={accessLevel}
                                      readonly={readOnly} placeholderValues={placeholderValues}
            />
        case FormBuilderComponentTypes.FileUploader:
            return <FileUploader
                id={field.id}
                index={index}
                value={readOnly ? data[field.id] : submitData[field.id]}
                description={field.formattedDescription ? field.formattedDescription : field.description}
                onChange={!submitMode && !previewMode ? handleChangeEdit : handleChangeSubmit}
                isFormArchived={isFormArchived}
                accessLevel={accessLevel}
                isEditMode={!submitMode && !previewMode}
                readonly={readOnly}
                placeholderValues={placeholderValues}
                onUpdateDescription={handleChangeEdit}
                componentToEditIndex={componentToEditIndex}
                enableValidation={field.enableValidation}
                handleDownloadAttachment={handleDownloadAttachment}
            />
        default:
            return null;
    }
};

ComponentBuilder.propTypes = {
    index: PropTypes.number,
    readOnly: PropTypes.bool,
    previewMode: PropTypes.bool,
    handleChangeEdit: PropTypes.func,
    handleChangeSubmit: PropTypes.func,
    handleDownloadAttachment: PropTypes.func,
    submitData: PropTypes.object,
    data: PropTypes.object,
    submitMode: PropTypes.bool,
    isFormArchived: PropTypes.bool,
    field: PropTypes.object,
    placeholderValues: PropTypes.object,
    accessLevel: PropTypes.number,
    relatedComponent: PropTypes.object,
    componentToEditIndex: PropTypes.number
};

export default ComponentBuilder;