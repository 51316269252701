import {useEffect, useState} from 'react';

const useTextField = (options = {}) => {
    const {
        initialValue = '',
        validate = () => {},
        hint = '',
        onChange = () => {},
        required = true
    } = options;
    const [value, setValue] = useState(initialValue);
    const [error, setError] = useState(false);
    const [helperText, setHelperText] = useState(hint ?? '');

    useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);

    const onValueChange = (event) => {
        const newValue = event.target.value;

        setValue(newValue);
        const validationResult = validate(newValue);

        if (validationResult) {
            setError(!validationResult.isValid);
            setHelperText(validationResult.valid ? (hint ?? '') : validationResult.message);
        }
        onChange(event);
    };

    const reset = () => {
        setValue(initialValue);
        setHelperText('');
        setError(false);
    }

    const setInitialValue = (value) => setValue(value)

    return {
        value,
        onChange: onValueChange,
        error,
        helperText,
        required,
        reset,
        setValue: setInitialValue
    };
};

export default useTextField;