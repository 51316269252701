import {useEffect, useState} from 'react';

const getFieldValues = (fields) => Object.values(fields)
    .reduce((obj, field) => {
        obj[field.textFieldProps.name] = field.otherProps.nullableOnEmpty && field.textFieldProps.value === ''
            ? null
            : field.textFieldProps.value;
        return obj;
    }, {});

const useTextFields = (textFields = []) => {
    const [fields, setFields] = useState({});

    const onChange = event => {
        const propKey = event.target.name;
        const value = event.target.value;

        setFields(prevState => {
            const textFieldProps = prevState[propKey].textFieldProps;
            const otherProps = prevState[propKey].otherProps;
            const validationResult = textFieldProps.required || value !== ''
                ? prevState[propKey].otherProps.validate(value, prevState)
                : {
                    isValid: true
                };
            const updatedField = {
                [propKey]: {
                    ...prevState[propKey],
                    textFieldProps: {
                        ...textFieldProps,
                        value,
                        changed: true,
                        error: !validationResult.isValid,
                        helperText: validationResult.isValid ? (otherProps.hint || '') : validationResult.message
                    }
                }
            };
            const deps = otherProps.dependencies
                ? typeof otherProps.dependencies === 'function'
                    ? otherProps.dependencies(prevState)
                    : otherProps.dependencies
                : undefined;
            const linkedPropsRequireValidation = deps
                ? deps
                    .reduce((acc, linkedProp) => {
                        const linkedPropValue = prevState[linkedProp].textFieldProps.value;
                        const validationResult = prevState[linkedProp]
                            .otherProps
                            .validate(linkedPropValue, updatedField);

                        acc[linkedProp] = {
                            ...prevState[linkedProp],
                            textFieldProps: {
                                ...prevState[linkedProp].textFieldProps,
                                error: !validationResult.isValid,
                                helperText: validationResult.isValid ? (otherProps.hint || '') : validationResult.message
                            }
                        };

                        return acc;
                    }, {})
                : {};

            return ({
                ...prevState,
                ...updatedField,
                ...linkedPropsRequireValidation
            });
        });
    };

    useEffect(() => {
        const fields = textFields.reduce((acc, {name, ...rest}) => {
            const validateFunc = rest.validate || ((_) => ({isValid: true}));

            acc[name] = {
                textFieldProps: {
                    name,
                    value: rest.inputType === 'date'
                        ? rest.initialValue
                        : (rest.initialValue !== null && rest.initialValue !== undefined) ? rest.initialValue : '',
                    type: rest.type || 'text',
                    label: rest.label,
                    hint: rest.hint || '',
                    helperText: rest.hint || '',
                    required: rest.required === undefined ? true : rest.required,
                    select: rest.select === true,
                    disabled: rest.disabled,
                    multiline: rest.multiline,
                    inputType: rest.inputType || 'text',
                    error: false,
                    onChange: event => onChange(event, rest),
                    onChangeWarningMessage: rest.onChangeWarningMessage
                },
                otherProps: {
                    validate: validateFunc,
                    options: rest.options,
                    nullableOnEmpty: rest.nullableOnEmpty === undefined
                        ? false
                        : rest.nullableOnEmpty,
                    dependencies: rest.dependencies
                }
            };

            return acc;
        }, {});

        setFields(fields);
    }, [textFields]);

    const reset = () => setFields({});

    return {fields, reset, getFieldValues: () => getFieldValues(fields)};
};

export default useTextFields;
