const mergeArraysByKey = (actual, original, key = 'id') => {
    const actualMap = new Map(actual.map(item => [item[key], item]));
    const result = original.map(item => {
        if (actualMap.has(item[key])) {
            const actualItem = actualMap.get(item[key]);

            actualMap.delete(item[key]);

            return actualItem;
        } else {
            return {...item, isDeleted: true};
        }
    });

    actualMap.forEach(item => {
        result.push({...item, isNew: true});
    });

    return result;
};

const mergeArraysByKeys = (actual, original, keys = []) => {
    if (!keys.length) return;

    const getKey = (item) => keys.map(key => item[key]).join('.');

    const actualMap = new Map(actual.map(item => [getKey(item), item]));
    const result = original.map(item => {
        const compositeKey = getKey(item);

        if (actualMap.has(compositeKey)) {
            const actualItem = actualMap.get(compositeKey);

            actualMap.delete(compositeKey);

            return actualItem;
        } else {
            return {...item, isDeleted: true};
        }
    });

    actualMap.forEach(item => {
        result.push({...item, isNew: true});
    });

    return result;
};

export {mergeArraysByKeys, mergeArraysByKey};