import React, {useCallback, useEffect, useState} from 'react';
import {ToggleButton, ToggleButtonGroup} from '@mui/material';
import {
    useAssignRolePermissionsMutation,
    useGetPermissionsQuery,
    useGetRolesQuery
} from '../../../../api/services/schoolManagementService';
import AdmicityTable from '../../../../shared-components/AdmicityTable';
import {ROLE_IDS, ROLES, ROLES_LABELS} from '../../../../constants/roles';
import PropTypes from 'prop-types';
import AddTaskIcon from '@mui/icons-material/AddTask';

const PermissionSelect = ({permissions, value, onChange}) =>
    (
        <ToggleButtonGroup
            color="primary"
            value={value}
            exclusive
            onChange={(e, permission) => onChange(permission)}
        >
            {
                permissions.map(permission => (
                    <ToggleButton
                        sx={{
                            '&.MuiButtonBase-root ': {
                                textTransform: 'none'
                            }
                        }}
                        key={permission.value}
                        value={permission.value}
                        disabled={permission.disabled}
                        size="small"
                    >
                        {permission.name}
                    </ToggleButton>
                ))
            }
        </ToggleButtonGroup>
    );

PermissionSelect.propTypes = {
    permissions: PropTypes.array.isRequired,
    value: PropTypes.string,
    onChange: PropTypes.func,
};

const AccessSettings = () => {
    const {data: permissionModules = [], isLoading: isPermissionsLoading} = useGetPermissionsQuery();
    const {data: roles = [], isLoading: isRolesLoading} = useGetRolesQuery();
    const [assignPermissions, {isLoading: isAssignPermissionsInProgress}] = useAssignRolePermissionsMutation();
    const [permissions, setPermissions] = useState({});
    const [originalPermissionsSnapshot, setOriginalPermissionsSnapshot] = useState({});
    const isLoading = isPermissionsLoading ||
        isRolesLoading ||
        isAssignPermissionsInProgress;

    const handlePermissionChange = useCallback((roleId, module, value) => {
        if (value) {
            setPermissions(prevPermissions => ({
                ...prevPermissions,
                [roleId]: {
                    ...prevPermissions[roleId],
                    [module]: value
                }
            }));
        }
    }, []);

    useEffect(() => {
        if (permissionModules.length > 0) {
            const permissions = permissionModules.reduce((acc, data) => {
                data.permissions.forEach(permission => {
                    permission.roleIds.forEach(roleId => {
                        if (!acc[roleId]) {
                            acc[roleId] = {};
                        }
                        acc[roleId][data.key] = permission.value;
                    });
                });
                return acc;
            }, {});

            setPermissions(permissions);
            setOriginalPermissionsSnapshot(permissions);
        }
    }, [permissionModules]);

    const columns = [
        {
            field: 'description',
            headerName: 'Actions',
            flex: 1,
            editable: false,
            sortable: false,
        },
        ...roles
            .filter(x => x.name !== ROLES.SUPER_ADMIN && x.name !== ROLES.NONE && x.name !== ROLES.STUDENT)
            .map(x => ({...x, order: ROLE_IDS[x.name] ?? 0}))
            .sort((a, b) => a.order - b.order)
            .map(({name, id: roleId}) => ({
                field: name,
                headerName: ROLES_LABELS[name],
                flex: 1,
                align: 'center',
                headerAlign: 'center',
                editable: false,
                sortable: false,
                renderHeader: () => <strong>{ROLES_LABELS[name]}</strong>,
                renderCell: ({row}) =>
                    row[name] && (
                        <PermissionSelect
                            permissions={row[name]}
                            value={permissions[roleId]?.[row.module]}
                            onChange={(value) => handlePermissionChange(roleId, row.module, value)}
                        />
                    )
            }))
    ];

    const handleAssignPermissions = async () => {
        const changedPermissions = Object
            .entries(permissions)
            .reduce((changedPermissions, [roleId, modules]) => {
                Object
                    .values(modules)
                    .forEach((value) => {
                        if (!changedPermissions[roleId]) {
                            changedPermissions[roleId] = [];
                        }

                        changedPermissions[roleId].push(value);
                    });
                return changedPermissions;
            }, {});

        await assignPermissions({
            roleClaims: Object.entries(changedPermissions)
                .map(([roleId, claims]) => ({
                    roleId,
                    claims: claims.filter(x => x)
                }))
        });
        setOriginalPermissionsSnapshot({});
    };

    const checkIfPermissionsChanged = () => {
        for (const [roleId, modules] of Object.entries(permissions)) {
            for (const [module, value] of Object.entries(modules)) {
                const original = originalPermissionsSnapshot[roleId]?.[module];

                if (value !== original) {
                    return true;
                }
            }
        }

        return false;
    };

    return (
        <>
            <AdmicityTable
                sx={{
                    '& .MuiDataGrid-cell': {
                        borderBottom: 'none',
                    }
                }}
                data={permissionModules && roles.length > 0
                    ? permissionModules.map((module, index) => ({
                        id: index,
                        description: module.description,
                        module: module.key,
                        ...roles
                            .reduce((roleItems, role) => ({
                                ...roleItems,
                                [role.name]: module.permissions.map(permission => ({
                                    name: permission.name,
                                    value: permission.value,
                                    disabled: permission.disabledForRoleIds.includes(role.id)
                                }))
                            }), {})
                    }))
                    : []}
                columns={columns}
                isLoading={isLoading}
                toolbarActions={[
                    {
                        type: 'button',
                        title: 'Assign Permissions',
                        icon: <AddTaskIcon/>,
                        onClick: handleAssignPermissions,
                        disabled:
                            isLoading ||
                            !checkIfPermissionsChanged()
                    }
                ]}
                hideFooterPagination
            />
        </>
    );
};

export default AccessSettings;